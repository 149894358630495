<!-- eslint-disable @typescript-eslint/no-explicit-any -->
<script setup lang="ts">
import { usePaymentData } from "@/composables/useGooglePay";
import { BUTTON_COLOR, BUTTON_SIZE_MODE, BUTTON_TYPE, ENVIROMENT, MERCHANT_ID, MERCHANT_NAME, TOTAL_PRICE_LABEL, TOTAL_PRICE_STATUS, } from "@/constants/googlePayConst";
import { PayEvent, ProviderPay, PaymentStatus, Pay } from "@/types";
import { v4 as uuidv4 } from "uuid";
import { defineEmits, defineProps, onMounted } from "vue";
import { COUNTRY_CODE, CURRENCY_CODE, DEFAULT_VALUE } from "@/constants/globalConst";
import { useCreatePay, useCreatePayRequest, useCreatePayResponse } from "@/composables/useUtils";

const google = (window as any).google;
const paymentsClient = new google.payments.api.PaymentsClient();
const props = defineProps({
    totalPay: {
    type: Number,
    required: true,
  },
  identificativoOrdineCliente: {
    type: String,
    required: true,
  }
});
onMounted(() => {
  onGooglePayLoaded();
});

const emit = defineEmits<{
  (event: 'processPayment', pay: Pay): void;
  (event: 'identifyOrderClientNotFound'): void;
}>();
const processPayment = (payEvent: PayEvent, paymentStatus: PaymentStatus, request: any, response: any) => {
  const method = response.paymentMethodData.info.cardDetails ?? DEFAULT_VALUE;
  const type = response.paymentMethodData.type ?? DEFAULT_VALUE;
  const circuit = response.paymentMethodData.info.cardNetwork ?? DEFAULT_VALUE;
  const req = useCreatePayRequest(props.totalPay);
  const res = useCreatePayResponse(method, type, circuit);
  const id = uuidv4();
  const pay: Pay = useCreatePay (
    id, MERCHANT_ID, MERCHANT_NAME, 
    ProviderPay.GOOGLEPAY, payEvent, paymentStatus,
    id, DEFAULT_VALUE, req, res);
  emit('processPayment', pay);
};
const identifyOrderClientNotFound = () => {
  emit('identifyOrderClientNotFound');
};

function onGooglePayLoaded() {
  paymentsClient.isReadyToPay(usePaymentData())
    .then((response: any) => {
      if (response.result) {
        addGooglePayButton();
        const container = document.getElementById("googlePayButton");
        if (container) {
          const buttonsInContainer = container.getElementsByTagName("button");
          if (buttonsInContainer.length > 1) {
            for (let i = 0; buttonsInContainer.length != i; i++) {
              const divOfButton = buttonsInContainer[0].parentNode;
              divOfButton?.parentNode?.removeChild(divOfButton);
            }
          }
        }
      }
    })
    .catch((error: any) => {
      console.error("Error checking Google Pay:", "=>", error);
    });
}
function addGooglePayButton() {
  const button = paymentsClient.createButton({
    environment: ENVIROMENT,
    buttonColor: BUTTON_COLOR,
    buttonType: BUTTON_TYPE,
    buttonSizeMode: BUTTON_SIZE_MODE,
    onClick: onGooglePaymentButtonClicked,
  });
  const container = document.getElementById("googlePayButton");
  if (container) {
    container.appendChild(button);
  }
}
function onGooglePaymentButtonClicked() {
  if (props.identificativoOrdineCliente === null || props.identificativoOrdineCliente === undefined || props.identificativoOrdineCliente === '') {
    identifyOrderClientNotFound();
  } else {
    const paymentDataRequest = usePaymentData();
    const totalPay = props.totalPay;
    paymentDataRequest.transactionInfo = {
      countryCode: COUNTRY_CODE,
      currencyCode: CURRENCY_CODE,
      totalPrice: totalPay.toFixed(2),
      totalPriceStatus: TOTAL_PRICE_STATUS,
      totalPriceLabel: TOTAL_PRICE_LABEL,
      transactionId: uuidv4(),
    };
    paymentsClient.loadPaymentData(paymentDataRequest)
      .then((paymentDataResponse: any) => {
        processPayment( PayEvent.SUCCESS, PaymentStatus.COMPLETE, paymentDataRequest, paymentDataResponse );
      })
      .catch((error: any) => {
        const userClosedPaymentRequestUI = error?.message?.toLowerCase().includes('user closed');
        if (!userClosedPaymentRequestUI) {
          processPayment( PayEvent.ERROR, PaymentStatus.REJECTED, paymentDataRequest, error );
        }
      });
  }
}
</script>

<template>
  <div class="is-flex is-justify-content-center is-align-items-center">
    <div id="googlePayButton" class="spBut"></div>
  </div>
</template>

<style lang="css" scoped>
div.spBut{
  height: 50px;
  margin-left: auto; 
  margin-right: auto;
}
</style>
