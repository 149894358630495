/* eslint-disable @typescript-eslint/no-explicit-any */
import { useConcatenatedString } from "@/composables/useUtils";
import { useGetFirstElementCollectionByCondition } from "@/firebase/firebaseUtilities";
import { DbDocumentName, type CatalogItem, type Local } from "@/types";

export async function useCaricaLocal(idLocale: string): Promise<Local> {
    //recupera i menu dal db.
    const data = await useGetFirstElementCollectionByCondition({
        collectionName: DbDocumentName.LOCAL,
        whereField: "id",
        whereFilterOp: "==",
        whereCondition: useConcatenatedString(idLocale),
    });
    const o = data as Local;
    return o;
}
export function useStoredLocal(store: any, local: Local): Local {
    store.commit("setLocal", local);
    return local;
}
export async function useCaricaCatalogItem(idCatalog: string): Promise<CatalogItem> {
    //recupera i menu dal db.
    const data = await useGetFirstElementCollectionByCondition({
        collectionName: DbDocumentName.CATALOG,
        whereField: "id",
        whereFilterOp: "==",
        whereCondition: useConcatenatedString(idCatalog),
    });
    const o = data as CatalogItem;
    return o;
}


