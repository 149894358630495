<!-- eslint-disable @typescript-eslint/no-non-null-assertion -->
<script setup lang="ts">
import { ref, computed, onMounted, watch } from 'vue';
import NavbarComponent from "@/components/NavbarComponent.vue";
import FooterComponent from '@/components/FooterComponent.vue'
import CartDetailComponent from '@/components/CartDetailComponent.vue';
import { Order, ServiceZone, Language, PaymentStatus, OrderPendingSubstatus, DbDocumentName, CatalogGroupType, OrderStatus } from "../types";
import { useRoute } from "vue-router";
import { useStore } from 'vuex';
import { useGetPendingOrdersRealTime, useUpdateDocument, useSetupOrderInChargeListener } from "@/firebase/firebaseUtilities";
import PaginationComponent from '@/components/PaginationComponent.vue';
import { usePagination } from '@/composables/usePagination';
import {useTranslatePaymentStatus} from "@/composables/useTranslate"
import { useFormatData } from "@/composables/useUtils";

const isLoading = ref<boolean>(true);
const route = useRoute();
const store = useStore();
const idLocale = route.params.idLocale;

//Gestione riproduzione audio all'arrivo di nuovi ordini
const audioDoorbell = ref<HTMLAudioElement | null>(null);
const hasUserLandedOnPage = ref<boolean>(false);
const audioFlag = computed<boolean>(() => {
  return store.state.audio
})

//variabili associate gli ordini
const activeServiceZone = ref<ServiceZone>(ServiceZone.onsit);
const orderInCharge = computed<Order | null>(() => {
  return store.state.ordersInCharge[activeServiceZone.value];
});
const pendingOrders = useGetPendingOrdersRealTime(idLocale);
const queueOrders = computed<Order[]>(() => {
  return pendingOrders.value.filter((order) => order.serviceZone === activeServiceZone.value) 
})
const ordersToDisplay = computed<Order[]>(() => {
  return queueOrders.value.slice(startIndex.value, endIndex.value);
})

//paginazione
const itemsPerPage = 3;
const { startIndex, endIndex, handlePageChange } = usePagination(itemsPerPage);
const currentPage = ref(1);
watch(activeServiceZone, () =>{
  currentPage.value = 1;
  handlePageChange(currentPage.value);
})

//stato dei bottoni per cambiare il substatus dell'ordine pending
const isButtonBarLoading = ref(false);
const isButtonKitchenLoading = ref(false);
const isButtonInvoiceLoading = ref(false);

//watcher per riprodurre l'audio all'arrivo di un nuovo ordine
watch(pendingOrders, (newOrders, oldOrders) => {
  if (newOrders.length > oldOrders.length && hasUserLandedOnPage.value && audioFlag.value) {
    audioDoorbell.value?.play();
  }
  if(!hasUserLandedOnPage.value){
    hasUserLandedOnPage.value = true;
  }
});

function toggleTab(tab: ServiceZone){
  activeServiceZone.value = tab;
}

function orderPaymentStatus(order: Order): PaymentStatus {
  return order.carts[0].pay.paymentStatus;
}

function changeCurrentOrder(order: Order) {
  const currentZone = activeServiceZone.value;
  const [orderReturned, unsubscribeOrder] = useSetupOrderInChargeListener(order.id);
  store.commit("changeOrderInChargeSubscription", {serviceZone: currentZone, unsubscribe: unsubscribeOrder});
  store.commit('setOrderInChargeByServiceZone', { order: orderReturned, serviceZone: activeServiceZone.value }); 
}

async function toggleOrderSubstatus(orderSubstatus: OrderPendingSubstatus){
  switch (orderSubstatus) {
    case OrderPendingSubstatus.PREPARED_BAR:
      isButtonBarLoading.value = true
      await useUpdateDocument(DbDocumentName.ORDER, orderInCharge.value!.id, {"document.isPreparedBar": true})
      isButtonBarLoading.value = false
      break;
    case OrderPendingSubstatus.PREPARED_KITCHEN:
      isButtonKitchenLoading.value = true
      await useUpdateDocument(DbDocumentName.ORDER, orderInCharge.value!.id, {"document.isPreparedKitchen": true})
      isButtonKitchenLoading.value = false
      break;
    case OrderPendingSubstatus.INVOICED:
      isButtonInvoiceLoading.value = true      
      await useUpdateDocument(DbDocumentName.ORDER, orderInCharge.value!.id, {
        "document.isInvoiced": true,
        "document.carts.0": {
          ...orderInCharge.value!.carts[0], // Mantiene tutto il contenuto del carrello
          "pay": {
            ...orderInCharge.value!.carts[0].pay, //mantiene tutto il contenuto dell'oggetto pay
            "paymentStatus": PaymentStatus.COMPLETE // Sovrascrive solo il campo "paymentStatus"
          }
        }
      }); 

      isButtonInvoiceLoading.value = false
      break;
    default:
      throw new Error("Order substatus not supported");
  }

  //cambio substatus
  store.commit('toggleOrderSubstatus', {orderId: orderInCharge.value?.id, serviceZone: activeServiceZone.value, orderPendingSubstatus: orderSubstatus});
  
  //verifico se si può segnare l'ordine come pagato sulla base dei 3 flag
  if(isOrderInPayedState(orderInCharge.value!)){
    await useUpdateDocument(DbDocumentName.ORDER, orderInCharge.value!.id, {"document.orderStatus": OrderStatus.COMPLETE});
    store.commit('deleteOrderInCharge', activeServiceZone.value);
  }
}

function isOrderInWaitingState(order: Order) : boolean {
  return !order.isInvoiced 
          && !order.isPreparedBar
          && (order.serviceZone === ServiceZone.takeaway || !order.isPreparedKitchen); 
}

function isOrderInPayedState(order: Order): boolean {
  return order.isInvoiced 
      && order.isPreparedBar 
      && (order.serviceZone != ServiceZone.onsit || order.isPreparedKitchen);
}

onMounted(() => {
  isLoading.value = false
  audioDoorbell.value = document.querySelector('audio');
});
</script>

<template>
  <audio ref="audioDoorbell" controls hidden="true"> 
    <source src="@/assets/audio/doorbell.mp3" type="audio/mp3">
  </audio>
  <NavbarComponent/>
  <div v-if="!isLoading">
    <div class="section qt-container">
      <!-- TABS -->
      <div class="columns is-vcentered">
        <div class="column">
          <div class="tabs is-toggle">
            <ul>
              <li :class="{ 'is-active': activeServiceZone === ServiceZone.onsit }" 
                  @click="toggleTab(ServiceZone.onsit)"
                  class="has-text-weight-bold">
                <a>{{ $t('room') }}</a>
              </li>
              <li :class="{ 'is-active': activeServiceZone === ServiceZone.standup }"
                  @click="toggleTab(ServiceZone.standup)"
                  class="has-text-weight-bold">
                <a>{{ $t('counter') }}</a>
              </li>
              <li :class="{ 'is-active': activeServiceZone === ServiceZone.takeaway }" 
                  @click="toggleTab(ServiceZone.takeaway)"
                  class="has-text-weight-bold">
                <a>{{ $t('takeaway') }}</a>
              </li>
            </ul>
          </div>
        </div>
        <div class="column is-narrow">
          <div class="is-pulled-right single-row-item" @click.prevent="store.commit('toogleAudio')">
              <figure class="icon mr-3 qt-icon-custom">
                <img v-if="audioFlag" :src="require(`@/assets/images/audio-on.png`)" alt="audio-on" />
                <img v-else :src="require(`@/assets/images/audio-off.png`)" alt="audio-off" />
              </figure>
              <span v-if="audioFlag" class="qt-text-p"><b>{{  $t('audio-on') }}</b></span>
              <span v-else class="qt-text-p"><b>{{  $t('audio-off') }}</b></span>
          </div>
        </div>
      </div>
      
      <!-- ORDINE PRESO IN CARICO -->
      <div class="mb-5">
        <p class="has-text-left mt-6 mb-5">
          <span class="qt-text-card-title-black">{{ $t('order_charge') }}</span>
        </p>
        <div class="section m-0 p-0">
            <div v-if="orderInCharge" class="qt-card">
              <p v-if="activeServiceZone != ServiceZone.onsit" class="qt-text-h3 has-text-left">{{ orderInCharge.identifyOrderClient }}</p>
              <hr v-if="activeServiceZone != ServiceZone.onsit" class="mt-2 mb-2">
              <div class="columns is-vcentered p-0 m-0">
                <div class="column pl-0">
                  <p class="qt-text-p"><b>{{ orderInCharge.identifyOrderApp }}</b></p>
                </div>
                <div class="column">
                  <p class="qt-text-p"><b>{{ useFormatData(orderInCharge.date) }}</b></p>
                </div>
                <div class="column" v-if="orderInCharge.table">
                  <p class="qt-text-p"><b>{{ $t('table') }} {{ orderInCharge.table }}</b></p>
                </div>
                <div class="column">
                  <p class="qt-text-p"><b>{{ $t('total_amount_label') }} {{ orderInCharge.totalAmount.toFixed(2) }}€</b></p>
                </div>
                <div class="column">
                  <div class="single-row-item">
                    <figure class="icon">
                      <img v-if="orderPaymentStatus(orderInCharge) === PaymentStatus.PENDING" class="is-small mr-2" :src="require(`@/assets/images/awaitTime.png`)" alt="awaitTime" />
                      <img v-else class="is-small mr-2" :src="require(`@/assets/images/successGreen.png`)" alt="awaitTime" />
                    </figure>
                    <span class="qt-text-p"><b>{{ useTranslatePaymentStatus(orderInCharge, Language.IT) }}</b></span>
                  </div>                  
                </div>
              </div>
              <div class="columns is-vcentered p-0 m-0">
                <div class="column pr-0">
                  <div class="single-row-item">
                    <button 
                      v-if="!orderInCharge.isPreparedBar" 
                      class="button is-link ml-4 mt-2 mb-2" 
                      :class="{ 'is-loading': isButtonBarLoading}"
                      @click="toggleOrderSubstatus(OrderPendingSubstatus.PREPARED_BAR)">
                      <span class="qt-text-p txt-white qt-txt-small">{{ $t('bar_ready') }}</span>
                    </button>
                    <div v-else  class="qt-logo-inline ml-5 mt-2 mb-2">
                      <figure class="icon">
                        <img class="is-small mr-2" :src="require(`@/assets/images/exclamationPointBlue.png`)" alt="awaitTime" />
                      </figure>
                      <span class="qt-text-p"><b>{{ $t('bar_ready') }}</b></span>
                    </div>

                    <!-- Cucina pronta button/label-->
                    <button 
                      v-if="orderInCharge.serviceZone === ServiceZone.onsit && !orderInCharge.isPreparedKitchen" 
                      class="button is-success ml-4 mt-2 mb-2"
                      :class="{ 'is-loading': isButtonKitchenLoading}"
                      @click="toggleOrderSubstatus(OrderPendingSubstatus.PREPARED_KITCHEN)">
                      <span class="qt-text-p txt-white qt-txt-small">{{ $t('kitchen_ready') }}</span>
                    </button>
                    <div v-else-if="orderInCharge.serviceZone === ServiceZone.onsit" class="qt-logo-inline ml-5 mt-2 mb-2">
                      <figure class="icon">
                        <img class="is-small mr-2" :src="require(`@/assets/images/exclamationPointLightBlue.png`)" alt="awaitTime" />
                      </figure>
                      <span class="qt-text-p"><b>{{ $t('kitchen_ready') }}</b></span>
                    </div> 

                    <!-- Scontrino emesso button/label-->
                    <button 
                      v-if="!orderInCharge.isInvoiced"
                      class="button is-danger ml-4 mt-2 mb-2"
                      :class="{ 'is-loading': isButtonInvoiceLoading}"
                      @click="toggleOrderSubstatus(OrderPendingSubstatus.INVOICED)">
                      <span class="qt-text-p txt-white qt-txt-small">{{ $t('receipt_issued') }}</span>
                    </button>
                    <div v-else  class="qt-logo-inline ml-5 mt-2 mb-2">
                      <figure class="icon">
                        <img class="is-small mr-2" :src="require(`@/assets/images/exclamationPointViolet.png`)" alt="awaitTime" />
                      </figure>
                      <span class="qt-text-p"><b>{{ $t('receipt_issued') }}</b></span>
                    </div> 

                  </div>
                </div>
              </div>
              <hr class="mt-2 mb-0">

              <!-- CARRELLO -->
              <div class="section m-0 p-0">
                <div class="columns mt-0 mb-3 m-0 p-0">
                  <div class="column is-6 qt-border pr-0">
                    <div class="qt-text-title-orange">{{ $t('bar') }}</div>
                    <CartDetailComponent 
                    :order="orderInCharge"
                    :cataloGroupType="CatalogGroupType.DRINK" />
                  </div>   
                  <div
                    v-if="orderInCharge.serviceZone === ServiceZone.onsit" 
                    class="column is-6">
                    <div 
                      class="qt-text-title-orange">
                      {{ $t('kitchen') }}
                    </div>
                    <CartDetailComponent 
                    :order="orderInCharge"
                    :cataloGroupType="CatalogGroupType.FOOD" />
                  </div>
                </div>
              </div>
            </div>
            <div v-else class="qt-card">
              <p class="has-text-left">
                <span class="qt-text-h3">{{ $t('no_order_charge') }}</span>
              </p>
            </div>
        </div>
      </div>

      <!-- CODA DEGLI ORDINI-->
      <div class="mb-5">
        <p class="has-text-left mt-6 mb-5">
          <span class="qt-text-card-title-black">{{ $t('order_queue') }}</span>
        </p>
        <div class="section m-1 p-1">
          <div v-if="queueOrders.length != 0">
            <div v-for="order in ordersToDisplay" :key="order.id" @click="changeCurrentOrder(order)">
              <div v-show="!orderInCharge || (orderInCharge && order.id !== orderInCharge.id)" class="columns is-vcentered qt-card mb-3 m-0 p-0">
                <div class="column">
                  <span class="qt-text-p"><b>{{ order.identifyOrderApp }}</b></span> 
                </div>
                <div v-if="activeServiceZone != ServiceZone.onsit" class="column">
                  <span class="qt-text-p"><b>{{ order.identifyOrderClient }}</b></span> 
                </div>
                <div class="column">
                  <span class="qt-text-p"><b>{{ useFormatData(order.date) }}</b></span> 
                </div>
                <div class="column" v-if="order.table">
                  <span class="qt-text-p"><b>{{ $t('table') }} {{ order.table }}</b></span>
                </div>
                <div class="column">
                  <span class="qt-text-p"><b>{{ $t('total_amount_label') }} {{ order.totalAmount.toFixed(2) }}€</b></span>
                </div>
                <div class="column">
                  <div class="single-row-item">
                    <figure class="icon">
                      <img v-if="orderPaymentStatus(order) === PaymentStatus.PENDING"  class="is-small mr-2" :src="require(`@/assets/images/awaitTime.png`)" alt="awaitTime" />
                      <img v-else class="is-small mr-2" :src="require(`@/assets/images/successGreen.png`)" alt="awaitTime" />
                    </figure>
                    <span class="qt-text-p"><b>{{ useTranslatePaymentStatus(order, Language.IT) }}</b></span>
                  </div> 
                </div>
                <!-- etichette flag -->
                <div class="column is-two-fifths">
                  <div class="buttons is-right">
                    <div v-if="order.isPreparedBar">
                      <span class="tag is-link is-medium is-rounded mr-1">
                        <span class="qt-text-p txt-white">{{ $t('bar_ready') }}</span>
                      </span>
                    </div>
                    <div v-if="order.isPreparedKitchen">
                      <span class="tag is-success is-medium is-rounded mr-1">
                        <span class="qt-text-p txt-white">{{ $t('kitchen_ready') }}</span>
                      </span>
                    </div>
                    <div v-if="order.isInvoiced">
                      <span class="tag is-danger is-medium is-rounded mr-1">
                        <span class="qt-text-p txt-white">{{ $t('receipt_issued') }}</span>
                      </span>
                    </div>
                    <div v-if="isOrderInWaitingState(order)">
                      <span class="tag is-grey is-medium is-rounded mr-1">
                        <span class="qt-text-p qt-txt-small">{{ $t('pending') }}</span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Paginazione -->
            <PaginationComponent class="mt-6"
              :totalItemsLength="queueOrders.length" 
              :itemsPerPage="itemsPerPage"
              v-model:initialPage="currentPage"
              @page-change="handlePageChange"
            />
          </div>
          <div v-else class="column is-full qt-card">
            <p class="has-text-left">
              <span class="qt-text-h3">{{ $t('no_order_queue') }}</span>
            </p>
          </div>
          </div>
        </div>
      </div>
  </div>
  <FooterComponent />
</template>

<style lang="css" scoped>
  .tabs.is-toggle li.is-active a {
    background: transparent linear-gradient(90deg, #F15A24 0%, #FFA800 100%) 0% 0% no-repeat padding-box;
    color:#000000;
    font-family: 'Roboto';
    z-index: 1;
}

  .tabs a {
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 0.5em 2em;
    vertical-align: top;
  }

  .tabs.is-toggle a {
    border-style: solid;
    border-width: 1px;
    margin-bottom: 0;
    position: relative;
    background: #ffff;
    font-family: 'Roboto-light';
  }
  
  .tabs.is-toggle li.is-active a,
  .tabs li.is-active a {
    border-color: #ffffff;
  }

  .qt-logo-inline{
    display: flex;
    align-items: center;
  }

  .box {
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1), 0 0px 0 1px rgba(10, 10, 10, 0.02); 
    color: #000000;
    display: block;
    padding: 1.25rem;
  }

  .image {
    display: block;
    position: absolute;
  }
  .txt-white{
    color: #ffffff !important;
    font-size: 13px;
  }

  .qt-txt-small{
    font-size: 13px;
  }

  .card-header {
    background-color: transparent;
    align-items: stretch;
    display: flow !important;
  }

  .qt-text-title-orange {
    font-size: 23px;
    }

  .buttons:last-child {
    margin-bottom: 0;
  }

  .single-row-item, .card-content-row-item {
    align-items: center;
    display: flex;
    justify-content: center;
  }

  .qt-text-p{
    text-align: center;
   }

  .qt-btm-center{
    justify-content: center;
  }

  .qt-border{
    border-right: solid;
    color: whitesmoke;
  }

  .qt-icon-custom {
    width: 2.5rem;
  }

  @media only screen and (min-width:200px) and (max-width:1024px) {

    .qt-text-p{
      font-size: 12px;
    }

    .qt-txt-small{
    font-size: 10px;
    }

    .qt-text-title-orange{
      font-size: 16px;
    }
  }
  
@media only screen and (max-width:768px) {

  .qt-border{
    border-width: 0;
  }

}
</style>