/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { firebaseConfig } from './firebaseConfig'
import { initializeApp } from 'firebase/app'
import type { DocumentSnapshot, Query, Timestamp, WhereFilterOp } from "firebase/firestore";
import { getFirestore, query, addDoc, collection, getDocs, where, doc, onSnapshot, DocumentData, updateDoc, getDoc, setDoc, orderBy, arrayUnion } from "firebase/firestore";
import { User, getAuth, onAuthStateChanged, signInWithEmailAndPassword, signOut } from 'firebase/auth'
import { onUnmounted, ref, Ref, computed, ComputedRef } from 'vue';
import { Cart, DbDocumentName, Order, Local, OrderStatus } from '@/types';
import { useFormatTimestampFromString } from '@/composables/useUtils';

const firebaseApp = initializeApp(firebaseConfig);
const db = getFirestore(firebaseApp);

export const getLoggedUser = async (): Promise<User | null> => {
    const auth = getAuth()
    const getUser = (): Promise<User | null> => {
        return new Promise(resolve => {
            onAuthStateChanged(auth, user => {
                resolve(user)
            })
        })
    }
    const user = await getUser()
    return user
}
export const userSignOut = () => {
    const auth = getAuth()
    signOut(auth)
}
export const emailSignIn = ({ email, password }: { email: string; password: string }) => {
    const auth = getAuth()
    return signInWithEmailAndPassword(auth, email, password)
}
export const useGetFirstElementCollectionByCondition = async (
    { collectionName, whereField, whereFilterOp, whereCondition }:
        { collectionName: DbDocumentName, whereField: string, whereFilterOp: WhereFilterOp, whereCondition: string | number, }
) => {
    const q = query(collection(db, collectionName), where(whereField, whereFilterOp, whereCondition));
    const querySnapshot = await getDocs(q);
    const coll = querySnapshot.docs.map(doc => doc.data());
    return coll[0];
}
export function useGetFirestoreListener(collectionName: DbDocumentName, documentId: string) {
    if (!documentId) {
        return null;
    }
    const db = getFirestore();
    const data: Ref<DocumentData | null> = ref(null);
    const docRef = doc(db, collectionName, documentId);
    const unsubscribe = onSnapshot(docRef, (doc) => {
        if (doc.exists()) {            
            data.value = doc.data();
        }
    });
    onUnmounted(unsubscribe);
    return data;
}
export async function useInsertDocument(collectionName: DbDocumentName, document: any): Promise<string> {
    //await setDoc(doc(db, "ordini", id), { order });//con id passato
    const docRef = await addDoc(collection(db, collectionName), { document });//con id generato
    return docRef.id;
}
export async function useUpdateDocument(collectionName: DbDocumentName, documentId: string, fields: Record<string, any>): Promise<void> {
    const documentRef = doc(db, collectionName, documentId);
    await updateDoc(documentRef, fields);
}
async function useGetDocument(collectionName: DbDocumentName, documentId: string): Promise<DocumentSnapshot> {
    const documentRef = doc(db, collectionName, documentId);
    const docSnap = await getDoc(documentRef);
    return docSnap;
}
export async function useGetOrderDocumentById(documentId: string): Promise<Order | null> {
    const docSnap = await useGetDocument(DbDocumentName.ORDER, documentId);
    if (docSnap.exists()){
        return docSnap.data().document as Order;
    } else {
        return null;
    }
}
export async function useUpdateOrderDocumentById(newOrder: Order): Promise<void> {
    const documentRef = doc(db, DbDocumentName.ORDER, newOrder.id);
    await setDoc(documentRef, { document: newOrder }, { merge: true });
}
export async function useAddCartIntoOrderById(documentId: string, cart:Cart): Promise<void> {
    const documentRef = doc(db, DbDocumentName.ORDER, documentId);
    await updateDoc(documentRef, {
        'document.carts': arrayUnion(cart)
    });
}
export async function useGetOrderDocumentByServiceZoneAndOrderStatusAndTable(serviceZone:string, orderStatus: OrderStatus, orderStatus2: OrderStatus, table: string): Promise<Order[]> {
    const q: Query<DocumentData, DocumentData> = query(
        collection(db, DbDocumentName.ORDER),
        where('document.serviceZone', '==', serviceZone),
        where('document.orderStatus', 'in', [orderStatus, orderStatus2]),
        where('document.table', '==', table),
        orderBy('document.date')
    );
    const orders = [] as Order[];
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
        const order: Order = doc.data().document as Order;
        orders.push(order);
    });
    return orders;
}
export async function useGetDocumentByOrderNumberOrTableNumber(orderNumber: string, tableNumber: string): Promise<Order[]> {
    const orders: Order[] = [];
    const today = new Date();
    const day = String(today.getDate()).padStart(2, '0');
    const month = String(today.getMonth() + 1).padStart(2, '0'); // Gennaio è 0!
    const year = today.getFullYear();
    const dateFrom = useFormatTimestampFromString(day + "-" + month + "-" + year, false);
    const dateTo = useFormatTimestampFromString(day + "-" + month + "-" + year, true);
    if (orderNumber != "") {
        const querySnapshot = await getDocs(query(
            collection(db, DbDocumentName.ORDER),
            where("document.identifyOrderApp", "==", orderNumber),
            where('document.date', ">=", dateFrom),
            where('document.date', "<=", dateTo),
            orderBy("document.date", "desc")
        ));
        querySnapshot.forEach((doc) => {
            orders.push(doc.data().document as Order);
        });
    }
    if (tableNumber != "") {
        const querySnapshot = await getDocs(query(
            collection(db, DbDocumentName.ORDER),
            where("document.table", "==", tableNumber),
            where('document.date', ">=", dateFrom),
            where('document.date', "<=", dateTo),
            orderBy("document.date", "desc")
        ));
        querySnapshot.forEach((doc) => {
            orders.push(doc.data().document as Order);
        });
    }
    return orders;
}
export function useGetPendingOrdersRealTime(localId: string | string[]) {
    const db = getFirestore();
    const pendingOrders = ref([] as Order[]);
    const q: Query<DocumentData, DocumentData> = query(
        collection(db, DbDocumentName.ORDER),
        where('document.idLocal', '==', localId),
        where('document.orderStatus', '==', OrderStatus.PENDING),
        orderBy('document.date')
    );
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
        pendingOrders.value = querySnapshot.docs.map(doc => doc.data().document as Order);
    });
    onUnmounted(unsubscribe);
    return computed(() => pendingOrders.value);
}

export function useSetupOrderInChargeListener(orderId: string): [ComputedRef<Order | null>, () => void]{
    const db = getFirestore();
    const order = ref<Order | null>(null);

    const unsubscribeOrder = onSnapshot(doc(db, DbDocumentName.ORDER, orderId), (doc) => {
        order.value = doc.data()!.document as Order
    });
    return [computed(() => order.value), unsubscribeOrder];
}

export async function useGetDailyReportByDate(dateFrom: Timestamp, dateTo: Timestamp): Promise<Order[]> {
    const q = getAllOrdersByDate(dateFrom, dateTo);
    return await getOrders(q);
}
export async function useGetDailyReportCompleteAndDeleted(dayOrdersTransactionsId: string): Promise<Order[]> {
    const q = getAllOrdersCompleteAndDeleted(dayOrdersTransactionsId);
    return await getOrders(q);
}
export async function useGetDailyReportOpenAndMaster(dayOrdersTransactionsId: string): Promise<Order[]> {
    const q = getAllOrdersOpenAndMaster(dayOrdersTransactionsId);
    return await getOrders(q);
}
export async function useGetDailyReportPending(dayOrdersTransactionsId: string): Promise<Order[]> {
    const q = getAllOrdersPending(dayOrdersTransactionsId);
    return await getOrders(q);
}
function getAllOrdersCompleteAndDeleted(dayOrdersTransactionsId: string): Query<DocumentData, DocumentData> {
    const q = query(
        collection(db, DbDocumentName.ORDER),
        where('document.dayOrdersTransactionsId', '==', dayOrdersTransactionsId),
        where('document.orderStatus', 'in', [OrderStatus.COMPLETE, OrderStatus.DELETED]),
        orderBy('document.date', 'desc')
    );
    return q;
}
function getAllOrdersOpenAndMaster(dayOrdersTransactionsId: string): Query<DocumentData, DocumentData> {
    const q = query(
        collection(db, DbDocumentName.ORDER),
        where('document.dayOrdersTransactionsId', '==', dayOrdersTransactionsId),
        where('document.orderStatus', 'in', [OrderStatus.OPEN, OrderStatus.MASTER_READY]),
        orderBy('document.date', 'desc')
    );
    return q;
}
function getAllOrdersPending(dayOrdersTransactionsId: string): Query<DocumentData, DocumentData> {
    const q = query(
        collection(db, DbDocumentName.ORDER),
        where('document.dayOrdersTransactionsId', '==', dayOrdersTransactionsId),
        where('document.orderStatus', '==', OrderStatus.PENDING),
        orderBy('document.date', 'desc')
    );
    return q;
}
function getAllOrdersByDate(dateFrom: Timestamp, dateTo: Timestamp): Query<DocumentData, DocumentData> {
        const q = query(
            collection(db, DbDocumentName.ORDER),
            where('document.date', '>=', dateFrom),
            where('document.date', '<=', dateTo),
            where('document.orderStatus', 'not-in', [OrderStatus.OPEN, OrderStatus.MASTER_READY]),
            orderBy('document.date', 'desc')
        );
    return q;
}
async function getOrders(q: Query<DocumentData, DocumentData>) {
    const orders = [] as Order[];
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
        const order: Order = doc.data().document as Order;
        orders.push(order);
    });
    return orders;
}

export function useGetOrderDocumentByIdRealtime(documentId: string){
    const order = ref<Order | undefined>(undefined);
    const unsubscribe = onSnapshot(doc(db, DbDocumentName.ORDER, documentId), (doc) => {
        order.value = doc.data()?.document as Order;
    });
    onUnmounted(unsubscribe);
    return computed(() => order.value);
}

export async function useDayOrdersTransactionsId(idLocale: string | string[]): Promise<string> {
    const locals = [] as Local[];
    const q = query(
        collection(db, DbDocumentName.LOCAL),
        where('id', '==', idLocale)
    );
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
        const local: Local = doc.data() as Local;
        locals.push(local);
    });
    return locals[0].dayOrdersTransactionsId;
}