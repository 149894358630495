<script setup lang="ts">
import { ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { redirectWithName } from "@/composables/useNavigation";
import { userSignOut } from "@/firebase/firebaseUtilities";
import { LOCAL_NAME, } from "@/constants/globalConst";
import LanguageComponent from "./LanguageComponent.vue";
import { useStore } from 'vuex';

const router = useRouter();
const route = useRoute();
const store = useStore();
const path = route.path;
const containsPrivate = window.location.href.includes("private");
const isMenuOpen = ref(false);

const toggleMenu = () => {
  isMenuOpen.value = !isMenuOpen.value;
};
function out() {
  userSignOut();
  store.commit("unsubscribeOrdersInCharge");
  redirectWithName(router, "login");
}
</script>

<template>
  <nav id="headerComponent" class="navbar center-container t1 qt-nav-container" :class="{ 'is-justify-content-center': !containsPrivate }" role="navigation" aria-label="main navigation" >
    <div class="navbar-brand">
      <img :src="require(`@/assets/images/QT/LogoHeader.png`)" class="qt-nav-img" />
      <span class="t1boldxxlarge is-align-self-center qt-span-space qt-txt-extra-bold">{{ LOCAL_NAME }}</span>
      <a v-if="containsPrivate" role="button" class="navbar-burger burger is-pulled-right" aria-label="menu" aria-expanded="false" @click="toggleMenu" >
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
      </a>
    </div>
    <div v-if="containsPrivate" class="navbar-menu" :class="{ 'is-active': isMenuOpen }">
      <div class="navbar-start">
        <span class="is-size-5 mt-3"></span>
        <router-link class="navbar-item" :to="{ name: 'local' }">
          <span :class="{ 'qt-text-orange-h3': path.endsWith('local'), 'qt-text-h3': !path.endsWith('local')}">Locale</span>
        </router-link> 
        <router-link class="navbar-item" :to="{ name: 'orders' }">
          <span :class="{ 'qt-text-orange-h3': path.endsWith('orders'), 'qt-text-h3': !path.endsWith('orders')}">Ordini</span>
        </router-link> 
        <router-link class="navbar-item" :to="{ name: 'searchOrders' }">
          <span :class="{ 'qt-text-orange-h3': path.endsWith('search'), 'qt-text-h3': !path.endsWith('search') }">Ricerca Ordini</span>
        </router-link> 
        <router-link class="navbar-item" :to="{ name: 'qrCode' }">
          <span :class="{ 'qt-text-orange-h3': path.endsWith('qrcode'), 'qt-text-h3': !path.endsWith('qrcode') }">QR code</span>
        </router-link>   
        <a class="navbar-item qt-show-tablet" @click="out">
          <span class="qt-text-h3 ">Logout</span>
        </a>
      </div>
      <div class="navbar-end">
        <div class="buttons qt-hidden-tablet" style="margin-bottom: 0rem;">
          <button class="button is-rounded round-button" @click="out">
            <img src="@/assets/images/logout.png" style="max-width: 200%;"  />
          </button>
        </div>
     </div>
    </div>
    <div v-else class="navbar-end">
      <LanguageComponent />
    </div>
  </nav>
  
</template>

<style lang="css" scoped>
.qt-span-space{
  padding-top: 0rem;
}
.round-button {
  width: 50px;
  height: 50px;
  background-color: #F7BF20;
}
.qt-show-tablet{
  display: none;
}

.round-button {
    width: 50px;
    height: 50px;
    background-color: #ffa800;
}

@media only screen and (max-width: 425px) {

  .qt-hidden-tablet{
    display: none;
  }

  .navbar-menu.is-active {
    display: flex;
    margin-left: -145px;
    margin-top: 239px;
  }

  .qt-show-tablet{
    display: block;
    border-top: 2px solid #F7BF20;
  }

}

@media only screen and (min-width:426px) and (max-width:1023px) {

  .navbar-menu.is-active {
    display: flex;
    margin-left: -145px;
    margin-top: 239px;
  }
  .qt-hidden-tablet{
    display: none;
  }

  .qt-show-tablet{
    display: block;
    border-top: 2px solid #F7BF20;
  }
}


</style>
