<!-- eslint-disable @typescript-eslint/no-explicit-any -->
<script setup lang="ts">
import { onBeforeMount, ref, watch } from "vue";
import { useGeneretePersonalNanoId } from "@/composables/useUtils";
import NavbarComponent from "@/components/NavbarComponent.vue";
import QRCodeVue3 from 'qrcode-vue3'
import FooterComponent from '@/components/FooterComponent.vue'
import { ServiceZone, Language } from '@/types';
import {useTranslateServiceZone} from "@/composables/useTranslate"
import { DEFAULT_VALUE, LOCAL_NAME } from '@/constants/globalConst';


const isLoading = ref<boolean>(false);
const id = ref(useGeneretePersonalNanoId());
const selectedColorBackground = ref('#FFFFFF');
const selectedColorBorder = ref('#000000');
const imageName = ref('Img.png');
const qrCodeValue = ref(DEFAULT_VALUE);
const showDiv = ref(false);
const showDivLogo = ref(true);
const selectedOption = ref<ServiceZone>(ServiceZone.takeaway);
const tableNumber = ref(DEFAULT_VALUE);
const url = ref(
  window.location.href.substring(0, window.location.href.indexOf("private")) 
  + "home"
  + "?serviceZone="+selectedOption.value
)
const isUploadImage = ref(false);

function changeIdUpdateQr() {
  updateUrl();
  id.value = useGeneretePersonalNanoId();
  if (selectedOption.value === ServiceZone.onsit) {
    showDivLogo.value = false;
    createImageQr();
  } else if (!isUploadImage.value) {
    showDivLogo.value = true;
    qrCodeValue.value = DEFAULT_VALUE;
  }
}
watch(selectedColorBackground, (newValue) => {
  selectedColorBackground.value = newValue;
});
watch(selectedColorBorder, (newValue) => {
  selectedColorBorder.value = newValue;
});
watch(imageName, (newValue) => {
  imageName.value = newValue;
  changeIdUpdateQr();
});

onBeforeMount(async () => {
  isLoading.value = false;
});

function createImageQr() {
  imageName.value = 'Img.png';
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d')!;
  // Impostare le dimensioni del canvas
  canvas.width = 550;
  canvas.height = 400;
  // Stilare il testo sull'immagine
  ctx.font = 'bold 97pt Roboto';
  ctx.textAlign = 'center';
  ctx.fillStyle = selectedColorBorder.value;
  ctx.fillText(tableNumber.value, 275, 225);
  // Creare un'immagine utilizzando il canvas
  const img = new Image();
  img.src = canvas.toDataURL('image/png');
  qrCodeValue.value = img.src;
  isUploadImage.value = false;
}

function handleFileChange(event: any) {
  try {
    const file = event.target.files[0];
    if (file && (file.name.endsWith("png") || file.name.endsWith("jpg"))) {
      const reader = new FileReader();
      reader.onload = () => {
        const imageUrl: string | ArrayBuffer | any | null = reader.result;
        imageName.value = file.name;
        if (imageUrl) {
          // imageUrl è una stringa
          qrCodeValue.value = imageUrl;
        } else if (imageUrl instanceof ArrayBuffer) {
          // imageUrl è una ArrayBuffer, trasformo ArrayBuffer in string
          qrCodeValue.value = new TextDecoder('utf-8').decode(imageUrl);
        }
      };
      reader.readAsDataURL(file);
      showDiv.value = false;
      isUploadImage.value = true;
    } else if (file && !file.name.endsWith("png") && !file.name.endsWith("jpg")) { 
      showDiv.value = true;
    }
  } catch (error) {
    console.error("Errore QRCodeView:", error);
    showDiv.value = true;
  }
}

function updateUrl() {
  let updatedUrl = url.value.slice(0, (url.value.indexOf("?serviceZone=") + "?serviceZone=".length)) + selectedOption.value;
  if (tableNumber.value && selectedOption.value === ServiceZone.onsit) {
    updatedUrl += "&table=" + tableNumber.value;
  }
  url.value = updatedUrl;
}

function generateDownloadOptions() {
  let name = 'qrCode_' + `${selectedOption.value}`;
  if (DEFAULT_VALUE !== tableNumber.value) {
    name += `_${tableNumber.value}`;
  }
  return {
    name: name,
    extension: 'png'
  };
}
</script>

<template>
  <div v-if="!isLoading">
    <NavbarComponent />
    <div class="qt-container">
      <div class="columns p-0 m-0 mb-4">
        <div class="column pl-0">
          <p class="qt-text-card-title-black has-text-left mb-2">{{ $t('qr_code_of') }} {{ LOCAL_NAME }}</p>
          <p class="qt-text-h3 has-text-left">{{ $t('set_qr_code') }}</p>
        </div>
      </div>
      <div class="section m-0 p-0">
        <div class="columns is-desktop pb-6">
          <div class="column is-5 is-12-mobile is-12-tablet is-5-desktop">
            <div v-show="showDivLogo">
              <p class="qt-text-h3 has-text-left mt-4 mb-2">{{ $t('insert_logo') }}</p>
              <div class="file has-name is-fullwidth">
                <label class="file-label">
                  <input class="file-input" type="file" name="resume" @change="handleFileChange">
                  <span class="file-cta qt-input-orange">
                    <span class="file-icon">
                      <i class="fas fa-upload">
                        <img src="@/assets/images/upload.png"/>
                      </i>
                    </span>
                    <span class="qt-text-p"><b>{{ $t('upload_logo') }}</b></span>
                  </span>
                  <span class="file-name qt-label-img">
                    {{ imageName }}
                  </span>
                </label>
              </div>
              <div v-show="showDiv" class="mt-1 has-text-danger">
                {{ $t('format_not_valid') }}
              </div>
            </div>

            <div class="mt-6">
              <p class="qt-text-h3 has-text-left mb-2">{{ $t('theme_colors') }}</p>
              <div class="qt-left mt-4">
                <div class="ml-2 mb-3">
                  <span class="pr-4 qt-text-p">{{ $t('background') }}</span>
                    <input @change="changeIdUpdateQr()" v-bind:style="{ background: selectedColorBackground }" v-model="selectedColorBackground" class="color-input input is-rounded" type="color">
                </div>
                <div  class="ml-2">
                  <span class="pr-5 qt-text-p">{{ $t('edge') }}</span>
                  <input @change="changeIdUpdateQr()" v-bind:style="{ background: selectedColorBorder }" v-model="selectedColorBorder" class="color-input input is-rounded" type="color">
                </div>
              </div>
            </div>

            <div class="mt-6">
              <p class="qt-text-h3 has-text-left mb-2">{{ $t('service_zone') }}</p>
              <div class="select is-fullwidth mt-2 qt-text-p">
                <select @change="changeIdUpdateQr()" id="dropdown" v-model="selectedOption">
                  <option v-for="zone in ServiceZone" :key="zone" :value="zone">
                    {{useTranslateServiceZone(zone, Language.IT)}}
                  </option>
                </select>
              </div>
            </div>

            <div class="mt-6" v-if="selectedOption === ServiceZone.onsit">
              <p class="qt-text-h3 has-text-left mb-2">{{ $t('table_number') }}</p>
              <div class="is-fullwidth mt-2 qt-text-p">
                <input @input="changeIdUpdateQr()" v-model="tableNumber" class="input" type="text" maxlength="5">
              </div>
            </div>
          </div>
          <div class="column is-2 is-12-mobile is-12-tablet is-2-desktop"></div>
          <div class="column is-5 is-12-mobile is-12-tablet is-5-desktop">
            <div class="qt-card">
              <div class="container content has-text-centered qt-continer-sapcing">
                <p class="has-text-centered qt-text-h3 mt-4">{{ $t('preview_qr_code') }}</p>
                <br/>
                <QRCodeVue3
                  :key="id"
                  :width="300"
                  :height="300"
                  :value=url
                  :qr-options="{ errorCorrectionLevel: 'H' }"
                  :image="qrCodeValue"
                  :image-options="{ hideBackgroundDots: true, imageSize: 0.3, margin: 7, crossOrigin: true }"
                  :corners-square-options="{ type: 'square', color: `${selectedColorBorder}` }"
                  :corners-dot-options="{
                    type: undefined,
                    color: `${selectedColorBorder}`
                  }"
                  :dots-options="{
                    type: 'square',
                    color: `${selectedColorBorder}`
                  }"
                  :backgroundOptions="{
                    color: `${selectedColorBackground}`
                  }"
                  :download="true"
                  downloadButton="button qrt-orange mt-5"
                  :downloadOptions="generateDownloadOptions()"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <FooterComponent />
  </div>
</template>

<style lang="css" scoped>
  input.color-input {
    border-color: #000000;
    width: 2rem;
    height: 2rem;
  }
  input.color-input::-webkit-color-swatch {
    border: none;
  }
  .notification{
    margin-bottom: 0rem;
  }

  .qt-continer-sapcing {
    padding: 1rem;
  }

  .qt-left{
    text-align: left;
  }

  .qt-btn-qr{

    background: transparent linear-gradient(90deg, #F15A24 0%, #FFA800 100%) 0% 0% no-repeat padding-box;
    border-radius: 10px;
    opacity: 1;
    width: 219px;
  }
  
</style>
