import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueAwesomePaginate from "vue-awesome-paginate";
import { createI18n } from 'vue-i18n';
import loadLocaleMessages from './i18n/i18n'; 

import 'bulma'
import 'vue-awesome-paginate/dist/style.css';
import './assets/templates/main.css'
import './assets/templates/mainV2.css'

export const i18n = createI18n({
  legacy: false,
  locale: 'it',
  messages: loadLocaleMessages(),
});

createApp(App)
    .use(store)
    .use(router)
    .use(i18n)
    .use(VueAwesomePaginate)
    .mount('#app')
