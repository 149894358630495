<!-- eslint-disable @typescript-eslint/no-explicit-any -->
<script setup lang="ts">
import { QrcodeStream } from 'vue-qrcode-reader'
import { ref } from 'vue';
import { i18n } from '@/main'; 

const isCameraOpen = ref<boolean>(false);
function openClose() {
  isCameraOpen.value = !isCameraOpen.value;
}
async function onReady(promise: Promise<any>) {
  try {
    await promise;
    isCameraOpen.value = true;
  } catch (error) {
    const testo = i18n.global.t('alert_message_qr_poto');
    alert(testo);
  }
}
const onDetect = (detectedCodes: any[]) => {
  const qrCodeUrl = detectedCodes[0]['rawValue'];
  isCameraOpen.value = false;
  if (isValidUrl(qrCodeUrl)) {
    window.location.href = qrCodeUrl;
  } else {
    const testo = i18n.global.t('alert_message_qr');
    alert(testo);
  }
};
function isValidUrl (string:string) {
  try {
    new URL(string);
    return true;
  } catch (error) {
    return false;  
  }
}
</script>

<template>
  <div>
    <div class="qt-card mt-3 mb-5" @click="openClose()" style="cursor: pointer;">
      <p class="mt-4">
        <img src="@/assets/images/qr.png"/>      
      </p>
      <p class="mt-2">
        <span class="qt-text-p"><b>{{ $t('scan_qr_code') }}</b></span>
      </p>
    </div>

    <div v-if="!isCameraOpen" class="mt-4 ml-4 mr-4 mb-4">
      <span class="qt-text-p">{{ $t('after_qr_code_scanned') }}</span>
    </div>
    <div v-else class="mt-4 ml-4 mr-4 mb-4 text-container" @click="openClose()" style="cursor: pointer;">
      <div>
        <qrcode-stream v-if="isCameraOpen" @detect="onDetect" @camera-on="onReady"> </qrcode-stream>
      </div>
    </div>
  </div>
</template>
