<script setup lang="ts">
import { ref, onBeforeMount } from 'vue';
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import NavbarComponent from "@/components/NavbarComponent.vue";
import FooterComponent from '@/components/FooterComponent.vue'
import { DbDocumentName, Order, ProviderPay, OrderStatus, Language } from '@/types';
import { useUpdateDocument, useGetDailyReportCompleteAndDeleted, getLoggedUser, useGetDailyReportByDate, useGetDailyReportOpenAndMaster, useGetDailyReportPending } from "@/firebase/firebaseUtilities";
import * as XLSX from 'xlsx';
import { useCaricaLocal } from "@/composables/useInit";
import { useFormatData, useConcatenatedString, useDayOrdersTransactionsId, useScrollToTop, useFormatTimestampFromStartDate, useFormatTimestampFromEndDate } from "@/composables/useUtils";
import { CURRENCY_CODE } from "@/constants/globalConst";
import { redirectWithName } from '@/composables/useNavigation';
import { useTranslateOrderStatus } from "@/composables/useTranslate";
import { User } from 'firebase/auth';
import '@vuepic/vue-datepicker/dist/main.css';
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';import { Italian } from 'flatpickr/dist/l10n/it.js';
import { German } from 'flatpickr/dist/l10n/de.js';
import { French } from 'flatpickr/dist/l10n/fr.js';
import { Mandarin } from 'flatpickr/dist/l10n/zh.js';
import { Spanish } from 'flatpickr/dist/l10n/es.js';
import { CustomLocale } from 'flatpickr/dist/types/locale';
import { Timestamp } from 'firebase/firestore';

const route = useRoute();
const router = useRouter();
const store = useStore();
const idLocale = useConcatenatedString(route.params.idLocale);
let ordersMemoryLocal = [] as Order[];
let ordersOpenAndMaster = [] as Order[];
let ordersPending = [] as Order[];
const isLoading = ref<boolean>(true);
const isOpen = ref<boolean>(false);
const dayOrdersTransactionsId = ref<string>("");
const loggedUser = ref<User|null>();
const date = ref("");

onBeforeMount(async () => {
  const local = await useCaricaLocal(idLocale);
  isOpen.value = local.isOpen;
  dayOrdersTransactionsId.value = local.dayOrdersTransactionsId;
  ordersMemoryLocal = store.getters.getOrders;
  isLoading.value = false;
  useScrollToTop();
  loggedUser.value = await getLoggedUser();
});

const localCountryCode: string = store.getters.getLocalDefaultLanguage;
const langMap: { [key: string]: CustomLocale } = {
    "it": Italian,
    "de": German,
    "fr": French,
    "zh": Mandarin,
    "es": Spanish
};
const config = ref({
  mode: "range",
  dateFormat: 'd-m-Y',
  /*enable: [{
    from: new Date(new Date().setDate(new Date().getDate() - 2)),
    to: new Date(new Date().setDate(new Date().getDate()))
  }],*/
  locale: langMap[localCountryCode] ?? 'default'
});

async function closePending() {
  ordersPending = await fetchDataPending(dayOrdersTransactionsId.value);
  if (ordersPending.length > 0) {
    if (confirm("ORDINI in stato pending, se si procede verranno annullati, vuoi proseguire?")) {
      for (let i = 0; i < ordersPending.length; i++) {
        await useUpdateDocument(
        DbDocumentName.ORDER,
        ordersPending[i].id,
        {
          "document.orderStatus": OrderStatus.DELETED
        }
      );
      }
      ordersPending = [];
    }
  }
  changeStatus();
}

async function closeOpenAndMaster() {
  ordersOpenAndMaster = await fetchDataOpenAndMaster(dayOrdersTransactionsId.value);
  for (let i = 0; i < ordersOpenAndMaster.length; i++) {
    await useUpdateDocument(
    DbDocumentName.ORDER,
    ordersOpenAndMaster[i].id,
    {
      "document.orderStatus": OrderStatus.DELETED
    }
  );
  }
  changeStatus();
}

async function changeStatus() {
  if ((isOpen.value && ordersPending.length <= 0) || !isOpen.value) {
    isOpen.value = !isOpen.value;
    await useUpdateDocument(
      DbDocumentName.LOCAL,
      idLocale,
      {
        "isOpen": isOpen.value,
      }
    );
    if (isOpen.value) {
      dayOrdersTransactionsId.value = useDayOrdersTransactionsId(idLocale);
      await useUpdateDocument(
      DbDocumentName.LOCAL,
      idLocale,
      {
        "dayOrdersTransactionsId": dayOrdersTransactionsId.value,
      }
    );
    }
  }
}

const buttonClick = async () => {
  if (date.value !== "") {
    const dateFrom = useFormatTimestampFromStartDate(date.value);
    const dateTo = useFormatTimestampFromEndDate(date.value);
    ordersMemoryLocal = await fetchDataByDate(dateFrom, dateTo);
  } else {
    ordersMemoryLocal = await fetchDataCompleteAndDeleted(dayOrdersTransactionsId.value);
  }
  //const pending = ordersMemoryLocal.filter(order => order.orderStatus === OrderStatus.PENDING);
  /*if (date.value === "" && pending.length > 0) {
    alert ("ORDINI in stato non valido per scaricare il report, annullarli per poter chiudere la cassa e scaricare il report");
    store.commit("setOrders", pending);
    return redirectWithName(router, "searchOrders");
  }*/
  exportToExcel(ordersMemoryLocal);
};

function exportToExcel (orders: Order[]) {
  const wb = XLSX.utils.book_new();
  // ciclo su tutti i valori dell'enum
  let ordersDeleted = [] as Order[];
  let ordersCompleted = [] as Order[];
  for (const providerPay in ProviderPay) {
    if (Object.prototype.hasOwnProperty.call(ProviderPay, providerPay)) {
      const providerValue = ProviderPay[providerPay as keyof typeof ProviderPay];
      const providerOrders = orders.filter(order => order.carts[0].pay.providerPay === providerValue && order.orderStatus != OrderStatus.DELETED);
      ordersCompleted = ordersCompleted.concat(orders.filter(order => order.carts[0].pay.providerPay === providerValue && order.orderStatus === OrderStatus.COMPLETE));
      ordersDeleted = ordersDeleted.concat(orders.filter(order => order.carts[0].pay.providerPay === providerValue && order.orderStatus === OrderStatus.DELETED));
      if (providerPay != ProviderPay.TABLEPAY) {
        let total_totalAmount = 0;
        let total_totalCurrency = "";
        const rows = providerOrders.map(order => ({
          "DATA CREAZIONE": useFormatData(order.date),
          "ID TRANSAZIONE": order.dayOrdersTransactionsId,
          ID: order.id,
          "ID LOCALE": order.idLocal,
          "STATO ORDINE": useTranslateOrderStatus(order.orderStatus, Language.IT),
          "IDENTIFICATIVO ORDINE APP": order.identifyOrderApp,
          "IDENTIFICATIVO ORDINE CLIENTE": order.identifyOrderClient,
          TOTALE: order.totalAmount,
          VALUTA: CURRENCY_CODE.toLowerCase(),
          "PROVIDER PAGAMENTO": order.carts[0].pay.providerPay.toLowerCase(),
          "ID TRANSAZIONE PROVIDER": order.carts[0].pay.providerTransactionId,
        }));
        // Calcola i totali per questo providerPay
        providerOrders.forEach(order => {
            total_totalAmount += order.totalAmount;
            total_totalCurrency = CURRENCY_CODE
        });
        // preparo  righe vuote e totali
        const extraRows = [
          {}, {}, {},
          {
            "DATA CREAZIONE": "",
            "ID TRANSAZIONE": "",
            ID: "",
            "ID LOCALE": "",
            "STATO ORDINE": "",
            "IDENTIFICATIVO ORDINE APP": "",
            "IDENTIFICATIVO ORDINE CLIENTE": "Totale:",
            TOTALE: total_totalAmount,
            VALUTA: total_totalCurrency.toLowerCase(),
            "PROVIDER PAGAMENTO": "",
            "ID TRANSAZIONE PROVIDER": "",
          }
        ];
        const finalData = [...rows, ...extraRows];
        const ws = XLSX.utils.json_to_sheet(finalData);
        XLSX.utils.book_append_sheet(wb, ws, 'Orders_'+providerValue);
        XLSX.utils.book_set_sheet_visibility(wb, 'Orders_'+providerValue, 1);
      }
    }
  }
  let rows = ordersCompleted.map(order => ({
    "DATA CREAZIONE": useFormatData(order.date),
    "ID TRANSAZIONE": order.dayOrdersTransactionsId,
    ID: order.id,
    "ID LOCALE": order.idLocal,
    "STATO ORDINE": useTranslateOrderStatus(order.orderStatus, Language.IT),
    "IDENTIFICATIVO ORDINE APP": order.identifyOrderApp,
    "IDENTIFICATIVO ORDINE CLIENTE": order.identifyOrderClient,
    TOTALE: order.totalAmount,
    VALUTA: CURRENCY_CODE.toLowerCase(),
    "PROVIDER PAGAMENTO": order.carts[0].pay.providerPay.toLowerCase(),
    "ID TRANSAZIONE PROVIDER": order.carts[0].pay.providerTransactionId,
  }));
  let total_totalAmount = 0;
  let total_totalCurrency = "";
  // Calcola i totali per questo providerPay
  ordersCompleted.forEach(order => {
      total_totalAmount += order.totalAmount;
      total_totalCurrency = CURRENCY_CODE
  });
  // preparo  righe vuote e totali
  let extraRows = [
    {}, {}, {},
    {
      "DATA CREAZIONE": "",
      "ID TRANSAZIONE": "",
      ID: "",
      "ID LOCALE": "",
      "STATO ORDINE": "",
      "IDENTIFICATIVO ORDINE APP": "",
      "IDENTIFICATIVO ORDINE CLIENTE": "Totale:",
      TOTALE: total_totalAmount,
      VALUTA: total_totalCurrency.toLowerCase(),
      "PROVIDER PAGAMENTO": "",
      "ID TRANSAZIONE PROVIDER": "",
    }
  ];
  let finalData = [...rows, ...extraRows];
  let ws = XLSX.utils.json_to_sheet(finalData);
  XLSX.utils.book_append_sheet(wb, ws, 'Orders_' + ProviderPay.TABLEPAY);
  rows = ordersDeleted.map(order => ({
    "DATA CREAZIONE": useFormatData(order.date),
    "ID TRANSAZIONE": order.dayOrdersTransactionsId,
    ID: order.id,
    "ID LOCALE": order.idLocal,
    "STATO ORDINE": useTranslateOrderStatus(order.orderStatus, Language.IT),
    "IDENTIFICATIVO ORDINE APP": order.identifyOrderApp,
    "IDENTIFICATIVO ORDINE CLIENTE": order.identifyOrderClient,
    TOTALE: order.totalAmount,
    VALUTA: CURRENCY_CODE.toLowerCase(),
    "PROVIDER PAGAMENTO": order.carts[0].pay.providerPay.toLowerCase(),
    "ID TRANSAZIONE PROVIDER": order.carts[0].pay.providerTransactionId,
  }));
  total_totalAmount = 0;
  total_totalCurrency = "";
  // Calcola i totali per questo providerPay
  ordersDeleted.forEach(order => {
      total_totalAmount += order.totalAmount;
      total_totalCurrency = CURRENCY_CODE
  });
  // preparo  righe vuote e totali
  extraRows = [
    {}, {}, {},
    {
      "DATA CREAZIONE": "",
      "ID TRANSAZIONE": "",
      ID: "",
      "ID LOCALE": "",
      "STATO ORDINE": "",
      "IDENTIFICATIVO ORDINE APP": "",
      "IDENTIFICATIVO ORDINE CLIENTE": "Totale:",
      TOTALE: total_totalAmount,
      VALUTA: total_totalCurrency.toLowerCase(),
      "PROVIDER PAGAMENTO": "",
      "ID TRANSAZIONE PROVIDER": "",
    }
  ];
  finalData = [...rows, ...extraRows];
  ws = XLSX.utils.json_to_sheet(finalData);
  XLSX.utils.book_append_sheet(wb, ws, 'Orders_' + OrderStatus.DELETED);
  wb.SheetNames.forEach((sheetName) => {
    const sheet = wb.Sheets[sheetName];
    const range = XLSX.utils.decode_range(sheet['!ref']!);
    const colWidths = [];
    for (let C = range.s.c; C <= range.e.c; ++C) {
      let maxWidth = 10; // Imposta una larghezza minima
      for (let R = range.s.r; R <= range.e.r; ++R) {
        const cellAddress = { c: C, r: R };
        const cellRef = XLSX.utils.encode_cell(cellAddress);
        const cell = sheet[cellRef];
        if (cell && cell.v) {
          const cellValue = cell.v.toString();
          const cellWidth = cellValue.length;
          if (cellWidth > maxWidth) {
            maxWidth = cellWidth;
          }
        }
      }
      colWidths[C] = { wch: maxWidth };
    }
    sheet['!cols'] = colWidths;
  });
  XLSX.writeFile(wb, 'ordersReport_NON_VALIDO_AI_FINI_FISCALI.xlsx');
}

async function fetchDataCompleteAndDeleted (localDayOrdersTransactionsId: string): Promise<Order[]> {
  let orders =  [] as Order[];
  try {
    orders = await useGetDailyReportCompleteAndDeleted(localDayOrdersTransactionsId);
  } catch (error) {
    console.error('Errore nel recupero dei dati:', error);
  }
  return orders;
}

async function fetchDataByDate (dateFrom: Timestamp, dateTo: Timestamp): Promise<Order[]> {
  let orders =  [] as Order[];
  try {
    orders = await useGetDailyReportByDate(dateFrom, dateTo);
  } catch (error) {
    console.error('Errore nel recupero dei dati:', error);
  }
  return orders;
}

async function fetchDataOpenAndMaster (localDayOrdersTransactionsId: string): Promise<Order[]> {
  let orders =  [] as Order[];
  try {
    orders = await useGetDailyReportOpenAndMaster(localDayOrdersTransactionsId);
  } catch (error) {
    console.error('Errore nel recupero dei dati:', error);
  }
  return orders;
}

async function fetchDataPending (localDayOrdersTransactionsId: string): Promise<Order[]> {
  let orders =  [] as Order[];
  try {
    orders = await useGetDailyReportPending(localDayOrdersTransactionsId);
  } catch (error) {
    console.error('Errore nel recupero dei dati:', error);
  }
  return orders;
}

</script>

<template>
  <div v-if="!isLoading">
    <NavbarComponent/>
    <section class="qt-container qt-container-med">
      <div class="columns is-vcentered">
        <div class="column is-12-mobile is-8-tablet is-8-desktop has-text-left">
          <span class="qt-text-card-title-black">{{ $t('local') }}</span>
          <br/>
          <span v-if="!isOpen" class="qt-text-h3">{{ $t('click_open_cash') }}</span>
          <span v-else class="qt-text-h3">{{ $t('click_close_cash') }}</span>
        </div>
        <div class="column is-12-mobile is-4-tablet is-4-desktop">
          <button v-if="!isOpen" class="button btn-green" @click="closeOpenAndMaster()">
            <span class="btn-txt-white">{{ $t('open_cash') }}</span>
          </button>
          <button v-else class="button btn-red" @click="closePending()">
            <span class="btn-txt-white">{{ $t('close_orders') }}</span>
          </button>
        </div>
      </div>
      <div v-if="!isOpen && loggedUser?.email != 'admin2@admin.com'" class="columns qt-spacer">
        <div class="column is-12 qt-card qt-margin">
          <div class="columns is-vcentered">
            <div class="column is-9 has-text-left">
              <span class="qt-text-h3">{{ $t('click_download_report') }}</span>
            </div>
            <div class="column is-3">
              <button class="button btn-orange" @click="buttonClick">
                <img class="mr-1" src="@/assets/images/raggruppa.png"/>
                <span class="btn-txt">{{ $t('download_report') }}</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div v-if="loggedUser?.email === 'admin2@admin.com'" class="columns qt-spacer">
        <div class="column is-12 qt-card qt-margin">
          <div class="columns is-vcentered">
            <div class="column is-9 has-text-left">
              <span class="qt-text-h3">{{ $t('click_download_report_admin') }}</span>
            </div>
            <div class="column is-3">
              <button class="button btn-orange" @click="buttonClick">
                <img class="mr-1" src="@/assets/images/raggruppa.png"/>
                <span class="btn-txt">{{ $t('download_report') }}</span>
              </button>
              <flat-pickr
                placeholder="Da            A"
                v-model="date"
                :config="config"
              />&nbsp;
              <img src="@/assets/images/calendar.png" />&nbsp;
            </div>
          </div>
        </div>
      </div>
    </section>
    <FooterComponent />
  </div>
</template>

<style lang="css" scoped>

  .qt-spacer{
    margin-top: auto;
  }

  .qt-text-h3 {
    font-weight: normal;
  }

  .qt-margin{
    margin-bottom: 6rem !important;
  }

  .btn-txt{
    font-size: 16px;
  }
  .btn-txt-white{
    font-weight: normal;
  }
</style>: any[]: any[](: any)(: (arg0: any) => any)