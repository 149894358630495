/* eslint-disable @typescript-eslint/no-explicit-any */
import { Timestamp } from 'firebase/firestore';
export enum DbDocumentName {
  CATALOG = 'catalog',
  LOCAL = 'local',
  ORDER = 'order',
}
export enum Language {
  IT = 'IT',
}
export enum CartActionTypes {
  ADD = 'ADD',
  SUBTRACT = 'SUBTRACT',
  RESET = 'RESET',
}
export enum CatalogSpecialOfferName {
  PLUS = 'PLUS',
  MINUS = 'MINUS',
}
export enum CatalogSpecialOfferType {
  PRICE = 'PRICE',
  PERCENT = 'PERCENT',
}
export enum CatalogGroupType {
  FOOD = 1,
  DRINK = 2,
}
export enum ProviderPay {
  AWAITING = 'AWAITING',
  TABLEPAY = 'TABLEPAY',
  GOOGLEPAY = 'GOOGLEPAY',
  SATISPAY = 'SATISPAY',
}
export enum PayEvent {
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
  NOBODY = 'NOBODY',
}
export enum PaymentStatus {
  PENDING = 'PENDING',
  COMPLETE = 'COMPLETE',
  REJECTED = 'REJECTED',
}
export enum OrderStatus {
  OPEN = 'OPEN', //shoppingList non completa
  MASTER_READY = 'MASTER_READY', //shoppingList solo master completa
  PENDING = 'PENDING', //in attesa di preparazione
  COMPLETE = 'COMPLETE', //completato
  DELETED = 'DELETED', //cancellato
}
export enum OrderPendingSubstatus {
  PREPARED_BAR = 'PREPARED_BAR', //pronto dal Bar
  PREPARED_KITCHEN = 'PREPARED_KITCHEN', //pronto dalla cucina
  INVOICED = 'INVOICED', //scontrino emesso
}
export enum ServiceZone {
  takeaway = 'takeaway',
  onsit = 'onsit',
  standup = 'standup',
}

export type Local = {
  id: string,
  dayOrdersTransactionsId: string,
  isOpen: boolean,
  serviceZoneCatalogs: ServiceZoneCatalog[],
}
export type ServiceZoneCatalog = {
  serviceZone: string,
  laterPay: boolean,
  catalogs: Catalog[],
}
export type Catalog = {
  alcol: boolean,
  catalogId: string,
  groupType: number,
  specialOffer: boolean,
  specialOfferName: string,
  specialOfferFee: number,
  specialOfferType: string,
}
export type CatalogItem = {
  id: string,
  logoPath: string,
  names: Names,
  productInfo: ProductInfo[],
}
export type Names = {
  [key: string]: Name, 
  it: Name,
}
export type Name = {
  name: string,
}
export type ProductInfo = {
  id: number,
  logicImplement: number,
  imagePath: string,
  descriptions: Descriptions,
  compositions: Composition[],
}
export type Descriptions = {
  [key: string]: Description; //definisco il tipo di accesso anche come stringa; per utilizzare il costrutto props.item.descriptions[selectedLanguage].name
  it: Description; //utilizza il costrutto classico props.item.descriptions.it.ingredient
}
export type Description = {
  ingredient: string,
  name: string,
  note: string,
}
export type Composition = {
  id: number,
  ivaPercent: number,
  specialOffer: boolean
	specialUnityPrice: number,
  unityPrice: number,
  names: Names,
  visulizeAlcol: boolean,
}
export type Shopping = {
	catalogId: string,
	catalogGroupType: number,
	catalogSpecialOffer: boolean,
	catalogSpecialOfferName: string,
	catalogSpecialOfferFee: number,
	catalogSpecialOfferType: string,
	catalogItemLogoPath: string,
	catalogItemName_it: string,
  catalogItemName_en: string,
  father: {
    serviceZone: string,
    catalogId: string,
    productInfoId: number,
    productInfoCompositionsId: number,
    productInfoCompositionsUnityPriceEffective: number,
    productInfoDescriptionsName_it: string,
    productInfoDescriptionsName_en: string,
  },
	productInfoId: number,
	productInfoLogicImplement: number,
	productInfoDescriptionsName_it: string,
	productInfoDescriptionsName_en: string,
	productInfoCompositionsId: number,
	productInfoCompositionsName_it: string,
	productInfoCompositionsName_en: string,
	productInfoCompositionsQuantity: number,
	productInfoCompositionsUnityPriceEffective: number,
	serviceZone: string,
}
export type Order = {
  id: string,
  carts: Cart[],
  dayOrdersTransactionsId: string,
  date: Timestamp,
  identifyOrderApp: string,
  identifyOrderClient: string,
  idLocal: string,
  isInvoiced: boolean,
  isPreparedBar: boolean,
  isPreparedKitchen: boolean,
  orderStatus: OrderStatus,
  serviceZone: string,
  table: string,
  totalAmount: number,
}
export type Cart = {
  id: string,
  pay: Pay,
  serviceZone: string,
  shoppings: Shopping[],
  totalShop: number,
}
export type Pay = {
  id: string,
  date: Timestamp,
  environment: string,
  merchantId: string,
  merchantName: string,
  payEvent: PayEvent,
  paymentStatus: PaymentStatus,
  providerPay: ProviderPay,
  providerTransactionId: string,
  redirectUrl2FA: string
  request: PayRequest,
  response: PayResponse,
}
export type PayRequest = {
  countryCode: string,
  currencyCode: string,
  totalPrice: number,
}
export type PayResponse = {
  circuit: string,
  method: string,
  type: string,
}