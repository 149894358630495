export const ENVIROMENT = process.env.VUE_APP_ENVIROMENT
export const ENABLE_GOOGLEPAY = false
export const ENABLE_SATISPAY = false
export const COUNTRY_CODE = "IT"
export const LOCAL_NAME = "Quick Tail"
export const CURRENCY = "€"
export const CURRENCY_CODE = "EUR"
export const COOKIE_NAME = "$£MEB_cookie_QT£$"
export const COOKIE_MINUTE_TIME_EXPIRE = 1
export const DEFAULT_VALUE = ""
export const SATISPAY_RESPONSE_ID = "satispayResponseId"
export const PAGINATE_PREV_BUTTON_NAME_IT = "Precedente"
export const PAGINATE_NEXT_BUTTON_NAME_IT = "Successivo"