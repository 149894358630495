<!-- eslint-disable @typescript-eslint/no-explicit-any -->
<script setup lang="ts">
import { ref, onBeforeMount, watch, onMounted, computed } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import FooterComponent from '@/components/FooterComponent.vue'
import NavbarComponent from "@/components/NavbarComponent.vue";
import CartSummaryComponent from "@/components/CartSummaryComponent.vue";
import PayTableComponent from "@/components/PayTableComponent.vue";
import PayGoogleComponent from "@/components/PayGoogleComponent.vue";
import PaySatisComponent from "@/components/PaySatisComponent.vue";
import { useGetFirestoreListener, useInsertDocument, useUpdateDocument } from "@/firebase/firebaseUtilities";
import { redirectMenu, redirectThankyou } from "@/composables/useNavigation";
import { useConcatenatedString, useCreateCart, useCreateOrder, useScrollToTop, useUpdateCookie } from "@/composables/useUtils";
import { ENABLE_SATISPAY, ENABLE_GOOGLEPAY, CURRENCY, ENVIROMENT, DEFAULT_VALUE } from "@/constants/globalConst"
import { Cart, DbDocumentName, OrderStatus, Pay, PayEvent, ProviderPay, Shopping } from "@/types";
import { Order } from "@/types";
import {useSatisPayCreatePayment} from "@/composables/useSatisPay"

const store = useStore();
const router = useRouter();
const route = useRoute();
const idLocale = useConcatenatedString(route.params.idLocale);
const isOpen = ref (store.getters.getLocalIsOpen);
const dayOrdersTransactionsId = ref (store.getters.getLocalDayOrdersTransactionsId);
const localListened = ref(useGetFirestoreListener(DbDocumentName.LOCAL, idLocale ));
const serviceZone = useConcatenatedString(route.query.serviceZone);
const table = useConcatenatedString(route.query.table);
const query = route.query;
const identificativoOrdineCliente = ref(DEFAULT_VALUE);
const visualizzaErrIdUtente = ref(false);
const totalPay = computed(() => { return store.getters.getTotalPay; });
const payLater: boolean = store.getters.getLocalServiceZoneIsLaterPay(serviceZone);
const selectedRadio = ref("CASSA");

onBeforeMount(async () => {
  const s:Shopping[] = await store.getters.getShoppingList;
  if (s.length<1){
    buttonReturn();
  }
});
onMounted(() => {
  useScrollToTop();
});
const buttonReturn = () => {
  redirectMenu(router, serviceZone, query);
};
watch(localListened, (newValue) => {
  isOpen.value = newValue?.isOpen;
  dayOrdersTransactionsId.value = newValue?.dayOrdersTransactionsId;
});
const identifyOrderClientNotFound =() => {
  const inputElement = document.getElementById("idUtente");
  if (inputElement) {
    inputElement.focus();
    visualizzaErrIdUtente.value = true;
  }
};
const processPayment = async (pay: Pay) => {
  //arriva qui solo se serviceZone!=onsit
  const c: Cart = useCreateCart(store, query, pay);
  let newOrder: Order = useCreateOrder(store, query, identificativoOrdineCliente.value, c);
  if (PayEvent.SUCCESS === pay.payEvent || PayEvent.NOBODY === pay.payEvent) {
    const documentId = await useInsertDocument(DbDocumentName.ORDER, newOrder);
    newOrder.id = documentId;
    if (documentId !== DEFAULT_VALUE) {
      await useUpdateDocument(
        DbDocumentName.ORDER, 
        documentId, 
        {
          'document.id': documentId,
          'document.orderStatus': OrderStatus.PENDING,
        }
      );
      newOrder.orderStatus = OrderStatus.PENDING;
    }
    if (ProviderPay.SATISPAY === pay.providerPay && PayEvent.NOBODY === pay.payEvent) {
      const windowCurrentUrl = window.location.href;
      const { orderSatis, paySatis } = await useSatisPayCreatePayment (store, query, windowCurrentUrl, newOrder);
      newOrder = orderSatis;
      pay = paySatis;
    }  
  }
  const params = {
    orderId: newOrder.id,
  };
  useUpdateCookie(store, params);
  store.commit("setOrder", newOrder);
  if (ProviderPay.SATISPAY === pay.providerPay && PayEvent.SUCCESS === pay.payEvent) {
    window.location.href = pay.redirectUrl2FA;
  } else {
    redirectThankyou(router, serviceZone, table);
  }
};
</script>

<template>
  <NavbarComponent />
  <div class="qt-container pb-2">
    <div class="columns is-mobile">
      <div class="column has-text-left p-0">
        <button class="button is-ghost p-0" @click="buttonReturn()">
          <img src="@/assets/images/arrow-left-orange.png" alt="cartImg" style="height: auto; max-width: 70%;"/>
          <h1 class="qt-text-title-orange ml-2">{{ $t('summary_cart') }}</h1>
        </button>
      </div>
    </div>
  </div>

  <div class="qt-container pb-6">
    <div class="columns is-mobile qt-card mb-6">
      <div class="column p-0">
        <p class="has-text-left">
          <span class="qt-text-card-title-black">{{ $t('review_cart_and') }}</span>
        </p>

        <div class="columns is-desktop">
          <div class="column is-6-desktop is-12-mobile is-12-tablet mb-3 mt-5 p-0 has-text-left">
            <span class="qt-text-h4">{{ $t('insert_name') }}</span>
            <input
              class="input is-fullwidth mt-2"
              id="idUtente"
              v-model="identificativoOrdineCliente"
              maxlength="25"
              placeholder="Riferimento per l'ordine..."
              style="width: 250px; height: 50px;"
              :style="{ borderColor: (visualizzaErrIdUtente && identificativoOrdineCliente==='') ? 'red' : '' }"
              required
            />
            <span v-if="visualizzaErrIdUtente && identificativoOrdineCliente===''" class="qt-error-txt">{{ $t('mandatory_fild') }}</span>
          </div>
          <hr>          
          <div class="column is-6-desktop is-12-mobile is-12-tablet mb-3 mt-3 p-0 has-text-center">
            <span class="qt-text-card-title-black">{{ $t('tot_pay') }}</span>
            <br>
            <span class="qt-text-card-title-black">{{totalPay.toFixed(2)}} {{CURRENCY}}</span>
          </div>
          <hr class="mb-0">
        </div>
        <div class="is-fullheight t1 mt-5 mb-5">
          <CartSummaryComponent />
        </div>
      </div>
    </div>

    <div class="columns is mobile qt-card">
      <div class="column p-0">
        <p>
          <span class="qt-text-card-title-black">{{ $t('chose_payment') }}</span>
        </p>
        <div class="columns is-desktop">
          <hr>
          <div class="column mb-3 mt-3 p-0 has-text-center">
            <span class="qt-text-title-orange">{{ $t('pay') }} {{totalPay.toFixed(2)}} {{CURRENCY}}</span>
          </div>
          <hr class="mb-0">
          <div v-if="!isOpen" class="column mb-3 mt-3 p-0 ">
            <span class="qt-error-txt"><b>{{ $t('local_close') }}</b><br><b>{{ $t('imposssible') }}</b></span>
            <hr>
          </div>
          <div v-else-if="isOpen && totalPay > 0" class="column mb-3 mt-3 p-0 ">
            <div class="control is-inline-flex">
              <div v-if="payLater || ENVIROMENT==='TEST' " >
                <label class="radio qt-text-h4-big mr-5">
                  <input type="radio" name="radioButtonSelector" v-model="selectedRadio" :value="'CASSA'" checked>
                  {{ $t('pay_cash') }}
                </label>
              </div>
              <div v-if="ENABLE_GOOGLEPAY || ENABLE_SATISPAY || ENVIROMENT==='TEST' " >
                <label class="radio qt-text-h4-big">
                  <input type="radio" name="radioButtonSelector" v-model="selectedRadio" :value="'APP'">
                  {{ $t('pay_app') }} 
                </label>
              </div>
            </div>
            <div class="mt-6 has-text-left">
              <span class="qt-text-p-small is-uppercase"><b>{{ $t('take_vision') }}</b></span>
              <br/>
              <div v-if="(payLater || ENVIROMENT==='TEST') && ('CASSA' === selectedRadio)">
                <span class="qt-text-p-small">
                  {{ $t('method_local') }}
                </span>
              </div>
              <div v-if="(ENABLE_GOOGLEPAY || ENABLE_SATISPAY || ENVIROMENT==='TEST') && ('APP' === selectedRadio)" >
                <span class="qt-text-p-small">
                  {{ $t('method_provider') }}
                </span>
              </div>
            </div>
            <div class="mt-4">
              <div v-if="(payLater || ENVIROMENT==='TEST') && ('CASSA' === selectedRadio)" >
                <PayTableComponent
                  :totalPay="totalPay"
                  :identificativoOrdineCliente="identificativoOrdineCliente"
                  @identifyOrderClientNotFound="identifyOrderClientNotFound"
                  @processPayment="processPayment"
                />
              </div>
              <div v-if="(ENABLE_GOOGLEPAY || ENABLE_SATISPAY || ENVIROMENT==='TEST') && ('APP' === selectedRadio)" class="mb-4">
                <div v-if="ENABLE_GOOGLEPAY || ENVIROMENT==='TEST' " >
                  <PayGoogleComponent
                    :totalPay="totalPay"
                    :identificativoOrdineCliente="identificativoOrdineCliente"
                    @identifyOrderClientNotFound="identifyOrderClientNotFound"
                    @processPayment="processPayment"
                  />
                </div>
                <div v-if="ENABLE_SATISPAY || ENVIROMENT==='TEST' " >
                  <PaySatisComponent
                    :totalPay="totalPay"
                    :identificativoOrdineCliente="identificativoOrdineCliente"
                    @identifyOrderClientNotFound="identifyOrderClientNotFound"
                    @processPayment="processPayment"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <br/>
  <FooterComponent />
</template>

<style lang="css" scoped>
  .columns {
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
  }

  .columns:not(:last-child) {
    margin-bottom: 0;
  }

  .columns:last-child {
   margin-bottom: 0;
  }

  .qt-text-h3 {
    color:#000000;
    font-family: 'Roboto-Medium';
    opacity: 1;
    font-size: 17px;
  }

  .qt-text-h4-big {
    color: #000000;
    font-family: 'Roboto-Medium';
    opacity: 1;
    font-size: 17px;
  }

  input[type="radio"] {
    vertical-align: middle;
  }

  .qt-text-p-small {
    font-size: 13px;
  }

  @media only screen and (max-width: 425px) {
    .qt-text-h4-big,
    .qt-text-h3 {
      font-size: 15px;
    }

    .qt-text-title-orange {
      font-size: 20px;
    }

    .qt-text-p-small {
      color: #000000;
      font-family: 'Roboto';
      opacity: 1;
      font-size: 11px;
    }

  }
</style>