/* eslint-disable @typescript-eslint/no-explicit-any */
import { DEFAULT_VALUE } from "@/constants/globalConst";
import { ServiceZone } from "@/types";
import { LocationQuery, RouteLocationNormalizedLoaded, RouteParamsRaw, Router } from "vue-router";

export function addQueryParam(route: RouteLocationNormalizedLoaded, router: Router, queryParam: string, paramValue: any) {
    const newPath = route.path;
    const newQuery = { ...route.query };
    newQuery[queryParam] = paramValue;
    router.push({ path: newPath, query: newQuery });
}
export function redirectWithName(router: Router, name: string) {
    router.push({
        name: name,
    });
}
export function redirectWithNameParam(router: Router, name: string, serviceZone: string, idTable: string) {
    router.push({
        name: name,
        query: { 
            serviceZone: serviceZone,
            table: idTable,
        },
    });
}
export function redirectMenu(router: Router, serviceZone: string, query: LocationQuery) {
    const newQuery = { ...query };
    if( ServiceZone.takeaway === serviceZone || ServiceZone.standup === serviceZone ) {
        if ('table' in newQuery) {
            delete newQuery['table'];
          }
    } 
    router.push({
        name: "menu",
        query: newQuery,
    });
}
export function redirectMenuZero(router: Router, query: LocationQuery) {
    router.push({
        name: "menuZero",
        query: query,
    });
}
export function redirectCart(router: Router, serviceZone: string, idTable: string) {
    if (serviceZone === ServiceZone.onsit) {
        router.push({
            name: "cartDown",
            query: { 
                serviceZone: serviceZone,
                table: idTable,
            },
        });
    } else {
        router.push({
            name: "cartUp",
            query: { 
                serviceZone: serviceZone,
                ...(
                    (idTable !== undefined && idTable !== null && idTable !== DEFAULT_VALUE) 
                    && { table: idTable }
                ),
            },
        });
    }
}
export function redirectThankyou(router: Router, serviceZone: string, idTable: string) {
    router.push({
        name: "thankyou",
        query: { 
            serviceZone: serviceZone, 
            ...(
                (idTable !== undefined && idTable !== null && idTable !== DEFAULT_VALUE) 
                && { table: idTable }
            ),
        },
    });
}
export function redirectError(router: Router, errorId: number) {
    const params: RouteParamsRaw = { idErrore: errorId };
    router.push({
        name: 'error',
        params: params
    });
}
