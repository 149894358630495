<script setup lang="ts">
import { useRoute, useRouter } from "vue-router";
import { defineProps, defineEmits, computed, ComputedRef } from "vue";
import { CatalogItem } from "@/types";
import { addQueryParam } from "@/composables/useNavigation";
import { useStore } from "vuex";

const route = useRoute();
const router = useRouter();
const store = useStore();
const selectedLanguage: ComputedRef<string> = computed(() => {
  return store.getters.getLocale;
});
const props = defineProps({
  mapCatalogItem: {
    type: Object as () => Map<string, CatalogItem>,
    required: true,
  },
});
const catalogId = computed(() => {
  return route.query.catalog;
});
const mapValues = computed(() => {
  return Array.from(props.mapCatalogItem?.values()) ?? [];
});
const getButtonProps = (id: string) => {
  return {
    class: {
      'button': true,
      'qt-btn-selected': catalogId.value === id,
      'is-rounded': true
    }
  };
};
const emit = defineEmits<{
  (event: 'rechargeFiltered', id: string): void;
}>();
const rechargeFiltered = (id: string) => {
  emit('rechargeFiltered', id);
};
const recharge = (id: string) => {
  addQueryParam(route, router, 'catalog', id);
  rechargeFiltered(id);
};
</script>

<template>
  <div class="buttons scrolling-wrapper">
    <div v-for="c in mapValues" :key="c.id" class="qt-card-scroll">
      <button v-bind="getButtonProps(c.id)" @click="recharge(c.id)">
        <span class="icon is-small">
          <img :src="require(`@/assets/images/${c.logoPath}`)"/>
        </span>
        <span class="qt-text-h4 ml-1 mr-1">{{c.names[selectedLanguage].name}}</span>
      </button>
    </div>
  </div>
</template>


<style lang="css" scoped>
  .scrolling-wrapper {
    overflow-y: hidden;
    white-space: nowrap;
    display: flow;
  }
  .qt-card-scroll {
    margin-right: 0.5rem !important;
    display: inline-block;
    flex: 0 0 auto;
  }

  .scrolling-wrapper-flexbox {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  }
</style>
