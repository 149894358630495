<script setup lang="ts">
import { defineEmits, defineProps} from "vue";

const emit = defineEmits<{
  (event: 'processOpenCloseModal', isActiveModal:boolean): void;
}>();
const processOpenCloseModal = (isActiveModal:boolean) => {
  emit('processOpenCloseModal',isActiveModal);
};

const props = defineProps({
  isActiveModal: {
    type: Boolean,
    required: true,
  },
  title: {
    type: String,
    required: true
  },
  textContent: {
    type: String,
    required: true
  },
  onConfirm: {
    type: Function,
    required: false,
  },
  onCancel: {
    type: Function,
    required: false,
  },
  confirmText: {
    type: String,
    required: false
  },
  cancelText: {
    type: String,
    required: false
  }
});
</script>

<template>
  <div class="modal" :class="{ 'is-active': isActiveModal }">
    <div class="modal-background" @click="processOpenCloseModal(!props.isActiveModal)"></div>
    <div class="modal-content">
      <div class="container-med p-4">
        <img src="@/assets/images/noun-answer.png" />
        <p class="mt-4">
          <span class="qt-text-card-title-black">{{ props.title }}</span>
        </p>
        <p class="mt-2">
          <span class="qt-text-p">{{ props.textContent }}</span>
        </p>
        <div class="mt-6 mb-3">
          <div class="field" v-if="props.confirmText">
            <button class="button btn-orange is-pulled-right mr-6" @click="props.onConfirm">
              <span class="btn-txt">{{ props.confirmText }}</span>
            </button>
          </div>
          <div class="field" v-if="props.cancelText">
            <button  class="button btn-white is-pulled-left ml-6" @click="props.onCancel ? props.onCancel : processOpenCloseModal(false)">
              <span class="btn-txt">{{ props.cancelText }}</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="css" scoped>

.container-med{
    display: flow;
    flex-direction: column;
    align-items: center;
    background: #FFFFFF;
    box-shadow: 0px 3px 6px #00000029;
    opacity: 1;
    margin-top: auto;
    margin-bottom: auto;
}

.modal-content, .modal-card {
  margin: 0 20px;
  max-height: calc(100vh - 160px);
  overflow: auto;
  position: relative;
  width: 90%;
  min-height: 84vh;
  border-radius: 10px;
}

.btn-white,
.btn-orange{
  width: 30%;
}

.btn-orange{
  height: 52px;
}
</style>
