<script setup lang="ts">
import { defineProps, computed, defineEmits } from "vue";
import { PAGINATE_PREV_BUTTON_NAME_IT, PAGINATE_NEXT_BUTTON_NAME_IT } from '../constants/globalConst';

const emit = defineEmits(['pageChange','update:initialPage'])

const props = defineProps({
    totalItemsLength: {
        type: Number,
        required: true
    },
    itemsPerPage: {
        type: Number,
        required: true
    },
    initialPage: {
        type: Number,
        required: false
    }
})

const currentPage = computed({
    get() {
        return props.initialPage ? props.initialPage : 1;
    },
    set(value) {
        emit('update:initialPage', value);
    }
})

const pagesShown = computed(() => {
  return Math.ceil(props.totalItemsLength / props.itemsPerPage);
})

function changePage(page: number){
    emit('pageChange', page);
}
</script>

<template>
<div class="example-two">
    <vue-awesome-paginate
        v-model="currentPage"
        :total-items="totalItemsLength"
        :items-per-page="itemsPerPage"
        :max-pages-shown="pagesShown"
        :on-click="changePage"
        :prev-button-content="PAGINATE_PREV_BUTTON_NAME_IT"
        :next-button-content="PAGINATE_NEXT_BUTTON_NAME_IT"
    />
</div>
</template>