/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { createStore } from 'vuex';
import { CartActionTypes, Order, OrderPendingSubstatus, PaymentStatus, ServiceZone, type Catalog, type Local, type ServiceZoneCatalog, type Shopping, } from "@/types";

export default createStore({
  state: {
    local: null as unknown as Local,
    shoppingList: [] as Shopping[],
    counterCart: 0,
    order: null as unknown as Order,
    orders: [] as Order[],
    shoppingFather: null as unknown as Shopping,
    locale: 'it',
    
    //gestione ordini esercente
    ordersInCharge: {
      [ServiceZone.onsit]: null,
      [ServiceZone.standup]: null,
      [ServiceZone.takeaway]: null
    } as Record<ServiceZone, Order | null>,

    //mappa delle funzioni che permetteno di terminare la sottoscrizione agli eventi di update sugli ordini presi in carico
    unsubscribeMap: {} as Partial<Record<ServiceZone, () => void>>,

    //stato bottone audio on/off (on = true, off = false)
    audio: false
  },
  getters: {
    getLocal: (state): Local | null => {
      const o: Local | null = state.local;
      return o;
    },
    getLocalId: (state): string => {
      const o: string | null = state.local?.id;
      return o;
    },
    getLocalDayOrdersTransactionsId: (state): string => {
      const o: string | null = state.local?.dayOrdersTransactionsId;
      return o;
    },
    getLocalIsOpen: (state): boolean => {
      const o: boolean = state.local?.isOpen ?? false;
      return o;
    },
    getLocalServiceZoneIsLaterPay: (state) => (serviceZone: string): boolean => {
      const szc: ServiceZoneCatalog[] = state.local?.serviceZoneCatalogs ?? [];
      const szcf: ServiceZoneCatalog | undefined = szc.find(i=> i.serviceZone === serviceZone);
      const o: boolean = szcf?.laterPay ?? false;
      return o;
    },
    getLocalServiceZoneCatalogList: (state): ServiceZoneCatalog[] => {
      const o: ServiceZoneCatalog[] = state.local?.serviceZoneCatalogs ?? [];
      return o;
    },
    getLocalCatalogByServiceZoneAndId: (state) => (serviceZone: string, catalogId: string): Catalog => {
      const szc: ServiceZoneCatalog[] = state.local?.serviceZoneCatalogs ?? [];
      const szcf: ServiceZoneCatalog | undefined = szc.find(i=> i.serviceZone === serviceZone);
      const c: Catalog[] = szcf?.catalogs ?? [];
      let o = c.find(i => i.catalogId === catalogId);
      if (o === undefined) {
        o = {
          "alcol": false,
          "catalogId": catalogId,
          "groupType": 0,
          "specialOffer": false,
					"specialOfferName": "PLUS",
					"specialOfferFee": 0,
					"specialOfferType": "PRICE",
        }
      }
      return o;
    },
    getShoppingList: (state): Shopping[] => {
      const o: Shopping[] = state.shoppingList;
      return o;
    },
    getQuantityOfShopping: (state)=> (serviceZone: string, catalogId: string, productInfoId: number, productInfoCompositionsId: number, productInfoCompositionsUnityPriceEffective: number): number => {
      const shoppingCart: Shopping | undefined = state.shoppingList.find(s => 
        s.serviceZone === serviceZone &&
        s.catalogId === catalogId &&
        s.productInfoId === productInfoId &&
        s.productInfoCompositionsId === productInfoCompositionsId &&
        s.productInfoCompositionsUnityPriceEffective === productInfoCompositionsUnityPriceEffective
      );
      if (shoppingCart) {
        return shoppingCart.productInfoCompositionsQuantity;
      } else {
        return 0;
      }
    },
    getShoppingSoon: (state)=> (shopping: Shopping): Shopping | undefined => {
      const shoppingCart: Shopping | undefined = state.shoppingList.find(s => 
        s.father.serviceZone === shopping.serviceZone &&
        s.father.catalogId === shopping.catalogId &&
        s.father.productInfoId === shopping.productInfoId &&
        s.father.productInfoCompositionsId === shopping.productInfoCompositionsId &&
        s.father.productInfoCompositionsUnityPriceEffective === shopping.productInfoCompositionsUnityPriceEffective
      );
      return shoppingCart;
    },
    getCounterCart: (state): number => {
      const o: number = state.counterCart;
      return o;
    },
    getTotalPay:(state): number => {
      const l: Shopping[] = state.shoppingList;
      let o = 0;
      l.forEach(element => {
        o += (element.productInfoCompositionsUnityPriceEffective * element.productInfoCompositionsQuantity);
      });
      return o;
    },
    getOrder: (state): Order => {
      const o: Order = state.order ?? null;
      return o;
    },
    getOrders: (state): Order[] => {
      const o: Order[] = state.orders ?? null;
      return o;
    },
    getShoppingFather: (state): Shopping => {
      const o: Shopping = state.shoppingFather ?? null;
      return o;
    },
    getOrderInChargeByServiceZone: (state) => (serviceZone: ServiceZone): Order | null => {
      return state.ordersInCharge[serviceZone];
    },
    getLocale: (state): string => {
      const o: string = state.locale;
      return o;
    },
  },
  mutations: {
    setLocal(state, val: Local) {
      state.local = val;
    },
    setShoppingList(state, val: Shopping[]) {
      state.shoppingList = val;
    },
    setOrder(state, val: Order) {
      state.order = val;
    },
    setOrders(state, val: Order[]) {
      state.orders = val;
    },
    setShoppingFather(state, val: Shopping) {
      state.shoppingFather = val;
    },
    managementCart(state, { shopping, cartActionType }) {
      const max = 9;
      const min = 1;
      const cat: CartActionTypes = cartActionType;
      if (CartActionTypes.RESET === cat) {
        state.shoppingList = [] as Shopping[];
        state.counterCart = 0;
        state.order = null as unknown as Order;
      } else {
        const sin: Shopping = shopping;
        if (sin.productInfoCompositionsQuantity > max) {
          sin.productInfoCompositionsQuantity = max;
        }
        if (sin.productInfoCompositionsQuantity < min) {
          sin.productInfoCompositionsQuantity = min;
        }
        const shoppingCartIndex = state.shoppingList.findIndex(s => 
          s.serviceZone === sin.serviceZone &&
          s.catalogId === sin.catalogId &&
          s.productInfoId === sin.productInfoId &&
          s.productInfoCompositionsId === sin.productInfoCompositionsId &&
          s.productInfoCompositionsUnityPriceEffective === sin.productInfoCompositionsUnityPriceEffective &&
          s.father.productInfoId === sin.father.productInfoId
        );
        switch (cat) {
          case CartActionTypes.ADD:
            if (shoppingCartIndex < 0) {
              state.shoppingList.push(sin);
              state.counterCart += sin.productInfoCompositionsQuantity;
            } else {
              const shoppingCart = state.shoppingList[shoppingCartIndex];
              const quantityAvailable  = max - shoppingCart.productInfoCompositionsQuantity;
              if (sin.productInfoCompositionsQuantity > quantityAvailable) {
                shoppingCart.productInfoCompositionsQuantity += quantityAvailable;
                state.counterCart += quantityAvailable;
              } else{
                shoppingCart.productInfoCompositionsQuantity += sin.productInfoCompositionsQuantity;
                state.counterCart += sin.productInfoCompositionsQuantity;
              }
              state.shoppingList[shoppingCartIndex] = shoppingCart;
            }
            break;
          case CartActionTypes.SUBTRACT:
            if (shoppingCartIndex >= 0) {
              const shoppingCart = state.shoppingList[shoppingCartIndex];
              const quantityAvailable  = shoppingCart.productInfoCompositionsQuantity;
              if (sin.productInfoCompositionsQuantity < quantityAvailable) {
                shoppingCart.productInfoCompositionsQuantity -= sin.productInfoCompositionsQuantity;
                state.counterCart -= sin.productInfoCompositionsQuantity;
                state.shoppingList[shoppingCartIndex] = shoppingCart;
              } else{
                state.counterCart -= quantityAvailable;
                state.shoppingList.splice(shoppingCartIndex, 1);
              }
            }
            break;
        }
      }
    },
    setOrderInChargeByServiceZone(state, payload: { order: Order, serviceZone: ServiceZone }) {
      const { order, serviceZone } = payload;
      state.ordersInCharge[serviceZone] = order;      
    },
    toggleOrderSubstatus(state, payload: { orderId: string, serviceZone: ServiceZone, orderPendingSubstatus: OrderPendingSubstatus }) {
      const { orderId, serviceZone, orderPendingSubstatus } = payload;
      const savedOrder = state.ordersInCharge[serviceZone];
      if(orderId === savedOrder?.id){
        switch (orderPendingSubstatus) {
          case OrderPendingSubstatus.PREPARED_BAR:
            savedOrder.isPreparedBar = true;
            break;
          case OrderPendingSubstatus.PREPARED_KITCHEN:
            savedOrder.isPreparedKitchen = true;
            break;
          case OrderPendingSubstatus.INVOICED:
            savedOrder.isInvoiced = true;
            savedOrder.carts[0].pay.paymentStatus = PaymentStatus.COMPLETE;
            break;
        }
      }     
    },
    deleteOrderInCharge(state, serviceZone: ServiceZone) {
      if (serviceZone in state.ordersInCharge) {
        state.ordersInCharge = {
          ...state.ordersInCharge,
          [serviceZone]: null
        };
      } else {
        console.warn(`ServiceZone ${serviceZone} non esiste in ordersInCharge`);
      }
    },
    setLocale(state, locale) {
      state.locale = locale;
    },
    /** 
     * Annulla l'iscrizione ai cambiamenti di stato dell'ordine preso in carico precedente per la serviceZone corrente, 
     * se esiste e si sottoscrive agli aggiornamenti del nuovo ordine preso in carico (tramite la nuova funzione)
    **/
    changeOrderInChargeSubscription(state, payload: {serviceZone: ServiceZone, unsubscribe: () => void}){
      const serviceZone = payload.serviceZone;
      const unsubscribe = payload.unsubscribe;
      if(state.unsubscribeMap[serviceZone]){
        state.unsubscribeMap[serviceZone]!();
      }
      state.unsubscribeMap[serviceZone] = unsubscribe;
    },

    /**
     * Annulla l'iscrizione ai listener sugli ordini in carico quando il componente viene smontato 
     * (da chiamare nel component App)
    **/
    unsubscribeOrdersInCharge(state){
      Object.keys(state.unsubscribeMap).forEach((zone) => {
        if (state.unsubscribeMap[zone as ServiceZone]) {
          state.unsubscribeMap[zone as ServiceZone]!();
        }
      });
    },
    
    toogleAudio(state){
      state.audio = !state.audio
    }
  }
})
