export const ENVIROMENT = 'PRODUCTION';
export const BUTTON_COLOR = 'black';
export const BUTTON_TYPE = 'buy';
export const BUTTON_LOCAL = 'it';
export const BUTTON_SIZE_MODE = 'fill';
export const TOTAL_PRICE_STATUS = 'FINAL';
export const TOTAL_PRICE_LABEL = 'Total';
export const ALLOWED_AUTH_METODS = ["PAN_ONLY", "CRYPTOGRAM_3DS"];
export const ALLOWED_CARD_NET_WORKS = ['AMEX', 'DISCOVER', 'INTERAC', 'JCB', 'MASTERCARD', 'VISA'];
export const ALLOW_PREPAID_CARDS = true;
export const ALLOW_CREDIT_CARDS = true;
export const TYPE = 'PAYMENT_GATEWAY';
export const MERCHANT_NAME = 'Quicktail di Gabbiani A. e Sottini D S.a.S';
export const MERCHANT_ID = 'BCR2DN4TWWMKZ7K3';
export const GATEWAY = 'example';
export const GATEWAY_MERCHANT_ID = 'exampleGatewayMerchantId';