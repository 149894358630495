/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Order, OrderStatus, PaymentStatus, Shopping } from "@/types";

export function useCloneShopping(item: Shopping| undefined | null) {
  if (!item) {
   return null;
  }
  const jsonString = JSON.stringify(item);
  const shopping: Shopping = JSON.parse(jsonString);
  shopping.productInfoCompositionsQuantity = item.productInfoCompositionsQuantity;
  return shopping;
}
export function useCloneShoppingSingleQuantity(item: Shopping| undefined | null) {
  if (!item) {
    return null;
  }
  const shopping: Shopping = useCloneShopping(item)!;
  shopping.productInfoCompositionsQuantity = 1;
  return shopping;
}
export function useOrderSuccess (order: Order) {
  if (OrderStatus.PENDING === order?.orderStatus) {
    return true;
  } else{
    return false;
  }
}
export function useOrderPay (order: Order) {
  if (order?.carts.length > 0) {
     return order.carts.some(ec => {
      return !(PaymentStatus.COMPLETE !== ec.pay.paymentStatus);
    });
  }
  return false;
}