<!-- eslint-disable @typescript-eslint/no-non-null-assertion -->
<script setup lang="ts">
import { onMounted, onBeforeMount, ref, computed, Ref, ComputedRef, watch } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import FooterComponent from '@/components/FooterComponent.vue'
import NavbarComponent from "@/components/NavbarComponent.vue";
import CartSummaryComponent from "@/components/CartSummaryComponent.vue";
import { useConcatenatedString, useGetCookieParam, useScrollToTop } from "@/composables/useUtils";
import { CURRENCY } from "@/constants/globalConst"
import { CartActionTypes, OrderStatus, Shopping, } from "@/types";
import { useGetOrderDocumentByIdRealtime } from "@/firebase/firebaseUtilities";
import { redirectWithNameParam } from "@/composables/useNavigation";
import { useCloneShopping } from "@/composables/useUtilsCart";
import { useCaricaLocal, useStoredLocal } from "@/composables/useInit";
import QrImageComponent from "@/components/QrImageComponent.vue";

const store = useStore();
const router = useRouter();
const route = useRoute();
const isLoading = ref<boolean>(true);
const idLocale = route.params.idLocale;
const serviceZone = useConcatenatedString(route.query.serviceZone);
const table = useConcatenatedString(route.query.table);
const documentId = useGetCookieParam('orderId');
const order = useGetOrderDocumentByIdRealtime(documentId);
const totalPay = computed(() => { return store.getters.getTotalPay; });

watch(order, (updatedOrder, _) => {
  store.commit("setOrder", updatedOrder);
  store.commit("setShoppingList", [] as Shopping[]);
    const promises = updatedOrder!.carts.flatMap(element => {
      return element.shoppings.map(item => {
        const shopping: Shopping = useCloneShopping(item)!;
        return store.commit("managementCart", { shopping: shopping, cartActionType: CartActionTypes.ADD });
      });
    });
    Promise.all(promises)
      .catch((e) => console.error("Errore aggiornamento carrello: ", e));
});

onMounted(() => {
  isLoading.value = false;
  useScrollToTop();
});

onBeforeMount(async () => {
  if (!store.getters.getLocalId) {
    const l = await useCaricaLocal(useConcatenatedString(idLocale));
    useStoredLocal(store, l);
  }

  if (documentId != undefined) {
    if (order.value && (order.value.orderStatus === OrderStatus.PENDING 
                        || order.value.orderStatus === OrderStatus.COMPLETE 
                        || order.value.orderStatus === OrderStatus.DELETED)) {
      redirectWithNameParam(router, "home", serviceZone, table);
    }
  }
});

const processConfirm = () => {
  redirectWithNameParam(router, "cartPayConfirm", serviceZone, table);
}
</script>

<template>
  <div v-if="!isLoading">
    <NavbarComponent />
    <div class="qt-container pb-2">
      <div class="columns is mobile">
        <div class="column has-text-left p-0">
          <h1 class="qt-text-title-orange ml-2">{{ $t('general_cart') }}</h1>
        </div>
      </div>
    </div>
    <div class="qt-container pb-6">
      <div class="columns is mobile qt-card">
        <div class="column p-0">
          <p class="has-text-left">
            <span class="qt-text-card-title-black">{{ $t('review_general_cart') }}</span>
          </p>
          <div class="">
            <div class="columns is desktop">
              <div class="column is-12-mobile is-5-tablet is-3-desktop mb-3 mt-3 pl-0">
                <span>
                  <span class="qt-text-h4 is-pulled-left">{{ $t('order_id') }}</span>
                  <span class="qt-text-h4 is-pulled-right">{{order?.identifyOrderApp}}</span>
                </span>
              </div>
              <div class="column is-12-mobile is-2-tablet is-6-desktop m-0 p-0"></div>
              <div class="column is-12-mobile is-5-tablet is-3-desktop mb-3 mt-3 pl-0">
                <span>
                  <span class="qt-text-h3 is-pulled-left">{{ $t('tot_order') }}</span>
                  <span class="qt-text-h3 is-pulled-right">{{totalPay.toFixed(2)}} {{CURRENCY}}</span>
                </span>
              </div>
            </div>
            <hr class="mb-0">
          </div>
          <div class="is-fullheight t1 mt-5 mb-5">
            <CartSummaryComponent :enableItemsDelete="false" />
          </div>
          <div>
            <button v-if="totalPay>0" class="button btn-orange mt-4 mb-4" @click="processConfirm" >
              <span class="btn-txt">{{ $t('confirm_order') }}</span>
            </button>
            <div v-else class="columns is desktop">
              <div class="column is-12-mobile mt-4 mb-4 has-text-center">
                <span class="qt-error-txt mb-2"> <b>{{ $t('not_possible_eliminate') }}</b></span>
                  <br><br>
                  <span class="qt-text-p">{{ $t('abandon') }}</span> 
                  <br> 
                  <span class="qt-text-p">{{ $t('our_staff') }}</span>
                  <br><br>
                  <span class="qt-text-p"><b>{{ $t('or') }}</b></span>
              </div>
              <QrImageComponent style="display: contents;"/>          
            </div>
        </div>
        </div>
      </div>
    </div>
    <FooterComponent />
  </div>
</template>

<style lang="css" scoped>
  .columns {
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
  }

  .columns:not(:last-child) {
    margin-bottom: 0;
  }

  .columns:last-child {
   margin-bottom: 0;
  }

  .qt-text-h3 {
    color:#000000;
    font-family: 'Roboto-Medium';
    opacity: 1;
    font-size: 17px;
  }

  @media only screen and (max-width: 425px) {
    .qt-text-h3 {
      font-size: 15px;
    }

    .qt-text-title-orange {
      font-size: 20px;
    }
  }

</style>
