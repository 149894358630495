<script setup lang="ts">
import { onBeforeMount, onMounted, ref } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { redirectThankyou } from "@/composables/useNavigation";
import { Order } from "@/types";
import { useGetOrderDocumentById } from "@/firebase/firebaseUtilities";
import { useConcatenatedString } from "@/composables/useUtils";
import { useCaricaLocal, useStoredLocal } from "@/composables/useInit";
import { SATISPAY_RESPONSE_ID } from "@/constants/globalConst";
import {useSatisPayDetailsPayment} from "@/composables/useSatisPay"

const store = useStore();
const router = useRouter();
const route = useRoute();
const idLocale = useConcatenatedString(route.params.idLocale);
const serviceZone = useConcatenatedString(route.query.serviceZone);
const table = useConcatenatedString(route.query.table);
const satisPayId = useConcatenatedString(route.query[`${SATISPAY_RESPONSE_ID}`]);
const dId = useConcatenatedString(route.params.idDocument);
const order = ref<Order | null>();

onBeforeMount(async () => {
  const local = await useCaricaLocal(idLocale);
  useStoredLocal(store, local);
});
onMounted(async()=> {
  order.value = await useGetOrderDocumentById(dId);
  if (order.value) {
    store.commit("setOrder", order.value);
    await useSatisPayDetailsPayment(order.value, satisPayId);
    order.value = await useGetOrderDocumentById(dId);
    store.commit("setOrder", order.value);
  }
  redirectThankyou(router, serviceZone, table);
});
</script>

<template>
  <div class="text-container mt-6">
    <span class="title2">{{ $t('redirect') }}</span>
  </div>
</template>
