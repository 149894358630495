<!-- eslint-disable @typescript-eslint/no-non-null-assertion -->
<script setup lang="ts">
import { onBeforeMount, onMounted, ref } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import FooterComponent from '@/components/FooterComponent.vue'
import NavbarComponent from "@/components/NavbarComponent.vue";
import { useConcatenatedString, useGetCookieParam, useScrollToTop, useUpdateCookie } from "@/composables/useUtils";
import { redirectMenu } from "@/composables/useNavigation";
import { useGetOrderDocumentById } from "@/firebase/firebaseUtilities";
import { DEFAULT_VALUE } from "@/constants/globalConst";

const router = useRouter();
const store = useStore();
const route = useRoute();
const serviceZone = useConcatenatedString(route.query.serviceZone);
const identifyOrderClient = ref(DEFAULT_VALUE);
const identifyOrderApp = ref(DEFAULT_VALUE);
const visualizzaErr = ref(false);
const visualizzaWar = ref(false);
const isMaster = ref(false);
const identifyOrderSlave = ref(DEFAULT_VALUE);

onMounted(() => {
  useScrollToTop();
});
onBeforeMount(async () => {
  isMaster.value = await useGetCookieParam("isMaster");
  const orderId = await useGetCookieParam('orderId');
  const order = await useGetOrderDocumentById(orderId);
  store.commit("setOrder", order);
  identifyOrderClient.value = order!.identifyOrderClient;
  identifyOrderApp.value = order!.identifyOrderApp;
  const params = {
    identifyOrderApp: identifyOrderApp.value,
  };
  useUpdateCookie(store, params);
});
const identifyOrderClientNotFound =(elementById: string) => {
  const inputElement = document.getElementById(elementById);
  if (inputElement) {
    inputElement.focus();
    visualizzaErr.value = true;
  }
};
function confirmMaster() {
  if (DEFAULT_VALUE === identifyOrderClient.value){
    identifyOrderClientNotFound("idUtente");
  } else {
    redirectMenu(router, serviceZone, route.query);
  }
}
function confirmSlave() {
  const id = identifyOrderSlave.value?.toUpperCase();
  if (identifyOrderApp.value != id) {
    visualizzaWar.value = true;
    identifyOrderClientNotFound("idOrderSlave");
  } else {
    redirectMenu(router, serviceZone, route.query);
  }
}
function updateVariables() {  
  const id = identifyOrderSlave.value?.toUpperCase();
  if (DEFAULT_VALUE === id){
    visualizzaErr.value = true;
  } else {
    visualizzaErr.value = false;
  }
  if (identifyOrderApp.value === id) {
    visualizzaWar.value = false;
  } else {
    visualizzaWar.value = true;
  }
}
</script>

<template>
  <NavbarComponent />
  <div class="body-container">
    <div class="qt-container">
      <h1 class="qt-text-title-orange">{{ $t('info') }}</h1>
    </div>
    <section class="section is-fullheight qt-container pt-4">
      <div class="t1 pl-5 pt-5 qt-card ">
        <div>
          <h1 v-if="isMaster" class="qt-text-card-title-black pb-5">{{ $t('you_are_master') }}</h1>
          <h1 v-else class="qt-text-card-title-black pb-5" >{{ $t('ask_order_id') }}</h1>
        </div>
        <div v-if="isMaster">
          <div class="pb-1">
            <span class="qt-text-h3">{{ $t('order_id') }}</span>
            <span class="qt-text-p">{{ $t('share_order_id') }}</span>
          </div>
          <h3 class="qt-text-title-orange"> {{ identifyOrderApp }}</h3>
        </div>
        <div v-else>
          <div class="mb-1">
            <b class="qt-text-p">{{ $t('insert_order_id') }}</b>
          </div>
          <input
            class="input is-fullwidth qt-input"
            id="idOrderSlave"
            v-model="identifyOrderSlave"
            maxlength="4"
            placeholder="ID ordine..."
            style="text-transform: uppercase;"
            :style="{ borderColor: (visualizzaErr || visualizzaWar) ? 'red' : DEFAULT_VALUE }"
            @input="updateVariables"
            required
          />
          <span v-if="visualizzaErr" class="qt-error-txt" >{{ $t('mandatory_fild') }}</span>
          <span v-if="visualizzaWar" class="qt-error-txt">{{ $t('error_field') }}</span>
        </div>
        <div v-if="isMaster">
          <button class="button btn-orange mt-6" @click="confirmMaster()">
            <span class="btn-txt">{{ $t('go_menu') }}</span>
          </button>
        </div>
        <div v-else>
          <button class="button btn-orange mt-6" @click="confirmSlave()">
            <span class="btn-txt">{{ $t('go_menu') }}</span>
          </button>
        </div>
      </div>
    </section>
  </div>
  <FooterComponent />
</template>
