<!-- eslint-disable @typescript-eslint/no-explicit-any -->
<script setup lang="ts">
import { onMounted, computed, ref, onBeforeMount } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import FooterComponent from '@/components/FooterComponent.vue'
import NavbarComponent from "@/components/NavbarComponent.vue"; 
import CartSummaryComponent from "@/components/CartSummaryComponent.vue";
import { redirectWithNameParam, redirectMenu, redirectThankyou } from "@/composables/useNavigation";
import { useConcatenatedString, useCreateCart, useCreateOrder, useCreatePay, useCreatePayRequest, useCreatePayResponse, useGetCookieParam, useScrollToTop, useUpdateCookie, useRemoveCookie } from "@/composables/useUtils";
import { CURRENCY, DEFAULT_VALUE, LOCAL_NAME } from "@/constants/globalConst"
import { Cart, DbDocumentName, Order, OrderStatus, Pay, PayEvent, PaymentStatus, ProviderPay, Shopping } from "@/types";
import { useAddCartIntoOrderById, useUpdateDocument, useGetOrderDocumentById } from "@/firebase/firebaseUtilities";
import { v4 as uuidv4 } from "uuid";
import GeneralModalComponent from "@/components/GeneralModalComponent.vue";

const store = useStore();
const router = useRouter();
const route = useRoute();
const query = route.query;
const idLocale = useConcatenatedString(route.params.idLocale);
const serviceZone = useConcatenatedString(query.serviceZone);
const table = useConcatenatedString(query.table);
const order: Order = store.getters.getOrder;
const identifyOrderApp = order?.identifyOrderApp;
const totalPay = computed(() => { return store.getters.getTotalPay; });
const isMaster = ref(useGetCookieParam('isMaster') ?? false);
const showErrorModal = ref(false); 
const isConfirmationModalActive = ref(false);

onBeforeMount(async () => {
 const s:Shopping[] = await store.getters.getShoppingList;
 if (s.length<1){
  buttonReturn();
 }
});
onMounted(() => {
  useScrollToTop();
});
const buttonReturn = () => {
  redirectMenu(router, serviceZone, route.query);
};
const confirmCart = async () => {
  const orderId = await useGetCookieParam('orderId');
  isMaster.value = await useGetCookieParam('isMaster');
  const req = useCreatePayRequest(store.getters.getTotalPay);
  const res = useCreatePayResponse(DEFAULT_VALUE, DEFAULT_VALUE, DEFAULT_VALUE);
  const id = uuidv4();
  const pay: Pay = useCreatePay(
    id, idLocale, LOCAL_NAME, 
    ProviderPay.AWAITING, PayEvent.NOBODY, PaymentStatus.PENDING,
    id, DEFAULT_VALUE, req, res);
  const c: Cart = useCreateCart(store, query, pay);

  //Inserisco il carrello nell'ordine solo se il suo stato è OPEN o MASTER_READY
  const currentOrder: Order | null = await useGetOrderDocumentById(orderId);
  if(currentOrder && (currentOrder.orderStatus === OrderStatus.OPEN || currentOrder.orderStatus === OrderStatus.MASTER_READY)){ 
    useAddCartIntoOrderById (orderId, c);
    if (isMaster.value){
      await useUpdateDocument(
        DbDocumentName.ORDER, 
        orderId, 
        {
          'document.orderStatus': OrderStatus.MASTER_READY,
        }
      );
      const params = {
        isMasterReady: true,
      };
      useUpdateCookie(store, params);
      redirectWithNameParam(router, "cartGeneral", serviceZone, table);
    } else {
      const identifyOrderApp = await useGetCookieParam('identifyOrderApp');
      let newOrder: Order = useCreateOrder(store, query, DEFAULT_VALUE, c);
      if (identifyOrderApp) {
        newOrder.identifyOrderApp = identifyOrderApp;
      }
      store.commit("setOrder", newOrder);
      redirectThankyou(router, serviceZone, table);
    }
  } else {
    isConfirmationModalActive.value = false;
    showErrorModal.value = true;
  }
}

const redirectToHome = () => {
  useRemoveCookie();
  showErrorModal.value = !showErrorModal.value;
  redirectWithNameParam(router, "home", serviceZone, table);
}

const showConfirmationModal = () => {
  isConfirmationModalActive.value = true;
}
</script>

<template>
    <NavbarComponent />
    <div class="qt-container pb-2">
      <div class="columns is mobile">
        <div class="column has-text-left p-0">
          <button class="button is-ghost p-0" @click="buttonReturn()">
            <img src="@/assets/images/arrow-left-orange.png" alt="cartImg" style="height: auto; max-width: 70%;"/>
            <h1 class="qt-text-title-orange ml-2">{{ $t('summary_cart') }}</h1>
          </button>
        </div>
      </div>
    </div>
    <div class="qt-container pb-6">
      <div class="columns is mobile qt-card">
        <div class="column p-0">
          <p class="has-text-left">
            <span class="qt-text-card-title-black">{{ $t('review_cart') }}</span> 
            <span v-if="isMaster" class="qt-text-card-title-black" >{{ $t('confirm') }}</span>
            <span v-else class="qt-text-card-title-black" >{{ $t('send_to_master') }}</span>
          </p>
          <div class="">
            <div class="columns is desktop">
              <div class="column is-12-mobile is-5-tablet is-3-desktop mb-3 mt-3 pl-0">
                <span>
                  <span class="qt-text-h4 is-pulled-left">{{ $t('order_id') }}</span>
                  <span class="qt-text-h4 is-pulled-right">{{identifyOrderApp}}</span>
                </span>
              </div>
              <div class="column is-12-mobile is-2-tablet is-6-desktop m-0 p-0"></div>
              <div class="column is-12-mobile is-5-tablet is-3-desktop mb-3 mt-3 pl-0">
                <span>
                  <span class="qt-text-h3 is-pulled-left">{{ $t('cart_tot') }}</span>
                  <span class="qt-text-h3 is-pulled-right">{{totalPay.toFixed(2)}} {{CURRENCY}}</span>
                </span>
              </div>
            </div>
            <hr class="mb-0">
          </div>
          <div class="is-fullheight t1 mt-5 mb-5">
            <CartSummaryComponent />
          </div>
          <div>
            <button v-if="totalPay>0" class="button btn-orange mt-4 mb-4" @click="showConfirmationModal()">
              <span class="btn-txt">{{ $t('cart_confirm') }}</span>
            </button>
            <button v-else class="button btn-orange mt-4 mb-4" @click="buttonReturn()">
              <span class="btn-txt">{{ $t('back_menu') }}</span>
            </button>
          </div>
          
        </div>
      </div>
    </div>
    <div class="modal" :class="{ 'is-active': showErrorModal }">
      <div class="modal-background" @click="showErrorModal = !showErrorModal"></div>
      <div class="modal-content">
        <div class="container-med p-4">
          <img src="@/assets/images/noun-answer.png" />
          <p class="mt-4">
            <span class="qt-text-card-title-black qt-uppercase-text">{{ $t('attention') }}</span>
          </p>
          <p class="mt-2 mb-5">
            <span class="qt-text-p">{{ $t('error_order_delivered') }}</span>
          </p>
          <div>
            <button class="button btn-orange mb-3" @click="redirectToHome">
              <span class="btn-txt qt-uppercase-text">{{ $t('go_home') }}</span>
            </button>
          </div>
        </div>
    </div>
  </div>
  <GeneralModalComponent
    :is-active-modal="isConfirmationModalActive"
    :cancel-text="$t('no')"
    :confirm-text="$t('yes')"
    :text-content="$t('cart_confirmation_warning_message')"
    :title="$t('cart_confirmation_title')"
    :on-confirm="confirmCart"
    @process-open-close-modal="isConfirmationModalActive = !isConfirmationModalActive"
  />
  <FooterComponent />
</template>
<style lang="css" scoped>
  .columns {
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
  }

  .columns:not(:last-child) {
    margin-bottom: 0;
  }

  .columns:last-child {
   margin-bottom: 0;
  }

  .qt-text-h3 {
    color:#000000;
    font-family: 'Roboto-Medium';
    opacity: 1;
    font-size: 17px;
  }

  .container-med{
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #FFFFFF;
    box-shadow: 0px 3px 6px #00000029;
    opacity: 1;
    margin-top: auto;
    margin-bottom: auto;
    min-height: 0;
}

.modal-content, .modal-card {
  margin: 0 20px;
  max-height: calc(100vh - 160px);
  overflow: auto;
  position: relative;
  width: 90%;
  border-radius: 10px;
}

  @media only screen and (max-width: 425px) {
    .qt-text-h3 {
      font-size: 15px;
    }

    .qt-text-title-orange {
      font-size: 20px;
    }
  }

</style>