import { createRouter, createWebHistory, RouteRecordName, RouteRecordRaw } from 'vue-router'
import { getLoggedUser } from "@/firebase/firebaseUtilities";
import _ErrorView from '../views/_ErrorView.vue'
import HomeView from '../views/HomeView.vue'
import MenuView from '../views/MenuView.vue'
import MenuZeroView from '../views/MenuZeroView.vue'
import CartUpView from '../views/CartUpView.vue'
import CartDownView from '../views/CartDownView.vue'
import CartSatisPayVerifyView from '../views/CartSatisPayVerifyView.vue'
import ThankyouView from '../views/ThankyouView.vue'
import LayFormView from '../views/LayFormView.vue'
import AwaitTableView from '../views/AwaitTableView.vue'
import CartGeneralView from '../views/CartGeneralView.vue'
import CartPayConfirmView from '../views/CartPayConfirmView.vue'
import LoginView from '../views/LoginView.vue'
import LocalView from '../views/LocalView.vue'
import OrdersView from '../views/OrdersView.vue'
import QrCodeView from '../views/QrCodeView.vue'
import SearchView from '@/views/SearchView.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/:idLocale/home',
    name: 'home',
    component: HomeView
  },
  {
    path: '/:idLocale/menu',
    name: 'menu',
    component: MenuView
  },
  {
    path: '/:idLocale/menuZero',
    name: 'menuZero',
    component: MenuZeroView
  },
  {
    path: '/:idLocale/cartUp',
    name: 'cartUp',
    component: CartUpView
  },
  {
    path: '/:idLocale/cartDown',
    name: 'cartDown',
    component: CartDownView
  },
  {
    path: '/:idLocale/cart/satispayVerifyPayment/:idDocument',
    name: 'cartSatisPayVerify',
    component: CartSatisPayVerifyView
  },
  {
    path: '/:idLocale/thankyou',
    name: 'thankyou',
    component: ThankyouView
  },
  {
    path: '/:idLocale/layForm',
    name: 'layForm',
    component: LayFormView
  },
  {
    path: '/:idLocale/awaitTable',
    name: 'awaitTable',
    component: AwaitTableView
  },
  {
    path: '/:idLocale/cartGeneral',
    name: 'cartGeneral',
    component: CartGeneralView
  },
  {
    path: '/:idLocale/cartPayConfirm',
    name: 'cartPayConfirm',
    component: CartPayConfirmView
  },
  {
    path: '/:idLocale/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/:idLocale/private/local',
    name: 'local',
    component: LocalView,
    meta: { requiresAuth: true }
  },
  {
    path: '/:idLocale/private/orders',
    name: 'orders',
    component: OrdersView,
    meta: { requiresAuth: true }
  },
  {
    path: '/:idLocale/private/qrcode',
    name: 'qrCode',
    component: QrCodeView,
    meta: { requiresAuth: true }
  },
  {
    path: '/:idLocale/private/search',
    name: 'searchOrders',
    component: SearchView,
    meta: { requiresAuth: true }
  },
  {
    path: '/:idLocale/error/:idErrore',
    name: 'error',
    component: _ErrorView
  },
  {
    path: '/:pathMatch(.*)',
    component: _ErrorView
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

const routeBackBlacklist: RouteRecordName[] = ["awaitTable", "cartGeneral", "thankyou", "layForm"];

router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const loggedUser = await getLoggedUser();
  if (requiresAuth && !loggedUser) {
    // Utente non autenticato, reindirizza alla pagina di login
    next({ name: 'login', params: { idLocale: to.params.idLocale, idName: to.params.idName } });
  }
  // Controlla se l'utente sta tornando indietro quando la route di partenza è una fra quelle contenute nella blacklist 
  else if (from.name && routeBackBlacklist.includes(from.name) && to.name){    
    const historyState = window.history.state;
    if (historyState && historyState.current && historyState.current === to.fullPath) {
      next(false);
    } else {
      next();
    }
  } else {
    // Prosegui con la navigazione
    next();
  }
});

export default router;