<script setup lang="ts">
import { onBeforeMount, ref } from "vue";
import { useInsertCookie, useIsValidCookie, useConcatenatedString, useScrollToTop, useCreateCart, useCreatePay, useCreatePayRequest, useCreatePayResponse, useCreateOrder, useRemoveCookie } from "@/composables/useUtils";
import { redirectWithNameParam, redirectMenu, redirectError } from "@/composables/useNavigation";
import { useRouter, useRoute } from "vue-router";
import { useCaricaLocal, useStoredLocal } from "@/composables/useInit";
import { useStore } from "vuex";
import FooterComponent from '@/components/FooterComponent.vue';
import NavbarComponent from "@/components/NavbarComponent.vue"; 
import { Cart, CartActionTypes, DbDocumentName, Order, OrderStatus, Pay, PayEvent, PaymentStatus, ProviderPay, ServiceZone } from "@/types";
import { useGetCookieParam } from "@/composables/useUtils";
import { useGetOrderDocumentByServiceZoneAndOrderStatusAndTable, useInsertDocument, useUpdateDocument } from "@/firebase/firebaseUtilities";
import { DEFAULT_VALUE, LOCAL_NAME } from "@/constants/globalConst";
import { v4 as uuidv4 } from "uuid";
import { generate } from "random-words";

const router = useRouter();
const store = useStore();
const route = useRoute();
const isLoading = ref<boolean>(true);
const idLocale = useConcatenatedString(route.params.idLocale);
const query = route.query;
const serviceZone = useConcatenatedString(route.query.serviceZone);
const table = useConcatenatedString(route.query.table);
const privacy = ref(true);
const privacyNext = ref(!privacy.value);
const ordersLength = ref(0);
const orderId = ref(DEFAULT_VALUE);
const isOpen = ref (store.getters.getLocalIsOpen);

onBeforeMount(async () => {
  router.push({ path: route.path});
  const local = await useCaricaLocal(idLocale);
  useStoredLocal(store, local);
  isOpen.value = local.isOpen;
  switch (serviceZone) {
    case ServiceZone.onsit: {
      store.commit("managementCart", { shopping: null, cartActionType: CartActionTypes.RESET });
      const isMasterReady:boolean = useGetCookieParam('isMasterReady');
      const orders: Order[] = await useGetOrderDocumentByServiceZoneAndOrderStatusAndTable(serviceZone, OrderStatus.OPEN, OrderStatus.MASTER_READY, table);
      ordersLength.value = orders.length;
      if (orders.length > 1) {
        redirectError(router, 429);
      } else if (orders.length === 1) {
        const order = orders[0];
        orderId.value = order.id;
        if (isMasterReady) {
          redirectWithNameParam(router, "cartGeneral", serviceZone, table);
        } else {
          if (useIsValidCookie()) {
            redirectWithNameParam(router, "layForm", serviceZone, table);
          }
        }
      } else {
        useRemoveCookie();
      }      
      break;
    }
    default:{
      if (useIsValidCookie()){
        redirectMenu(router, serviceZone, query);
      }
      break;
    }
  }
  isLoading.value = false;
  useScrollToTop();
});
async function insert() {
  let isMaster = false;
  let isMasterReady = false;
  if (serviceZone === ServiceZone.onsit) {
    const req = useCreatePayRequest(store.getters.getTotalPay);
    const res = useCreatePayResponse(DEFAULT_VALUE, DEFAULT_VALUE, DEFAULT_VALUE);
    const id = uuidv4();
    const pay: Pay = useCreatePay(
      id, idLocale, LOCAL_NAME, 
      ProviderPay.AWAITING, PayEvent.NOBODY, PaymentStatus.PENDING,
      id, DEFAULT_VALUE, req, res);
    const c: Cart = useCreateCart(store, query, pay);
    if (ordersLength.value === 0) {
      store.commit("managementCart", { shopping: null, cartActionType: CartActionTypes.RESET });
      isMaster = true;
      const identifyOrderClient = useConcatenatedString(generate({ exactly: 1, minLength: 4 }));
      const newOrder: Order = useCreateOrder(store, query, identifyOrderClient, c);
      orderId.value = await useInsertDocument(DbDocumentName.ORDER, newOrder);
      newOrder.id = orderId.value;
      await useUpdateDocument(
        DbDocumentName.ORDER, 
        orderId.value, 
        {
          'document.id': orderId.value,
        }
      );
      store.commit("setOrder", newOrder);
    } else {
      isMaster = await useGetCookieParam('isMaster');
      isMasterReady = await useGetCookieParam('isMasterReady');
      if (isMaster) {
        orderId.value = await useGetCookieParam('orderId');
      } 
    }
  }
  useInsertCookie(serviceZone, table, isMaster, isMasterReady, orderId.value);
  privacyNext.value = true;
  
  //Ridireziono l'utente sul menù
  if (serviceZone === ServiceZone.onsit) {
    redirectWithNameParam(router, "layForm", serviceZone, table);
  } else {
    redirectMenu(router, serviceZone, query);
  }
}
</script>

<template>
  <div v-if="!isLoading">
    <NavbarComponent />
    <div class="body-container">
      <div class="columns-is-mobile">
        <div class="column-is-12">
          <figure class="image">
            <div class="container-banner"></div>
          </figure>
        </div>
        <div class="column-is-12 qt-container">
          <figure class="image qt-icon pt-4 pb-4">
            <img src="@/assets/images/documentShield.png" alt="document" />
          </figure>
          <div v-if="isOpen">
            <div v-if="privacy">
              <div class="text-container">
                <span v-if="privacyNext" class="qt-text-title-orange has-text-centered">{{ $t('thanks_accepted') }}</span>
                <span v-else class="qt-text-title-orange has-text-centered">{{ $t('accept_terms') }}</span>
              </div>
      
              <div class="text-container m-4">
                <span v-if="privacyNext" class="qt-text-p has-text-centered">{{ $t('after_accepted') }} {{LOCAL_NAME}}.</span>
                <span v-else class="qt-text-p has-text-centered">{{ $t('cookie_message') }}</span>
              </div>
              <div class="qt-pb">
                <div v-if="!privacyNext">
                  <button class="button btn-orange" @click="insert()">
                    <span class="btn-txt">{{ $t('btn_accetp_continue') }}</span>
                  </button>
                </div>
              </div>
            </div>
            <div class="mt-6" v-else>
              <div class="text-container mt-6">
                <span class="title2">{{ $t('redirect') }}</span>
              </div>
            </div>
          </div>
          <div v-if="!isOpen">
            <span class="qt-error-txt" ><b>{{ $t('local_close') }} <br>{{ $t('impossible_continue') }}</b></span>
            <hr>
          </div>
        </div>
      </div>
    </div>
    <FooterComponent />
  </div>
</template>

<style lang="css" scoped>

.qt-pb{
  padding-bottom: 5rem !important;
}

.btn-orange{
  width: 313px;
  height: 62px;
}

@media only screen and (max-width: 425px) {
  .btn-orange{
    width: 285px;
  }
}

</style>

