<script setup lang="ts">
import FooterComponent from '@/components/FooterComponent.vue';
import NavbarComponent from "@/components/NavbarComponent.vue";
import QrImageComponent from "@/components/QrImageComponent.vue";
import { useRoute } from "vue-router";
import { useConcatenatedString } from "@/composables/useUtils";

const route = useRoute();
const idErrore = useConcatenatedString(route.params.idErrore);
</script>

<template>
  <div class="body-container">
    <NavbarComponent />
    <div class="container-subTiltle-med mt-6">
      <span v-if="'429'===idErrore" class="titleOrangBig">
        429<br>Too Many Requests<br>
        <span class="title7">
          {{ $t('more_orders_open') }}
        </span>
      </span>
      
      <span v-else-if="'400'===idErrore" class="titleOrangBig">
        400<br>Bad Request<br>
        <span class="title7">
          {{ $t('no_data') }}
        </span>
      </span>

      <span v-else class="titleOrangBig">
        404<br>NOT FOUND<br>
        <span class="title7">
          {{ $t('page_not_found') }}
        </span>
      </span>
      
      <div v-if="'400'===idErrore" class="mt-6 ml-6 mr-6 mb-6">
        <QrImageComponent />
      </div>
      <div v-else class="mt-6 ml-6 mr-6 mb-6">
        <figure class="image is-2by1">
          <img src="@/assets/images/_err.png" alt="err" />
        </figure>
      </div>      
    </div>
  </div>
  <FooterComponent />
</template>
