<script setup lang="ts">
import { defineProps } from "vue";
import { CatalogGroupType, Order, Shopping } from "../types";

const props = defineProps({
  order: {
    type: Object as () => Order,
    required: true,
  },
  cataloGroupType: {
    type: Number as () => CatalogGroupType,
    required: false
  }
});

function filterProductsByCatalogGroupType(shoppings: Shopping[], catalogGroupType: CatalogGroupType | undefined) : Shopping[] {
  if(catalogGroupType)
    return shoppings.filter((s) => s.catalogGroupType === catalogGroupType);
  else
    return shoppings;
}

</script>

<template>
  <div v-for="cart in props.order.carts" :key="cart.id" class="columns">
    <div v-for="(product, prodIndex) in filterProductsByCatalogGroupType(cart.shoppings, props.cataloGroupType)" 
        :key="prodIndex" 
        class="column pt-2 pl-0 pr-0">
      <p class="qt-text-p"><b>{{ product.productInfoDescriptionsName_it.toUpperCase() }}</b></p>
      <p class="qt-text-p-light">{{ product.productInfoCompositionsQuantity }} x {{ product.productInfoCompositionsName_it.toUpperCase() }}</p>
    </div>
  </div>
</template>

<style lang="css" scoped>

.order-card {
  padding-top: 1%;
  padding-bottom: 1%;
}
.order-card strong {
  padding-bottom: 2%;
  padding-top: 2%;
}

@media only screen and (min-width:426px) and (max-width:1024px) {
  .qt-text-p{
    font-size: 14px;
  }

  .qt-text-p-light{
    font-size: 13px;
  }
}

@media screen and (min-width: 769px), print {
    .columns:not(.is-desktop) {
        all: unset;
    }
}
</style>