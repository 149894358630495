import { Language, Order, OrderStatus, PaymentStatus, ServiceZone } from "@/types";

enum ServiceZone_IT {
    TAKEWAY = 'TAKE AWAY',
    ONSIT = 'SALA',
    STANDUP = 'BANCONE',
}
enum PaymentStatus_IT {
    PENDING = 'Pagamento in attesa',
    COMPLETE = 'Pagamento effettuato',
    REJECTED = 'Pagamento rifiutato',
}
enum OrderStatus_IT {
    OPEN = 'Aperto',
    MASTER_READY = 'Aperto',
    PENDING = 'In Preparazione',
    COMPLETE = 'Completato',
    DELETED = 'Cancellato',
}
export function useTranslateServiceZone(serviceZone: string, language: Language): string {
    switch (language) {
     case Language.IT: {
         switch (serviceZone) {
             case ServiceZone.takeaway.toString(): return ServiceZone_IT.TAKEWAY;
             case ServiceZone.onsit.toString(): return ServiceZone_IT.ONSIT;
             case ServiceZone.standup.toString(): return ServiceZone_IT.STANDUP;
             default: return serviceZone;
         }
     }
     default:
         return serviceZone.toString();
    }
 }
export function useTranslatePaymentStatus(order: Order, language: Language): string {
    const paymentStatus = order.carts[0].pay.paymentStatus;
    switch (language) {
      case Language.IT: {
        switch (paymentStatus) {
            case PaymentStatus.PENDING: return PaymentStatus_IT.PENDING;
            case PaymentStatus.COMPLETE: return PaymentStatus_IT.COMPLETE;
            case PaymentStatus.REJECTED: return PaymentStatus_IT.REJECTED;
            default: return paymentStatus;
        }
      }
      default:
        return paymentStatus.toString();
    }
}
export function useTranslateOrderStatus(orderStatus: OrderStatus, language: Language): string {
    switch (language) {
      case Language.IT: {
        switch (orderStatus) {
            case OrderStatus.OPEN: return OrderStatus_IT.OPEN;
            case OrderStatus.MASTER_READY: return OrderStatus_IT.MASTER_READY;
            case OrderStatus.PENDING: return OrderStatus_IT.PENDING;
            case OrderStatus.COMPLETE: return OrderStatus_IT.COMPLETE;
            case OrderStatus.DELETED: return OrderStatus_IT.DELETED;
            default: return orderStatus;
        }
      }
      default:
        return orderStatus.toString();
    }
}
