<script setup lang="ts">
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { emailSignIn } from "@/firebase/firebaseUtilities";
import { redirectWithName } from "@/composables/useNavigation";
import FooterComponent from '@/components/FooterComponent.vue'


const credentialError = ref(false);
const router = useRouter();
const email = ref("");
const password = ref("");

async function loginUser() {
  try {
    await emailSignIn({ email: email.value, password: password.value });
    redirectWithName(router, "local");
  } catch (error) {
    credentialError.value = true;
  }
}
</script>

<template>
  <nav id="headerComponent" class="navbar qt-nav-container qt-nav-align" role="navigation" aria-label="main navigation" >
    <div class="navbar-brand">
      <img :src="require(`@/assets/images/imgNavLogin.png`)" style="cursor: pointer;" class="qt-nav-img"/>
    </div>
  </nav>
  <div class="body-container is-justify-content-center qt-login-section">
    <div class="columns is-multiline">
      <div class="column qt-margin-box">
        <h1 class="qt-login-title has-text-left">{{ $t('welcome') }}</h1>
        <div class="image-container">
          <img src="../assets/images/loginEsercente.svg" class="image" />
        </div>
        <h1 class="qt-login-subtitle has-text-left">{{ $t('login_to_access') }}</h1>
      </div>
      <div class="column  qt-login qt-container qt-margin-box" >
        <p class="t1boldLarger mt-3 mb-4 has-text-centered">{{ $t('insert_credentials') }}</p>
        <form @submit.prevent="loginUser">
          <div class="field ml-0 mb-5">
            <label class="label has-text-left">{{ $t('email') }}</label>
            <input class="input" type="email" id="email" v-model="email" placeholder="Inserisci la tua email..." required autocomplete="current-password">
          </div>
          <div class="field">
            <label class="label has-text-left">{{ $t('password') }}</label>
            <input class="input" type="password" id="password" v-model="password" placeholder="Inserisci la tua password..." required>
          </div>
          <br>
          <p v-if="credentialError">
            <label class="label" style="color: red;">{{ $t('error_credentials') }}</label>
          </p>
          <p v-else>
            <label class="label">&nbsp;</label>
          </p>
          <div class="field pt-3 pb-5">
            <p class="control">
              <button class="button is-dark" type="submit">{{ $t('enter') }}</button>
            </p>
          </div>
          <div class="field pb-6">
            <p class="control"><span>{{ $t('problem') }}</span></p>
          </div>
        </form>
      </div>
    </div>
  </div>
  <FooterComponent />
</template>

<style lang="css" scoped>

.qt-container {
    padding-left: 3rem;
    padding-right: 3rem;
}

.qt-nav-container{
  background: black;
}

.qt-margin-box{
  margin-left: 10rem;
  margin-right: 10rem;
}

.image-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

.image {
  width: 100%;
  height: auto; /* Per mantenere l'aspetto proporzionale dell'immagine */
  text-align: center;
}

#navbar {
  display: inline;
}

.testo {
  font-style: normal;
  font-family: "Roboto";
  letter-spacing: 0px;
  color: #FFFFFF;
  opacity: 1;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.imagesocial {
  height: 54px;
}

.qt-login{
  background: transparent linear-gradient(180deg, #F15A24 0%, #FFA800 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 6px 10px #00000029;
    border-radius: 10px;
    opacity: 1;
}

.qt-login-section{
    min-height: 45rem;
    background: transparent url("@/assets/images/loginBck.svg") 0% 0% no-repeat padding-box;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 10rem;
    padding-bottom: 10rem;
}

.qt-login-title{
  font-size: 6em;
  color: white;
  padding-bottom: 2rem;
}

.qt-login-subtitle{
  font-size: 2em;
  color: white;
  padding-top: 4rem;
}

@media only screen and (max-width: 425px) {

  .qt-nav-align {
    justify-content: center !important;
  }

  .qt-margin-box{
    margin-left: 2rem;
    margin-right: 2rem;
    margin-bottom:3rem;
  }

  .qt-login-section{
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .qt-login-subtitle {
    font-size: 1em;
    color: white;
    padding-top: 3rem;
    font-family: "Roboto";
  }

  .qt-login-title {
    font-size: 2em;
    color: white;
    padding-bottom: 1rem;
    font-family: "Roboto";
  }
}

@media only screen and (min-width:426px) and (max-width:767px) {

  .qt-margin-box{
    margin-left: 6rem;
    margin-right: 6rem;
    margin-bottom:3rem;
  }

  .qt-login-section{
    padding-top: 6rem;
    padding-bottom: 6rem;
  }

}

@media only screen and (min-width:768px) and (max-width:2100px) {
  .qt-margin-box{
    margin-left: 4rem;
    margin-right: 4rem;
    margin-bottom:3rem;
  }

  .qt-login-section{
    padding-top: 6rem;
    padding-bottom: 6rem;
  }

  .qt-login-title {
    font-size: 3em;
    color: white;
    padding-bottom: 1rem;
  }

  .qt-login-subtitle{
    font-size: 1.2em;
    color: white;
    padding-top: 2rem;
  }
}

</style>

