/* eslint-disable @typescript-eslint/no-explicit-any */
import { METHOD_FUNCTION, URL_PAYMENT, FLOW_PAYMENT, URL_DETAILS} from "@/constants/satisPayConst";
import { PayEvent, ProviderPay, PaymentStatus, Pay, Order, Cart, OrderStatus } from "@/types";
import { CURRENCY_CODE, DEFAULT_VALUE, SATISPAY_RESPONSE_ID } from "@/constants/globalConst";
import axios from 'axios';
import { useCreateCart, useCreateOrder, useCreatePay, useCreatePayRequest, useCreatePayResponse, useSleep } from "@/composables/useUtils";
import { Store } from "vuex";
import { useUpdateOrderDocumentById } from "@/firebase/firebaseUtilities";
import { LocationQuery } from "vue-router";

export async function useSatisPayCreatePayment(store: Store<any>, query: LocationQuery, windowCurrentUrl: string, order: Order): Promise<{ orderSatis: Order, paySatis: Pay }> {
  let redirectUrl;
  alert(order.totalAmount);
  order.totalAmount = 0.01;
  alert(order.totalAmount);
  if (windowCurrentUrl.includes("cartPayConfirm")) {
    redirectUrl = await windowCurrentUrl.replace('cartPayConfirm',`cart/satispayVerifyPayment/${order.id}`)+`&${SATISPAY_RESPONSE_ID}=`;
  } else if (windowCurrentUrl.includes("cartUp")){
    redirectUrl = await windowCurrentUrl.replace('cartUp',`cart/satispayVerifyPayment/${order.id}`)+`&${SATISPAY_RESPONSE_ID}=`;
  }
  const paymentDataRequest = {
    method: METHOD_FUNCTION.toLowerCase(),
    url: URL_PAYMENT,
    data: {
      flow: FLOW_PAYMENT,
      amount_unit: order.totalAmount * 100,
      currency: CURRENCY_CODE,
      redirect_url: redirectUrl,
    }
  };
  const response = await axios.request(paymentDataRequest);
  const resposneStaus = response.status;
  const paymentDataResponse = await response.data;
  const req = useCreatePayRequest(order.totalAmount);
  const res = useCreatePayResponse(paymentDataResponse.type ?? DEFAULT_VALUE, FLOW_PAYMENT, paymentDataResponse.flow ?? DEFAULT_VALUE);
  let pay: Pay = null as unknown as Pay;
  if (resposneStaus === 200){
    const satispayId = await paymentDataResponse.id;
    const redirect_url = await paymentDataResponse.redirect_url + `${satispayId}`;
    pay = useCreatePay (
      satispayId, paymentDataResponse.receiver.id, paymentDataResponse.receiver.type, 
      ProviderPay.SATISPAY, PayEvent.SUCCESS, PaymentStatus.PENDING,
      paymentDataResponse.code_identifier, redirect_url, req, res);
  } else {
    const satispayId = await paymentDataResponse.id;
    const redirect_url = await paymentDataResponse.redirect_url + `${satispayId}`;
    pay = useCreatePay (
      satispayId, paymentDataResponse.receiver.id, paymentDataResponse.receiver.type, 
      ProviderPay.SATISPAY, PayEvent.ERROR, PaymentStatus.REJECTED,
      paymentDataResponse.code_identifier, redirect_url, req, res);
  }
  const c: Cart = useCreateCart(store, query, pay);
  const newOrder: Order = useCreateOrder(store, query, order.identifyOrderClient, c);
  newOrder.id = order.id;
  newOrder.identifyOrderApp = order.identifyOrderApp;
  await useUpdateOrderDocumentById(newOrder);
  const orderSatis = newOrder;
  const paySatis = pay;
  return { orderSatis, paySatis };
}
export async function useSatisPayDetailsPayment(order: Order, satisPayId: string): Promise<void> {
  const paymentDataRequest = {
    method: METHOD_FUNCTION.toLowerCase(),
    url: URL_DETAILS,
    data: {
      id: satisPayId
    }
  };
  await onSatisPaymentVerify(order, paymentDataRequest, satisPayId);
}
async function onSatisPaymentVerify(orderVal:Order, paymentDataRequest:any, satisPayId: string) {
  let prosegui = true;
  for (let i = 0; i < 3 && prosegui; i++) {
    await useSleep(1000);
    try {
      const response = await axios.request(paymentDataRequest);
      const resposneStaus = response.status;
      const paymentDataResponse = await response.data;
      const paymentDataResponseStaus = paymentDataResponse.status;
      if (resposneStaus === 200){
        prosegui = await updateDocument(orderVal, paymentDataResponseStaus, satisPayId);
      }
    } catch (error) {
      console.error('Errore durante la richiesta di dettagli POST:', i, error);
    }
  }
  await useSleep(500);
}
async function updateDocument(orderVal:Order, paymentDataResponseStaus: string, satisPayId: string): Promise<boolean> {
  let prosegui = false;
  const newOrder: Order = JSON.parse(JSON.stringify(orderVal));
  const index = orderVal.carts.findIndex(element => element.pay.id === satisPayId);
  if (index >= 0) {
    if (paymentDataResponseStaus === "ACCEPTED") {
      newOrder.carts[index].pay.paymentStatus = PaymentStatus.COMPLETE;
      newOrder.carts[index].pay.payEvent = PayEvent.SUCCESS;
      newOrder.orderStatus = OrderStatus.PENDING;
    } else if (paymentDataResponseStaus === "CANCELED") {
      newOrder.carts[index].pay.paymentStatus = PaymentStatus.REJECTED;
      newOrder.carts[index].pay.payEvent = PayEvent.ERROR;
      newOrder.orderStatus = OrderStatus.DELETED;
    } else if (paymentDataResponseStaus === "PENDING") {
      prosegui = true;
      newOrder.carts[index].pay.paymentStatus = PaymentStatus.PENDING;
      newOrder.carts[index].pay.payEvent = PayEvent.NOBODY;
      newOrder.orderStatus = OrderStatus.OPEN;
    }
    await useUpdateOrderDocumentById(newOrder);
  }
  return prosegui;
}
