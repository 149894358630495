<script setup lang="ts">
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';

const store = useStore();
const { locale } = useI18n();

const languages = {
    en: 'English',
    it: 'Italiano'
};

const selectedLanguage = ref(store.getters.getLocale);

const changeLanguage = () => {
    store.commit('setLocale', selectedLanguage.value);
    locale.value = selectedLanguage.value;
};

</script>

<template>
    <div class="language-switcher select">
        <select v-model="selectedLanguage" @change="changeLanguage" class="select qt-text-p">
            <option class="qt-text-p" v-for="(lang, key) in languages" :key="key" :value="key">{{ lang }}</option>
        </select>
  </div>
</template>

<style lang="css" scoped>

</style>