<script setup lang="ts">
</script>

<template>
  <nav id="footerComponent" role="navigation" class="qt-footer">
    <div class="columns is-mobile is-align-self-center mb-0">
      <div class="column is-3-mobile is-2-tablet is-2-desktop">
        <img :src="require(`@/assets/images/LogoFooter.png`)" class="qt-footer-logo"/>
      </div>
      <div class="column is-6-mobile is-8-tablet is-8-desktop is-align-self-center">
        <a href="https://www.magnaebasta.app" target="_blank" class="button qt-btn-yellow qt-btn-txt">
          {{ $t('discover_meb') }}
        </a>
      </div>
      <div class="column is-3-mobile is-2-tablet is-2-desktop is-align-self-center">
        <span :href="`https://www.instagram.com/quick.tail/`">
          <img :src="require(`@/assets/images/instagram.png`)" class="qt-footer-social-logo mt-2" />
        </span>
      </div>
    </div>
    <div class="columns is-mobile">
      <div class="column is-12 is-align-self-center p-0">
        <span class="qt-footer-txt has-text-centered qt-btn-txt">www.magnaebasta.app</span>
      </div>
    </div>
    <div class="columns is-mobile pb-3">
      <div class="column is-7 has-text-left qt-padding-left">
        <span class="qt-footer-txt qt-txt">© Copyright 2024 MAGNA E BASTA™ Powered by Beasy4BIZ</span>
      </div>
      <div class="column is-5 has-text-right qt-padding-right">
        <span class="qt-footer-txt qt-txt">Privacy e Policy</span>
      </div>
    </div> 
  </nav>
</template>

<style lang="css" scoped>
#navbar {
  display: inline;
}
.qt-footer-txt {
  font-style: normal;
  font-family: "Roboto";
  letter-spacing: 0px;
  color: #FFFF3A;
  opacity: 1;
}
.qt-footer-social-logo {
  height: 45px;
}

.qt-footer-logo {
  height: 80px;
}

.qt-footer{
  background-color: black;
}

.qt-btn-yellow{
  font-family: "Roboto-bold";
  background-color: #FFFF3A;
  border-color: transparent;
  color: #000000;
}

.qt-btn-txt{
  font-size:1.2em
}

.qt-txt{
  font-size:0.8em;
  color:#FFFFFF;
  font-family: "Roboto-light";
}

.qt-padding-left{
  padding-left: 4rem;
  padding-right: 0rem;
}

.qt-padding-right{
  padding-right: 4rem;
}

@media only screen and (max-width: 425px) {
    
  .qt-footer-social-logo {
    height: 33px;
  }

  .qt-footer-logo {
    height: 55px;
  }

  .qt-btn-txt{
    font-size:0.7em
  }

  .qt-txt{
    font-size:0.5em
  }

  .qt-padding-left{
    padding-left: 1.7rem;
    padding-right: 0rem;
  }

  .qt-padding-right{
    padding-right: 1.7rem;
  }
}

@media only screen and (min-width:426px) and (max-width:1024px) {

  .qt-btn-txt{
    font-size:0.9em
  }

  .qt-txt{
    font-size:0.6em
  }

  .qt-padding-left{
    padding-left: 3rem;
    padding-right: 0rem;
  }

  .qt-padding-right{
    padding-right: 3rem;
  }

}
</style>