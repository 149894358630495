<!-- eslint-disable @typescript-eslint/no-non-null-assertion -->
<script setup lang="ts">
import { ref, onBeforeMount, onMounted, computed } from "vue";
import { useConcatenatedString, useGetCookieParam, useScrollToTop } from "@/composables/useUtils";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { LOCAL_NAME, } from "@/constants/globalConst";
import { useCaricaCatalogItem, useCaricaLocal, useStoredLocal } from "@/composables/useInit";
import { Catalog, CatalogItem, Local, ServiceZoneCatalog, CatalogGroupType } from "@/types";
import FooterComponent from '@/components/FooterComponent.vue';
import NavbarComponent from "@/components/NavbarComponent.vue"; 
import NavbuttonComponent from "@/components/NavbuttonComponent.vue";
import CardComponent from "@/components/CardComponent.vue";
import { addQueryParam, redirectMenu } from "@/composables/useNavigation";

const route = useRoute();
const store = useStore();
const router = useRouter();
const isLoading = ref<boolean>(true);
const idLocale = route.params.idLocale;
const serviceZone = useConcatenatedString(route.query.serviceZone);
const visulizeAlcol = useConcatenatedString(route.query.visulizeAlcol);
const shoppingFather = store.getters.getShoppingFather;
const local = ref<Local>();
let mapCatalogItem = new Map<string, CatalogItem>();
const keyCatalogItem = ref<string | null | undefined>();
const logoCatalogItem = ref<string | null | undefined>();
const nameItCatalogItem = ref<string | null | undefined>();
const nameEnCatalogItem = ref<string | null | undefined>();
const table = useConcatenatedString(route.query.table);
const identifyOrderApp = useGetCookieParam('identifyOrderApp');

onBeforeMount(async () => {
  if (!shoppingFather) {
    redirectMenu(router, serviceZone, route.query);
  }
  if (!store.getters.getLocalId) {
    const l = await useCaricaLocal(useConcatenatedString(idLocale));
    local.value = l;
    useStoredLocal(store, l);
  } else {
    local.value = store.getters.getLocal;
  }
  const serviceZoneCatalog: ServiceZoneCatalog[] = local.value!.serviceZoneCatalogs;
  const serviceZoneCatalogFiltered: ServiceZoneCatalog | undefined = serviceZoneCatalog.find(item=> item.serviceZone === serviceZone);
  const catalogs: Catalog[] = serviceZoneCatalogFiltered?.catalogs ?? [];
  const catalogsDrink: Catalog[] = catalogs.filter(item => (item.groupType === CatalogGroupType.DRINK && item.alcol.toString() === visulizeAlcol)) ?? [];
  const promises = catalogsDrink.map(async element => {
    const id = element.catalogId;
    const ci: CatalogItem = await useCaricaCatalogItem(id);
    return [id, ci] as [string, CatalogItem];
  });
  const catalogItemsArray = await Promise.all(promises);
  const map = new Map<string, CatalogItem>(catalogItemsArray);  
  mapCatalogItem = map;
  if (!keyCatalogItem.value){
    const id = map.keys().next().value;
    keyCatalogItem.value = id;
    const ci: CatalogItem = mapCatalogItem.get(id)!;
    logoCatalogItem.value = ci.logoPath;
    nameItCatalogItem.value = ci.names.it.name;
    nameEnCatalogItem.value = ci.names.en.name;
  }
  addQueryParam(route, router, 'catalog', keyCatalogItem.value);
  isLoading.value = false;
});
onMounted(() => {
  useScrollToTop();
});
const productsInfoValues = computed(() => {
  if (keyCatalogItem.value) {
    const productCatalog = mapCatalogItem.get(keyCatalogItem.value);
    return productCatalog?.productInfo ?? [];
  } else {
    return [];
  }
});
const rechargeFiltered = (id: string) => {
  keyCatalogItem.value = id;
  const ci: CatalogItem = mapCatalogItem.get(id)!;
  logoCatalogItem.value = ci.logoPath;
  nameItCatalogItem.value = ci.names.it.name;
  nameEnCatalogItem.value = ci.names.en.name;
  addQueryParam(route, router, 'catalog', keyCatalogItem.value);  
};
</script>

<template>
  <div v-if="!isLoading">
    <div class="body-container">
      <NavbarComponent/>
      <div class="t1 qt-container pb-1">
        <span class="t1boldxlarge">{{ $t('welcome_menu') }} {{ LOCAL_NAME }}!</span>
        <br/>
        <div v-if="table || identifyOrderApp">
          <span v-if="table" class="qt-text-card-title-black">{{ $t('table-lable') }} <span style="color: #F15A24;">{{ table }}</span>, </span>
          <span v-if="identifyOrderApp" class="qt-text-card-title-black">{{ $t('order-lable') }} <span style="color: #F15A24;">{{ identifyOrderApp }}</span></span>
          <br/>
        </div>
        <span class="t1bold">{{ $t('drink_associated') }}</span>
      </div>
      <div class="t1 qt-container pb-1">
        <NavbuttonComponent 
          :mapCatalogItem="mapCatalogItem"
          @rechargeFiltered="rechargeFiltered" 
        />        
      </div>
      <section class="section qt-container">
        <div class="columns is-multiline">
          <div v-for="productInfo in productsInfoValues" :key="productInfo.id" class="column is-4-desktop is-6-tablet is-12-mobile">
            <CardComponent
              :zeroPageEnabled=true
              :keyCatalogItem="keyCatalogItem!"
              :logoCatalogItem="logoCatalogItem!"
              :nameItCatalogItem="nameItCatalogItem!"
              :nameEnCatalogItem="nameEnCatalogItem!"
              :item="productInfo"
            />
          </div>
        </div>
      </section>
    </div>
    <br/>
    <FooterComponent />
  </div>
</template>
