import { ref } from "vue";

export function usePagination(itemsPerPage: number){
    const startIndex = ref<number>(0)
    const endIndex= ref<number>(itemsPerPage);

    function handlePageChange(page: number){
        startIndex.value = (page * itemsPerPage) - itemsPerPage;
        endIndex.value = startIndex.value + itemsPerPage;
    }

    return {startIndex, endIndex, handlePageChange}
}