/* eslint-disable @typescript-eslint/no-explicit-any */
import {
    ALLOWED_AUTH_METODS, ALLOWED_CARD_NET_WORKS, ALLOW_PREPAID_CARDS, ALLOW_CREDIT_CARDS, TYPE, GATEWAY, GATEWAY_MERCHANT_ID, MERCHANT_NAME, MERCHANT_ID
} from '@/constants/googlePayConst';

export function usePaymentData(): any {
    return {
        //* @see {@link https://developers.google.com/pay/api/web/reference/request-objects}
        apiVersion: 2,
        apiVersionMinor: 0,
        allowedPaymentMethods: [{
            type: 'CARD',
            parameters: {
                //* @see {@link https://developers.google.com/pay/api/web/reference/request-objects#PaymentMethod}
                allowedAuthMethods: ALLOWED_AUTH_METODS,
                allowedCardNetworks: ALLOWED_CARD_NET_WORKS,
                allowPrepaidCards: ALLOW_PREPAID_CARDS,
                allowCreditCards: ALLOW_CREDIT_CARDS,
            },
            tokenizationSpecification: {
                //* @see {@link https://developers.google.com/pay/api/web/reference/request-objects#PaymentMethodTokenizationSpecification}
                type: TYPE,
                parameters: {
                    gateway: GATEWAY,
                    gatewayMerchantId: GATEWAY_MERCHANT_ID,
                },
            },
        }],
        merchantInfo: {
            merchantId: MERCHANT_ID,
            merchantName: MERCHANT_NAME,
        },
    };
}


