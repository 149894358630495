<!-- eslint-disable @typescript-eslint/no-non-null-assertion -->
<script setup lang="ts">
import { useStore } from "vuex";
import { useRoute, useRouter } from 'vue-router';
import { redirectCart } from "@/composables/useNavigation";
import { nextTick, onMounted, watch } from "vue";
import { useConcatenatedString } from "@/composables/useUtils";
import { onBeforeUnmount } from "vue";

const store = useStore();
const router = useRouter();
const route = useRoute();
const serviceZone = useConcatenatedString(route.query.serviceZone);
const table = useConcatenatedString(route.query.table);

const buttonClick = () => {
  redirectCart(router, serviceZone, table);
};
watch(() => store.getters.getCounterCart, () => {
  nextTick(() => {
    getPosition();
  });
});
onMounted(() => {
  window.addEventListener('scroll', getPosition);
});
onBeforeUnmount(() => {
  window.removeEventListener('scroll', getPosition);
});
function getPosition() {
  if (store.getters.getCounterCart !== 0) {
    const windowHeightPx = window.innerHeight;
    const divElementFooter = document.getElementById('footerComponent')!;
    const divElement = document.getElementById('cartScrollButton')!;
    const distanceDivElementFooterFromTopPx = divElementFooter.getBoundingClientRect().top;
    const divElementHeightPx = 60;
    divElement.style.bottom = '15%';
    if (distanceDivElementFooterFromTopPx + divElementHeightPx <= windowHeightPx) {
      divElement.style.bottom = `${windowHeightPx - distanceDivElementFooterFromTopPx + divElementHeightPx}px`;
    }
  }
}
</script>

<template>
  <div id="cartScrollButton" v-if="store.getters.getCounterCart !== 0" class="cartContainer">
    <div class="round red-dot">{{ store.getters.getCounterCart }}</div>
    <button class="round round-button"  @click="buttonClick" >
      <img src="@/assets/images/shoppingCart.png" alt="cartImg" />
    </button>
  </div>
</template>

<style lang="css" scoped>
.cartContainer{
  position: fixed;
  z-index: 102;
  bottom: 15%;
}
.red-dot {
  position: fixed;
  right: 1.5rem;
  cursor: pointer;
  color: white;
  background-color: #FFA800;
  z-index: 101;
  width: 30px;
  height: 30px;
}
.round-button {
  position: fixed;
  right: 2rem;
  cursor: pointer;
  color: white;
  background-color: #FFFF3A;
  z-index: 100;
  padding: 25px;
}
</style>
