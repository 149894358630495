<script setup lang="ts">
import { useRoute } from "vue-router";
import { defineEmits, defineProps} from "vue";
import { PayEvent, ProviderPay, PaymentStatus, Pay } from "@/types";
import { v4 as uuidv4 } from "uuid";
import { useConcatenatedString, useCreatePay, useCreatePayRequest, useCreatePayResponse } from "@/composables/useUtils";
import { DEFAULT_VALUE, LOCAL_NAME } from "@/constants/globalConst";

const route = useRoute();
const idLocale = useConcatenatedString(route.params.idLocale);
const props = defineProps({
  totalPay: {
    type: Number,
    required: true,
  },
  identificativoOrdineCliente: {
    type: String,
    required: true,
  }
});

const emit = defineEmits<{
  (event: 'processPayment', pay: Pay): void;
  (event: 'identifyOrderClientNotFound'): void;
}>();
const processPayment = (pay: Pay) => {
  emit('processPayment', pay);
};
const identifyOrderClientNotFound = () => {
  emit('identifyOrderClientNotFound');
};
async function onTablePaymentButtonClicked() {
  if (props.identificativoOrdineCliente === null || props.identificativoOrdineCliente === undefined || props.identificativoOrdineCliente === '') {
    identifyOrderClientNotFound();
  } else {
    const p = ProviderPay.TABLEPAY.toString();
    const req = useCreatePayRequest(props.totalPay);
    const res = useCreatePayResponse(p, p, p);
    const id = uuidv4();
    const pay: Pay = useCreatePay (
      id, idLocale, LOCAL_NAME, 
      ProviderPay.TABLEPAY, PayEvent.NOBODY, PaymentStatus.PENDING,
      id, DEFAULT_VALUE, req, res);
    processPayment(pay);
  }
}
</script>

<template>
  <div class="mt-4 mb-4">
    <button id="tablePayButton" class="button btn-orange" @click="onTablePaymentButtonClicked()" >
      <span class="btn-txt">{{ $t('send_order') }}</span>
    </button>
  </div>
</template>
