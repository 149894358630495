<script setup lang="ts">
import { ref, onMounted, onBeforeMount, computed, onBeforeUnmount } from "vue";
import FooterComponent from '@/components/FooterComponent.vue'
import NavbarComponent from "@/components/NavbarComponent.vue"; 
import { useConcatenatedString, useGetCookieParam, useNow, useScrollToTop, useUpdateCookie } from "@/composables/useUtils";
import { CURRENCY, DEFAULT_VALUE } from "@/constants/globalConst";
import { useRoute, useRouter } from "vue-router";
import { CartActionTypes, Order, ServiceZone } from "@/types";
import { useStore } from "vuex";
import { useGetOrderDocumentById } from "@/firebase/firebaseUtilities";
import { useOrderPay, useOrderSuccess } from "@/composables/useUtilsCart";
import { redirectError } from "@/composables/useNavigation";

const router = useRouter();
const store = useStore();
const route = useRoute();
const isMaster = ref(false);
const serviceZone = ref(DEFAULT_VALUE);
let order: Order = store.getters.getOrder;
const identifyOrderApp = computed(() => { return order?.identifyOrderApp; });
const identifyOrderClient = computed(() => { return order?.identifyOrderClient; });
const totalPay = computed(() => { 
  return (order?.totalAmount>0) ? order?.totalAmount : store.getters.getTotalPay; 
});
const isReady = ref(false);

onMounted(() => {
  useScrollToTop();
});
onBeforeMount(async () => {
  isMaster.value = await useGetCookieParam("isMaster");
  serviceZone.value = route.query.serviceZone ? useConcatenatedString(route.query.serviceZone) : await useGetCookieParam('serviceZone');
  if (!order) {
    if (ServiceZone.onsit === serviceZone.value && !isMaster.value) {
      redirectError(router, 400);
    } else {
      const orderId = await useGetCookieParam('orderId');
      if (orderId) {
        const orderInt = await useGetOrderDocumentById(orderId);
        if (orderInt) {
          order = orderInt;
          store.commit("setOrder", order);
        }
      }
    }
  }
  const params = {
    timestampExpire: useNow().seconds,
    table: DEFAULT_VALUE,
  };
  useUpdateCookie(store, params);
  isReady.value = true;
})
const isOrderSuccess =() => { 
  if (ServiceZone.onsit === order.serviceZone && !isMaster.value) {
    return true;
  } else {
    return useOrderSuccess(order); 
  }
};
const isOrderPay =() => { 
  return useOrderPay(order);
};
onBeforeUnmount(() => {
  store.commit("managementCart", { shopping: null, cartActionType: CartActionTypes.RESET });
});
</script>

<template>
  <div v-if="isReady" class="body-container">
    <NavbarComponent />

    <div class="qt-container pb-2">
      <div class="columns is mobile">
        <div class="column has-text-left pb-4">
          <span v-if="isMaster" class="qt-text-title-orange">{{ $t('confirm_of_order') }}</span>
          <span v-else class="qt-text-title-orange">{{ $t('confirm_cart') }}</span>
        </div>
      </div>
    </div>

    <div class="qt-container pb-6">
      <div class="qt-card">
        <span class="qt-text-card-title-black">{{ $t('thanks') }}&nbsp;</span>
        <span v-if="isMaster && ServiceZone.onsit === serviceZone" class="qt-text-card-title-black">{{ $t('order_to_merchant') }}</span>
        <span v-else-if="ServiceZone.onsit === serviceZone" class="qt-text-card-title-black">{{ $t('order_to_master') }}</span>
        <span v-else class="qt-text-card-title-black">{{ $t('your_order') }}</span>
        <div class="columns is-vcentered m-0 p-0 mt-5">
          <div v-if="isOrderSuccess()" class="column p-0">
            <div class="single-row-item">
              <figure class="icon mr-2">
                <img src="@/assets/images/successGreen.png" alt="success"/>
              </figure>
              <span v-if="!isMaster && ServiceZone.onsit === serviceZone" class="qt-text-h4">{{ $t('incoming_order') }}</span>
              <span v-else class="qt-text-h4">{{ $t('order_received') }}</span>  
            </div> 
          </div>
          <div v-else class="column p-0">
            <div class="single-row-item">
              <figure class="icon mr-2">
                <img src="@/assets/images/errorRed.png" alt="error"/>
              </figure>
              <span class="qt-text-h4">{{ $t('ops') }}<br/>{{ $t('order_error') }}</span> 
            </div>
          </div>
        </div>
        <hr>
        <div class="mt-2">
          <div class="container-vcenter">
            <span class="qt-text-h4">{{ $t('order_id') }}</span>
          </div>
          <div class="container-vcenter">
            <span class="qt-text-title-orange">{{identifyOrderApp}}</span>
          </div>
        </div>

        <div v-if="ServiceZone.onsit != serviceZone" class="mt-4">
          <div class="container-vcenter">
            <span class="qt-text-h4">{{ $t('identify_order') }}</span>
          </div>
          <div class="container-vcenter">
            <span class="qt-text-title-orange">{{identifyOrderClient}}</span>
          </div>
        </div>

        <div v-if="isOrderSuccess() || (ServiceZone.onsit === serviceZone && !isMaster)" class="mt-4">
          <div>
            <div class="container-vcenter">
              <span v-if="isOrderPay()" class="qt-text-h4">{{ $t('tot_payed') }}</span>
              <span v-else-if="ServiceZone.onsit === serviceZone && !isMaster" class="qt-text-h4">{{ $t('tot_cart') }}</span>
              <div v-else class="mt-4">
                <div class="container-vcenter">
                  <figure class="image is-24x24 mr-2">
                    <img src="@/assets/images/awaitTime.png" alt="awaitTime"/>
                  </figure>
                  <span class="qt-text-h4">{{ $t('tot_pay') }}</span>
                </div>
              </div>
            </div>
            <div class="container-vcenter">
              <span class="qt-text-card-title-black qt-lh">{{totalPay.toFixed(2)}} {{CURRENCY}}</span>
            </div>
          </div>
        </div>
        <div v-else class="mt-4">
          <div class="container-vcenter">
            <span class="qt-text-h4">{{ $t('tot_cart_not_payed') }}</span>
          </div>
          <div class="container-vcenter">
            <span class="qt-text-card-title-black qt-lh">{{totalPay.toFixed(2)}} {{CURRENCY}}</span>
          </div>
        </div>
        <hr>
        <div class="mt-2">
          <span class="qt-text-p">{{ $t('problems') }}</span>
        </div>
      </div>
    </div>
  </div>
  <FooterComponent />
</template>

<style lang="css" scoped>

.qt-text-h4 {
    font-size: 17px;
}

.qt-lh{
line-height: 2;
}

.single-row-item, .card-content-row-item {
    align-items: center;
    display: inline-flex;
}

@media only screen and (max-width: 425px) {
    .qt-text-h4 {
        font-size: 15px;
    }
}
</style>