/* eslint-disable @typescript-eslint/no-explicit-any */
export default function () {
  function handleKeyPress(event: any) {
    // Rileva il tasto F5 (codice 116)
    if (event.keyCode === 116) {
      event.preventDefault();
    }
  }

  return {
    inhibitF5() {
      document.addEventListener("keydown", handleKeyPress);
    },
    restoreF5() {
      document.removeEventListener("keydown", handleKeyPress);
    },
  };
}
