<script setup lang="ts">
import { ComputedRef, computed, defineProps, onBeforeMount, ref } from "vue";
import { CartActionTypes, Composition, Shopping, type ProductInfo } from "@/types";
import { useStore } from "vuex";
import { CURRENCY } from "@/constants/globalConst";
import { useCalculateUnityPrice, useConcatenatedString, useCreateShopping } from "@/composables/useUtils";
import { useRoute, useRouter } from "vue-router";
import { redirectMenu, redirectMenuZero } from "@/composables/useNavigation";

const store = useStore();
const route = useRoute();
const router = useRouter();
const query = route.query;
const serviceZone = useConcatenatedString(query.serviceZone);
const compositionSelected = ref(null as unknown as Composition);
let f: Shopping;
const selectedLanguage: ComputedRef<string> = computed(() => {
  return store.getters.getLocale;
});
onBeforeMount(async () => {
  f = await store.getters.getShoppingFather;
});
const props = defineProps({
  zeroPageEnabled: {
    type: Boolean,
    required: true,
  },
  keyCatalogItem: {
    type: String,
    required: true,
  },
  logoCatalogItem: {
    type: String,
    required: true,
  },
  nameItCatalogItem: {
    type: String,
    required: true,
  },
  nameEnCatalogItem: {
    type: String,
    required: true,
  },
  item: {
    type: Object as () => ProductInfo,
    required: true,
  },
});

const visualizeUnityPrice = (unityPrice: number) => {
  const price = useCalculateUnityPrice(store, serviceZone, props.keyCatalogItem, props.zeroPageEnabled? 0 : unityPrice);
  return price.toFixed(2);
};
const qty = (composition: Composition) => {
  return store.getters.getQuantityOfShopping (serviceZone, props.keyCatalogItem, props.item.id, composition.id, composition.unityPrice);
};
const incrementQty = (composition: Composition) => {
  if (!composition){
    return;
  }
  if (props.zeroPageEnabled) {
    if (composition) {
      store.commit('managementCart', { shopping: f, cartActionType: CartActionTypes.ADD });
      const o: Shopping = useCreateShopping(store, route, props.keyCatalogItem, props.logoCatalogItem, props.nameItCatalogItem, props.nameEnCatalogItem, props.item, props.zeroPageEnabled, composition, 1);
      store.commit('managementCart', { shopping: o, cartActionType: CartActionTypes.ADD });
      redirectMenu(router, serviceZone, query);
    }
  } else {
    const o: Shopping = useCreateShopping(store, route, props.keyCatalogItem, props.logoCatalogItem, props.nameItCatalogItem, props.nameEnCatalogItem, props.item, props.zeroPageEnabled, composition, 1);
    if (1 === props.item.logicImplement) {
      store.commit('managementCart', { shopping: o, cartActionType: CartActionTypes.ADD });
    }
    if (2 === props.item.logicImplement || 3 === props.item.logicImplement) {
      store.commit('setShoppingFather', o);
      const queryIn = { ...route.query, visulizeAlcol: composition.visulizeAlcol.toString() };
      redirectMenuZero(router, queryIn);
    }
  }
};
const decrementQty = (composition: Composition) => {
  const o: Shopping =  useCreateShopping(store, route, props.keyCatalogItem, props.logoCatalogItem, props.nameItCatalogItem, props.nameEnCatalogItem, props.item, props.zeroPageEnabled, composition, 1);
  store.commit('managementCart', { shopping: o, cartActionType: CartActionTypes.SUBTRACT });
};
function changeRadio(comp:Composition) {
  compositionSelected.value=comp;
}
</script>

<template>
  <div class="card qt-card">
    <div class="card-image">
      <div>
        <figure>
          <img class="is-fullwidth" :src="require(`@/assets/images/QT/${props.item.imagePath}`)" alt="img" />
        </figure>
      </div>
    </div>
    <div class="card-content">
      <div class="content">
        <p class="qt-text-title-orange">{{ props.item.descriptions[selectedLanguage].name }}</p>
        <p class="qt-text-p">{{ props.item.descriptions[selectedLanguage].ingredient }}</p>
        <p class="qt-text-p">{{ props.item.descriptions[selectedLanguage].note }}</p>
      </div>
      <div v-if="props.zeroPageEnabled" class="content mt-5 mb-2">
        <p v-if="props.item.compositions.length > 1" class="qt-text-card-subtitle">{{ $t('select') }}</p>
        <!--
          <p v-else-if="props.item.compositions[0].names[selectedLanguage].name" class="qt-text-card-subtitle">{{ $t('select') }}</p>
        -->
        </div>
      <div v-else class="content mt-5 mb-2">
        <p class="qt-text-card-subtitle">{{ $t('select') }}</p>
      </div>
      <!-- 1 === props.item.logicImplement -->      
      <div v-if="!props.zeroPageEnabled && 1 === props.item.logicImplement">
        <div v-for="(comp) in props.item.compositions" :key="comp.id" >
          <div class="content is-flex is-fullwidth is-justify-content-space-between mt-1">
            <div v-if="comp.names[selectedLanguage].name" class="child card-content-row-item qt-left" style="width: 30%;">
              <p class="qt-text-p">
                <b>
                  {{ comp.names[selectedLanguage].name }}
                </b>
              </p>
            </div>
            <div class="child card-content-row-item qt-center" style="max-width: 45%;">
              <div class="card-content-row-item">
                <button class="button is-small is-warning is-light"
                  style="width: min-content;"
                  @click="() => decrementQty(comp)" ><b class="qt-text-p">-</b></button>
                <input 
                  class="input is-small" 
                  type="number" 
                  :value="qty(comp)" 
                  style=" text-align: center; 
                          max-width: 10ch;"
                  disabled />
                <button class="button is-small is-warning is-light" 
                  style="width: min-content;"
                  @click="() => incrementQty(comp)" ><b class="qt-text-p">+</b></button>
              </div>
            </div>
            <div class="child card-content-row-item qt-right">
              <span v-if="comp.specialOffer" class="subtitle">
                <span class="qt-text-p">
                  <b>
                    {{ visualizeUnityPrice(comp.unityPrice) }}
                  </b>
                </span>
                <br>
                <span class="qt-text-p">
                  <b>
                    {{ comp.specialUnityPrice.toFixed(2) }}&nbsp;{{ CURRENCY }}
                  </b>
                </span>
              </span>
              <span v-else class="qt-text-p">
                <b>
                  {{ visualizeUnityPrice(comp.unityPrice) }}&nbsp;{{ CURRENCY }}
                </b>
              </span>
            </div>
          </div>
        </div>
      </div>
      <!-- 2 === props.item.logicImplement -->
      <div v-if="!props.zeroPageEnabled && 2 === props.item.logicImplement">
        <div v-for="(comp) in props.item.compositions" :key="comp.id" >
          <div class="content is-flex is-fullwidth is-justify-content-space-between mt-1">
            <div v-if="comp.names[selectedLanguage].name" class="child card-content-row-item">
              <p class="qt-text-p">
                <b>
                  {{ comp.names[selectedLanguage].name }}
                </b>
              </p>
            </div>
            <div class="child card-content-row-item">
              <span v-if="comp.specialOffer" class="subtitle">
                <span class="qt-text-p"> 
                  <b>
                    {{ visualizeUnityPrice(comp.unityPrice) }}
                  </b>
                </span>
                <br>
                <span class="qt-text-p">
                  <b>
                    {{ comp.specialUnityPrice.toFixed(2) }}&nbsp;{{ CURRENCY }}
                  </b>
                </span>
              </span>
              <span v-else class="qt-text-p"> 
              <b>
                {{ visualizeUnityPrice(comp.unityPrice) }}&nbsp;{{ CURRENCY }}
              </b>
              </span>
            </div>
          </div>
          <div class="content mt-6">
            <button class="button btn-orange" @click="incrementQty(comp)">
              <span class="btn-txt">{{ $t('add_and_select') }}</span>
            </button>
          </div>
        </div>
      </div>
      <!-- 3 === props.item.logicImplement -->
      <div v-if="!props.zeroPageEnabled && 3 === props.item.logicImplement">
        <div v-for="(comp) in props.item.compositions" :key="comp.id" >
          <div class="content is-flex is-fullwidth is-justify-content-space-between mt-1">
            <div class="child card-content-row-item">
              <div class="control">
                <label class="radio">
                  <p class="qt-text-p">
                    <input type="radio" name="radioButtonSelector" @click="changeRadio(comp)" />
                    <b>&nbsp;{{ comp.names[selectedLanguage].name }}</b>
                  </p>
                </label>
              </div>
            </div>
            <div class="child card-content-row-item">
              <span v-if="comp.specialOffer" class="subtitle">
                <span class="qt-text-p"> 
                  <b>
                    {{ visualizeUnityPrice(comp.unityPrice) }}
                  </b>
                </span>
                <br>
                <span class="qt-text-p">
                  <b>
                    {{ comp.specialUnityPrice.toFixed(2) }}&nbsp;{{ CURRENCY }}
                  </b>
                </span>
              </span>
              <span v-else class="qt-text-p"> 
              <b>
                {{ visualizeUnityPrice(comp.unityPrice) }}&nbsp;{{ CURRENCY }}
              </b>
              </span>
            </div>
          </div>
        </div>
        <div class="content mt-6">
          <button class="button btn-orange" @click="incrementQty(compositionSelected)">
            <span class="btn-txt">{{ $t('add_and_select') }}</span>
          </button>
        </div>
      </div>
      <!-- zeroPageEnabled -->
      <div v-if="props.zeroPageEnabled">
        <div v-if="props.item.compositions.length > 1">
          <div class="control">
            <div v-for="(comp) in props.item.compositions" :key="comp.id" >
              <div class="content is-flex is-fullwidth is-justify-content-space-between mt-1">
                <div class="child card-content-row-item">
                  <input type="radio" name="radioButtonSelector" @click="changeRadio(comp)" />
                  <p class="qt-text-p pl-2"><b>{{ comp.names[selectedLanguage].name }}</b></p>
                </div>
                <div class="child card-content-row-item">
                  <span class="qt-text">
                    <b>
                      {{ visualizeUnityPrice(comp.unityPrice) }}&nbsp;{{ CURRENCY }}
                    </b>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="content mt-6">
            <button class="button btn-orange" @click="incrementQty(compositionSelected)">
              <span class="btn-txt">{{ $t('add_and_return') }}</span>
            </button>
          </div>
        </div>
        <div v-else>
          <div class="control">
            <div class="content is-flex is-fullwidth is-justify-content-space-between mt-1">
              <div v-if="props.item.compositions[0].names[selectedLanguage].name" class="child card-content-row-item">
                <p class="qt-text-p"><b>{{ props.item.compositions[0].names[selectedLanguage].name }}</b></p>
              </div>
              <div v-if="props.item.compositions[0].names[selectedLanguage].name" class="child card-content-row-item">
                <span class="qt-text">
                  <b>
                    {{ visualizeUnityPrice(props.item.compositions[0].unityPrice) }}&nbsp;{{ CURRENCY }}
                  </b>
                </span>
              </div>
            </div>
          </div>
          <div class="content mt-6">
            <button class="button btn-orange" @click="incrementQty(props.item.compositions[0])">
              <span class="btn-txt">{{ $t('add_and_return') }}</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="css" scoped>


.card-content {
  padding: 0;
}

.qt-left {
    justify-content: left;
}

.qt-center {
    justify-content: center;
}

.qt-right {
    justify-content: right;
}



.button.is-warning.is-light {
    background-color: #ffffff;
    color: #000000;
    border: 2px solid #ffa800;
    border-radius: 4px;
}

.button.is-warning.is-light:hover, .button.is-warning.is-light.is-hovered {
    background-color: #FFA800;
    border-color: transparent;
    color: #000000;
}

.input[disabled] {
    background-color: #f2f2f2;
    border-color: #f2f2f2;
    box-shadow: none;
    color: #000000;
    border-radius: 4px;
}

.qt-card {
  min-height: 435px;
}

@media only screen and (max-width: 425px) {
  .btn-txt {
    font-size: 12px;
  }
}

@media only screen and (min-width:426px) and (max-width:1024px) {
  .btn-txt {
    font-size: 13px;
  }

  .qt-text-title-orange {
    font-size: 20px;
  }

  .qt-text-p {
    font-size: 14px;
  }
}

</style>



