<script setup lang="ts">
import { FLOW_PAYMENT } from "@/constants/satisPayConst";
import { PayEvent, ProviderPay, PaymentStatus, Pay } from "@/types";
import { defineEmits, defineProps} from "vue";
import { useCreatePay, useCreatePayRequest, useCreatePayResponse } from "@/composables/useUtils";
import { DEFAULT_VALUE } from "@/constants/globalConst";

const props = defineProps({
    totalPay: {
    type: Number,
    required: true,
  },
  identificativoOrdineCliente: {
    type: String,
    required: true,
  }
});
const emit = defineEmits<{
  (event: 'processPayment', pay: Pay): void;
  (event: 'identifyOrderClientNotFound'): void;
}>();
const processPayment = (pay: Pay) => {
  emit('processPayment', pay);
};
const identifyOrderClientNotFound = () => {
  emit('identifyOrderClientNotFound');
};
async function onSatisPaymentButtonClicked() {
  if (props.identificativoOrdineCliente === null || props.identificativoOrdineCliente === undefined || props.identificativoOrdineCliente === '') {
    identifyOrderClientNotFound();
  } else {
    const req = useCreatePayRequest(props.totalPay);
    const res = useCreatePayResponse(DEFAULT_VALUE, FLOW_PAYMENT, DEFAULT_VALUE);
    const pay: Pay = useCreatePay (
        DEFAULT_VALUE, DEFAULT_VALUE, DEFAULT_VALUE, 
        ProviderPay.SATISPAY, PayEvent.NOBODY, PaymentStatus.PENDING,
        DEFAULT_VALUE, DEFAULT_VALUE, req, res);
      processPayment(pay);
  }
}
</script>

<template>
  <div class="is-flex is-justify-content-center is-align-items-center mt-3">
    <button id="satisPayButton" class="button spBut" @click="onSatisPaymentButtonClicked()" >
      <img :src="require(`@/assets/images/satisPay-generic-red.png`)" style="width: auto; height: 100%;" />
    </button>
  </div>
</template>

<style lang="css" scoped>
button.spBut:hover {
  background-color:  rgb(249, 121, 114);
}
button.spBut{
  height: 50px;
  width: 240px;
  padding: 0;
  background-color: rgb(249, 76, 67); 
  transition: background-color 0.3s;
}
</style>