<!-- eslint-disable @typescript-eslint/no-non-null-assertion -->
<script setup lang="ts">
import { useStore } from 'vuex';
import { ComputedRef, computed, defineProps } from "vue";
import { CartActionTypes, Shopping } from '@/types';
import { CURRENCY } from "@/constants/globalConst";
import { useCloneShoppingSingleQuantity } from "@/composables/useUtilsCart";
import { i18n } from '@/main';

const props = defineProps({
  enableItemsDelete: {
    type: Boolean,
    required: false,
    default: true
  }
})

const store = useStore();
const selectedLanguage: ComputedRef<string> = computed(() => {
  return store.getters.getLocale;
});
const shoppingList: ComputedRef<Shopping[]> = computed(() => {
  return store.getters.getShoppingList;
});
const mapShoppingId: ComputedRef<Record<string, Shopping[]>> = computed(() => {
    const idMap: Record<string, Shopping[]> = {};
    shoppingList.value.forEach(element => {
        const catalogId = element.catalogId;
        if (!idMap[catalogId]) {
            idMap[catalogId] = [];
        }
        idMap[catalogId].push(element);
    });
    return idMap; 
});
const deleteItem = (item: Shopping) => {
    const shopping: Shopping = useCloneShoppingSingleQuantity(item)!;
    const shoppingSoon:Shopping | null = useCloneShoppingSingleQuantity(store.getters.getShoppingSoon(item));
    const shoppingFather: Shopping = useCloneShoppingSingleQuantity(item)!;
    shoppingFather.serviceZone = item.father.serviceZone;
    shoppingFather.catalogId = item.father.catalogId;
    shoppingFather.productInfoId = item.father.productInfoId;
    shoppingFather.productInfoCompositionsId = item.father.productInfoCompositionsId;
    shoppingFather.productInfoCompositionsUnityPriceEffective = item.father.productInfoCompositionsUnityPriceEffective;
    shoppingFather.father.catalogId = "";
    shoppingFather.father.productInfoCompositionsId = 0;
    shoppingFather.father.productInfoCompositionsUnityPriceEffective = 0;
    shoppingFather.father.productInfoDescriptionsName_it = "";
    shoppingFather.father.productInfoDescriptionsName_en = "";
    shoppingFather.father.productInfoId = 0;
    shoppingFather.father.serviceZone = "";    
    if (shopping.father.catalogId !='') {
        const testo = i18n.global.t('alert_message');
        if (confirm(testo)){
            store.commit("managementCart", { shopping: shoppingFather, cartActionType: CartActionTypes.SUBTRACT });
            store.commit("managementCart", { shopping: shopping, cartActionType: CartActionTypes.SUBTRACT });
        }
    } else {
        store.commit("managementCart", { shopping: shopping, cartActionType: CartActionTypes.SUBTRACT });
        if (shoppingSoon) {
            store.commit('managementCart', { shopping: shoppingSoon, cartActionType: CartActionTypes.SUBTRACT });
        }
    }
}
const dynamicTranslateProperty = (item: Shopping, name: string) => {
  const propertyName = `${name}${selectedLanguage.value}`;
  return item[propertyName as keyof Shopping] as string;
};

</script>
<template>
  <div v-for="(items, id) in mapShoppingId" :key="id">
    <div class="single-row-item is-justify-content-left">
      <figure class="icon">
        <img class="is-small" :src="require(`@/assets/images/${items[0].catalogItemLogoPath}`)" alt="imgCatLogo" />
      </figure>
      <p class="subtitle text6 ml-2">{{ dynamicTranslateProperty(items[0], "catalogItemName_") }}</p>
    </div>
    <div v-for="(item, index) in items" :key="index">
      <p class="qt-h3-orange">
        {{ dynamicTranslateProperty(items[index], "productInfoDescriptionsName_") }}
        <span v-if="item.father.productInfoDescriptionsName_it !='' " class="qt-h3-orange-light">
            ({{ $t('element_associated') }}
            <span class="qt-h3-orange-light">{{ item.productInfoCompositionsUnityPriceEffective.toFixed(2) }}&nbsp;{{ CURRENCY }}</span>
            )
        </span>
      </p>
      <div class="3
      ">
        <div class="child" style="min-width: 50%;">
          <span class="qt-text-h4 mr-2">{{ $t('quantity') }}</span>
          <span class="qt-text-h4">{{ item.productInfoCompositionsQuantity }}&nbsp;{{ dynamicTranslateProperty(items[index], "productInfoCompositionsName_") }}</span>
        </div>
        <div v-if="props.enableItemsDelete" class="child">
          <figure class="icon">
            <img class="is-small" src="@/assets/images/xRed.png" @click="deleteItem(item)" alt="imgX" style="cursor: pointer" />
          </figure>
        </div>
      </div>
      <br />
    </div>        
    <hr class="mt-2 mb-2"/>
  </div>
</template>
<style lang="css" scoped>

.qt-text-h4 {
  font-weight: lighter;
  line-height: 2;
}

.qt-h3-orange-light{
  line-height: 25px;
  font-family: 'Roboto-light';
  letter-spacing: 0px;
  color: #F15A24;
  font-size: 15px;
}

.single-row-item, .card-content-row-item {
  align-items: center;
  display: flex;
  justify-content: center;
  }


@media only screen and (max-width: 425px) {
  .qt-h3-orange-light{
    font-size: 12px;
  }
}

</style>