<script setup lang="ts">
import { RouterView } from "vue-router";
import { onBeforeMount, onBeforeUnmount } from "vue";
import useInhibitF5 from "@/composables/useInhibitF5";

const { inhibitF5, restoreF5 } = useInhibitF5();

onBeforeMount(() => {
  // Attiva l'intercettazione del tasto F5
  inhibitF5();
});
onBeforeUnmount(() => {
  // Rimuovi l'intercettazione del tasto F5
  restoreF5();
});
</script>

<template>
  <RouterView />
</template>

<style lang="less">
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  text-align: center;
  width: 100%; /* Imposta la larghezza massima al 90% del <div id="app"></div> in index.html */
  overflow-x: hidden;
  overflow-y: hidden;
}
</style>
