<!-- eslint-disable @typescript-eslint/no-explicit-any -->
<script setup lang="ts">
import { onBeforeMount, onMounted, ref, watch } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { useConcatenatedString, useCreateCart, useCreateOrder, useScrollToTop, useSleep } from "@/composables/useUtils";
import FooterComponent from '@/components/FooterComponent.vue'
import NavbarComponent from "@/components/NavbarComponent.vue"; 
import PayTableComponent from "@/components/PayTableComponent.vue";
import PayGoogleComponent from "@/components/PayGoogleComponent.vue";
import PaySatisComponent from "@/components/PaySatisComponent.vue";
import { ENABLE_SATISPAY, ENABLE_GOOGLEPAY, CURRENCY, ENVIROMENT } from "@/constants/globalConst"
import { useGetFirestoreListener, useUpdateOrderDocumentById } from "@/firebase/firebaseUtilities";
import { Cart, DbDocumentName, Order, OrderStatus, Pay, PayEvent, ProviderPay, Shopping } from "@/types";
import { redirectThankyou, redirectWithNameParam } from "@/composables/useNavigation";
import { useSatisPayCreatePayment } from "@/composables/useSatisPay";
import GeneralModalComponent from "@/components/GeneralModalComponent.vue";

const store = useStore();
const router = useRouter();
const route = useRoute();
const query = route.query;
const totalPay: number = store.getters.getTotalPay;
const idLocale = useConcatenatedString(route.params.idLocale);
const isOpen = ref (store.getters.getLocalIsOpen);
const dayOrdersTransactionsId = ref (store.getters.getLocalDayOrdersTransactionsId);
const localListened = ref(useGetFirestoreListener(DbDocumentName.LOCAL, idLocale ));
const serviceZone = useConcatenatedString(route.query.serviceZone);
const table = useConcatenatedString(route.query.table);
const payLater: boolean = store.getters.getLocalServiceZoneIsLaterPay(serviceZone);
const identificativoOrdineCliente = store.getters.getOrder?.identifyOrderClient;
const selectedRadio = ref("CASSA");
const isConfirmationModalActive = ref(false);
const payObject = ref<Pay | null>(null);

onMounted(() => {
  useScrollToTop();
});
onBeforeMount(async () => {
  payObject.value = null;
  const s:Shopping[] = await store.getters.getShoppingList;
  if (s.length<1){
    redirectWithNameParam(router, "cartGeneral", serviceZone, table);
  }
});
watch(localListened, (newValue) => {
  isOpen.value = newValue?.isOpen;
  dayOrdersTransactionsId.value = newValue?.dayOrdersTransactionsId;
});
const identifyOrderClientNotFound =() => {
  // non implementare
};
const processPayment = async () => {
  let pay = payObject.value!;
  console.log("Oggetto pay: ", pay);
  
  //arriva qui solo se serviceZone===onsit
  const c: Cart = useCreateCart(store, query, pay);
  let newOrder: Order = useCreateOrder(store, query, identificativoOrdineCliente, c);
  const order: Order = store.getters.getOrder;
  newOrder.date=order.date;
  newOrder.id=order.id;
  newOrder.identifyOrderApp=order.identifyOrderApp;
  newOrder.orderStatus=order.orderStatus;
  if (PayEvent.SUCCESS === pay.payEvent || PayEvent.NOBODY === pay.payEvent) {
    newOrder.orderStatus=OrderStatus.PENDING;
  }
  if (ProviderPay.SATISPAY === pay.providerPay && PayEvent.NOBODY === pay.payEvent) {
    const windowCurrentUrl = window.location.href;
    const { orderSatis, paySatis } = await useSatisPayCreatePayment (store, query, windowCurrentUrl, newOrder);
    newOrder = orderSatis;
    pay = paySatis;
  }
  await useUpdateOrderDocumentById(newOrder);
  store.commit("setOrder", newOrder);

  isConfirmationModalActive.value = false;
  if (ProviderPay.SATISPAY === pay.providerPay && PayEvent.SUCCESS === pay.payEvent) {
    window.location.href = pay.redirectUrl2FA;
  } else {
    //await useSleep(1000);
    redirectThankyou(router, serviceZone, table);
  }
}

const showConfirmationModal = (pay: Pay) => {
  payObject.value = pay;
  isConfirmationModalActive.value = true;
}
</script>

<template>
  <NavbarComponent />
  <div class="qt-container pb-2">
    <div class="columns is mobile">
      <div class="column has-text-left p-0">
          <h1 class="qt-text-title-orange ml-2">{{ $t('method_pay') }}</h1>
      </div>
    </div>
  </div>
  
  <div class="qt-container pb-6">
    <div class="columns is mobile qt-card mb-6">
      <div class="column p-0">
        <span class="qt-text-card-title-black">{{ $t('chose_payment') }}</span>
        <div class="columns is-desktop">
          <hr>
          <div class="column mb-3 mt-3 p-0 has-text-center">
            <span class="qt-text-title-orange">{{ $t('pay') }} {{totalPay.toFixed(2)}} {{CURRENCY}}</span>
          </div>
          <hr class="mb-0">
          <div v-if="!isOpen" class="column mb-3 mt-3 p-0 ">
            <span class="qt-error-txt" ><b>{{ $t('local_close') }} <br>{{ $t('imposssible') }}</b></span>
            <hr>
          </div>
          <div v-else-if="isOpen && totalPay > 0" class="column mb-3 mt-3 p-0 ">
            <div class="control is-inline-flex">
              <div v-if="payLater || ENVIROMENT==='TEST' " >
                <label class="radio qt-text-h4-big mr-5">
                  <input type="radio" name="radioButtonSelector" v-model="selectedRadio" :value="'CASSA'" checked>
                  {{ $t('pay_cash') }}
                </label>
              </div>
              <div v-if="ENABLE_GOOGLEPAY || ENABLE_SATISPAY || ENVIROMENT==='TEST' " >
                <label class="radio qt-text-h4-big">
                  <input type="radio" name="radioButtonSelector" v-model="selectedRadio" :value="'APP'">
                  {{ $t('pay_app') }} 
                </label>
              </div>
            </div>
            <div class="mt-6 has-text-left">
              <span class="qt-text-p-small is-uppercase"><b>{{ $t('take_vision') }}</b></span>
              <br/>
              <div v-if="(payLater || ENVIROMENT==='TEST') && ('CASSA' === selectedRadio)">
                <span class="qt-text-p-small">
                  {{ $t('method_local') }}
                </span>
              </div>
              <div v-if="(ENABLE_GOOGLEPAY || ENABLE_SATISPAY || ENVIROMENT==='TEST') && ('APP' === selectedRadio)" >
                <span class="qt-text-p-small">
                  {{ $t('method_app') }}
                </span>
              </div>
            </div>
            <div class="mt-4">
              <div v-if="(payLater || ENVIROMENT==='TEST') && ('CASSA' === selectedRadio)" >
                <PayTableComponent
                  :totalPay="totalPay"
                  :identificativoOrdineCliente="identificativoOrdineCliente"
                  @identifyOrderClientNotFound="identifyOrderClientNotFound"
                  @processPayment="showConfirmationModal"
                />
              </div>
              <div v-if="(ENABLE_GOOGLEPAY || ENABLE_SATISPAY || ENVIROMENT==='TEST') && ('APP' === selectedRadio)" class="mb-4">
                <div v-if="ENABLE_GOOGLEPAY || ENVIROMENT==='TEST' " >
                  <PayGoogleComponent
                    :totalPay="totalPay"
                    :identificativoOrdineCliente="identificativoOrdineCliente"
                    @identifyOrderClientNotFound="identifyOrderClientNotFound"
                    @processPayment="processPayment"
                  />
                </div>
                <div v-if="ENABLE_SATISPAY || ENVIROMENT==='TEST' " >
                  <PaySatisComponent
                    :totalPay="totalPay"
                    :identificativoOrdineCliente="identificativoOrdineCliente"
                    @identifyOrderClientNotFound="identifyOrderClientNotFound"
                    @processPayment="processPayment"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <GeneralModalComponent
        :is-active-modal="isConfirmationModalActive"
        :cancel-text="$t('no')"
        :confirm-text="$t('yes')"
        :text-content="$t('order_confirmation_warning_message')"
        :title="$t('order_confirmation_title')"
        :on-confirm="processPayment"
        @process-open-close-modal="isConfirmationModalActive = !isConfirmationModalActive"
      />
    </div>
  </div>
  <FooterComponent />
</template>

<style lang="css" scoped>
  .columns {
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
  }

  .columns:not(:last-child) {
    margin-bottom: 0;
  }

  .columns:last-child {
   margin-bottom: 0;
  }

  .qt-text-h3 {
    color:#000000;
    font-family: 'Roboto-Medium';
    opacity: 1;
    font-size: 17px;
  }

  .qt-text-h4-big {
    color: #000000;
    font-family: 'Roboto-Medium';
    opacity: 1;
    font-size: 17px;
  }

  input[type="radio"] {
    vertical-align: middle;
  }

  .qt-text-p-small {
    color: #000000;
    font-family: 'Roboto';
    opacity: 1;
    font-size: 13px;
  }

  @media only screen and (max-width: 425px) {
    .qt-text-h4-big,
    .qt-text-h3 {
      font-size: 15px;
    }

    .qt-text-title-orange {
      font-size: 20px;
    }

    .qt-text-p-small {
    font-size: 11px;
    }

  }
</style>
