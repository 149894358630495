/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { COOKIE_MINUTE_TIME_EXPIRE, COOKIE_NAME, COUNTRY_CODE, CURRENCY_CODE, DEFAULT_VALUE, ENVIROMENT } from "@/constants/globalConst";
import { Catalog, CatalogSpecialOfferType, CatalogSpecialOfferName, Shopping, ProductInfo, Composition, PayRequest, PayResponse, Pay, ProviderPay, PayEvent, PaymentStatus, Cart, Order, OrderStatus } from "@/types";
import { Timestamp } from "firebase/firestore";
// @ts-ignore
import Cookies from 'js-cookie';
import { v4 as uuidv4 } from "uuid";
import { LocationQuery, RouteLocationNormalizedLoaded } from "vue-router";
import { format } from 'date-fns';

export function useConcatenatedString(obj: any | any[]): string {
  const concatenatedString = Array.isArray(obj) ? obj.join('') : obj;
  return concatenatedString;
}
export function useNow(): Timestamp {
  const now: Timestamp = Timestamp.fromDate(new Date());
  return now;
}
export function useScrollToTop(): void {
  window.scrollTo(0, 0);
}
export async function useSleep(ms: number): Promise<void> {
  return await new Promise(resolve => setTimeout(resolve, ms));
}
export function usePaginateRangeInitFor(currentPage: number, itemsInPage: number): number {
  return ((currentPage - 1) * itemsInPage);
}
export function usePaginateRangeEndFor(currentPage: number, itemsInPage: number): number {
  return (currentPage * itemsInPage);
}
export function useInsertCookie(serviceZone: string, table: string, isMaster: boolean, isMasterReady: boolean, orderId: string): void {
  const jsonValue = {
    serviceZone: serviceZone, 
    table: table,
    isMaster: isMaster,
    isMasterReady: isMasterReady,
    orderId: orderId,
    timestampExpire: useNow().seconds+(COOKIE_MINUTE_TIME_EXPIRE * 60),
  };
  const jsonString = JSON.stringify(jsonValue);
  Cookies.set(COOKIE_NAME, jsonString);
}
export function useIsValidCookie(): boolean {
  let b = false;
  const cookieValue = Cookies.get(COOKIE_NAME);
  if (cookieValue) {
    const jsonObject = JSON.parse(cookieValue);
    const timestampExpire = jsonObject.timestampExpire;
    if (useNow().seconds <= timestampExpire){
      b = true;
    }
  }
  return b;
}
export function useUpdateCookie(store: any, params: {[key: string]: any}): void {
  const cookieValue = Cookies.get(COOKIE_NAME);
  const jsonObject = JSON.parse(cookieValue!);
  const newJson = {
    ...jsonObject,
    ...params
  };
  const jsonString = JSON.stringify(newJson);
  Cookies.set(COOKIE_NAME, jsonString);
}
export function useRemoveCookie(): void {
  const cookieValue = Cookies.get(COOKIE_NAME);
  if (cookieValue) {
    Cookies.remove(COOKIE_NAME);
  }
}
/**
 * get cookie value of paramName in input
 * @param cookieParamName The name of the parameter to retrieve from the cookie. <br>
 * Accept values:  
 * - 'counterCart'
 * - 'shoppingList'
 * - 'isMaster'
 * - 'isMasterReady'
 * - 'serviceZone'
 * - 'table'
 * - 'timestampExpire'
 * - 'orderId'
 * - 'identifyOrderApp'
 * @returns The value of the specified cookie parameter, or undefined if not found.
 */
export function useGetCookieParam(cookieParamName: string): any|undefined {
  const cookieValue = Cookies.get(COOKIE_NAME);
  if (cookieValue) {
    const jsonObject = JSON.parse(cookieValue);
   return jsonObject[cookieParamName];
  }
  return undefined
}
export function useGeneretePersonalNanoId(): string {
  const randomNumber = 3;
  const capitalLetter = String.fromCharCode(65 + Math.floor(Math.random() * 26));
  const number = Math.floor(Math.random() * (10 * randomNumber)).toString().padStart(randomNumber, '0');
  return `${capitalLetter}${number}`;
}
export function useCalculateUnityPrice(store:any, serviceZone:string, catalogItemId:string, unityPrice: number): number {
  let price: number = unityPrice;
  const c: Catalog = store.getters.getLocalCatalogByServiceZoneAndId(serviceZone, catalogItemId);
  if (c.specialOffer)  {
    switch (c.specialOfferType) {
      case CatalogSpecialOfferType.PERCENT:
        if(CatalogSpecialOfferName.MINUS === c.specialOfferName) {
          price = unityPrice - ((unityPrice * c.specialOfferFee) / 100);
        } else if(CatalogSpecialOfferName.PLUS === c.specialOfferName) {
          price = unityPrice + ((unityPrice * c.specialOfferFee) / 100);
        } 
        break;
      case CatalogSpecialOfferType.PRICE:
        if(CatalogSpecialOfferName.MINUS === c.specialOfferName) {
          price = unityPrice - c.specialOfferFee;
        } else if(CatalogSpecialOfferName.PLUS === c.specialOfferName) {
          price = unityPrice + c.specialOfferFee;
        } 
        break;
      default:
        price = unityPrice;
        break;
    }
  }
  return price!;
}
export function useCreateShopping(
  store:any, route: RouteLocationNormalizedLoaded, 
  catalogItemId:string, catalogItemLogoPath:string, 
  catalogItemName_it:string, catalogItemName_en:string, 
  productInfo:ProductInfo, zeroEnabled:boolean,
  composition:Composition, quantity:number
): Shopping {
  const serviceZone = useConcatenatedString(route.query.serviceZone);
  const c: Catalog = store.getters.getLocalCatalogByServiceZoneAndId(serviceZone, catalogItemId);
  const cup = useCalculateUnityPrice(store, serviceZone, catalogItemId, zeroEnabled? 0 : composition.unityPrice);
  const eup = composition.specialOffer ? composition.specialUnityPrice : cup;
  const shoppingFather: Shopping = store.getters.getShoppingFather;
  const o: Shopping = {
    catalogId: c.catalogId,
    catalogGroupType: c.groupType,
    catalogSpecialOffer: c.specialOffer,
    catalogSpecialOfferName: c.specialOfferName,
    catalogSpecialOfferFee: c.specialOfferFee,
    catalogSpecialOfferType: c.specialOfferType,
    catalogItemLogoPath: catalogItemLogoPath,
    catalogItemName_it: catalogItemName_it,
    catalogItemName_en: catalogItemName_en,
    father: {
      serviceZone: zeroEnabled? (shoppingFather?.serviceZone ?? "") : "",
      catalogId: zeroEnabled? (shoppingFather?.catalogId ?? "") : "",
      productInfoId: zeroEnabled? (shoppingFather?.productInfoId ?? 0) : 0,
      productInfoCompositionsId: zeroEnabled? (shoppingFather?.productInfoCompositionsId ?? 0) : 0,
      productInfoCompositionsUnityPriceEffective: zeroEnabled? (shoppingFather?.productInfoCompositionsUnityPriceEffective ?? 0) : 0,
      productInfoDescriptionsName_it: zeroEnabled? (shoppingFather?.productInfoDescriptionsName_it ?? "") : "",
      productInfoDescriptionsName_en: zeroEnabled? (shoppingFather?.productInfoDescriptionsName_en ?? "") : "",
    },
    productInfoId: productInfo.id,
    productInfoLogicImplement: productInfo.logicImplement,
    productInfoDescriptionsName_it: productInfo.descriptions.it.name,
    productInfoDescriptionsName_en: productInfo.descriptions.en.name,
    productInfoCompositionsId: composition.id,
    productInfoCompositionsName_it: composition.names.it.name,
    productInfoCompositionsName_en: composition.names.en.name,
    productInfoCompositionsQuantity: quantity,
    productInfoCompositionsUnityPriceEffective: eup,
    serviceZone: serviceZone,
  };
  store.commit('setShoppingFather', null);
  return o;
}
export function useCreatePayRequest (totalPrice:number): PayRequest {
  const o: PayRequest = {
    countryCode: COUNTRY_CODE,
    currencyCode: CURRENCY_CODE,
    totalPrice: totalPrice,
  };
  return o;
}
export function useCreatePayResponse (method:string, type:string, circuit:string): PayResponse {
  const o: PayResponse = {
    method: method,
    type: type,
    circuit: circuit,
  };
  return o;
}
export function useCreatePay (id: string, merchantId: string, merchantName: string, 
  providerPay: ProviderPay, payEvent: PayEvent, paymentStatus: PaymentStatus,
  providerTransactionId: string, redirectUrl2FA: string, request: PayRequest, response: PayResponse
  ): Pay {
  const o: Pay = {
    id: id,
    date: useNow(),
    environment: ENVIROMENT,
    merchantId: merchantId,
    merchantName: merchantName,
    providerPay: providerPay,
    payEvent: payEvent,
    paymentStatus: paymentStatus,
    providerTransactionId: providerTransactionId,
    redirectUrl2FA: redirectUrl2FA,
    request: request,
    response: response,
  };
  return o;
}
export function useCreateCart (store: any, query: LocationQuery, pay: Pay): Cart {
  const sl = store.getters.getShoppingList;
  const ts = store.getters.getTotalPay;
  const serviceZone = useConcatenatedString(query.serviceZone);
  const o: Cart = {
    id: uuidv4(),
    pay: pay,
    serviceZone: serviceZone,
    shoppings: sl,
    totalShop: ts,
  };
  return o;
}
export function useCreateOrder (store: any, query: LocationQuery, identifyOrderClient: string, cart: Cart): Order {
  const serviceZone = useConcatenatedString(query.serviceZone);
  const table = useConcatenatedString(query.table) ?? DEFAULT_VALUE;
  let totalAmount = 0;
  cart.shoppings.forEach(element => {
    totalAmount += (element.productInfoCompositionsUnityPriceEffective * element.productInfoCompositionsQuantity);
  });
  const carts: Cart[]= [];
  carts.push(cart);
  const o: Order = {
    id: uuidv4(),
    identifyOrderApp: useGeneretePersonalNanoId(),
    identifyOrderClient: identifyOrderClient,
    carts: carts,
    dayOrdersTransactionsId: store.getters.getLocalDayOrdersTransactionsId,
    date: useNow(),
    idLocal: store.getters.getLocalId,
    isInvoiced: false,
    isPreparedBar: false,
    isPreparedKitchen: false,
    orderStatus: OrderStatus.OPEN,
    serviceZone: serviceZone,
    table: table,
    totalAmount: totalAmount,
  };
  return o;
}
export function useDayOrdersTransactionsId(idLocale: string): string {
  const now: Date = new Date();
  const year = now.getFullYear();
  // Il mese parte da 0, quindi aggiungiamo 1
  const month = (now.getMonth() + 1).toString().padStart(2, '0');
  const day = now.getDate().toString().padStart(2, '0');
  const hours = now.getHours().toString().padStart(2, '0');
  const minutes = now.getMinutes().toString().padStart(2, '0');
  const seconds = now.getSeconds().toString().padStart(2, '0');
  const formattedValue = `${year}${month}${day}${hours}${minutes}${seconds}`;
  const finalValue = `${idLocale}${formattedValue}`;
  return finalValue;
}
export function useOrderErasable(order: Order): boolean {
  if(order.orderStatus === OrderStatus.PENDING || 
      order.orderStatus === OrderStatus.MASTER_READY || order.orderStatus === OrderStatus.OPEN) {
        return true;
      } else {
        return false;
      }
}
export function useFormatData(createdAt: Timestamp): string {
  try {
    const timestampInMilliseconds = createdAt.toMillis();
    return format(new Date(timestampInMilliseconds), 'dd/MM/yyyy HH:mm:ss');
  } catch (error) {
    return "";
  }
}
export function useFormatTimestampFromStartDate(date: string): Timestamp {
  const dateMatch = useRegexDate(date);
  const newDate = useFormatTimestampFromString(dateMatch[0], false);
  return newDate;
}

export function useFormatTimestampFromEndDate(date: string): Timestamp {
  const dateMatch = useRegexDate(date);
  if (dateMatch.length === 2) {
    return useFormatTimestampFromString(dateMatch[1], true);
  } else {
    return useFormatTimestampFromString(dateMatch[0], true);
  }
}
function useRegexDate(date: string): string[] {
  const regex = /\d{2}-\d{2}-\d{4}/g;
  const dateMatch = date.match(regex);
  if (dateMatch) {
    return dateMatch;
  } else {
    return [];
  }
}
export function useFormatTimestampFromString(dateString: string, addDay: boolean): Timestamp {
  const [d, m, y] = dateString.split("-");
  const newDate = new Date(Number(y), Number(m) - 1, Number(d));
  if (addDay) {
    return Timestamp.fromMillis(Timestamp.fromDate(newDate).toMillis() + (24 * 60 * 60 * 1000));
  } else {
    return Timestamp.fromDate(newDate);
  }
}