<!-- eslint-disable @typescript-eslint/no-non-null-assertion -->
<script setup lang="ts">
import { onBeforeMount, onMounted, ref } from "vue";
import { useConcatenatedString, useGetCookieParam, useScrollToTop } from "@/composables/useUtils";
import FooterComponent from '@/components/FooterComponent.vue'
import NavbarComponent from "@/components/NavbarComponent.vue";
import QrImageComponent from "@/components/QrImageComponent.vue";
import { useGetOrderDocumentById } from "@/firebase/firebaseUtilities";
import { CartActionTypes, Order } from "@/types";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { onBeforeUnmount } from "vue";

const route = useRoute();
const orderId = useGetCookieParam('orderId');
const store = useStore();
const order: Order = store.getters.getOrder;
const identifyOrderApp = ref(order?.identifyOrderApp);
const table = useConcatenatedString(route.query.table);

onMounted(() => {
  useScrollToTop();
});
onBeforeMount(async () => {
  if (!order && orderId && orderId!="") {
    const order = await useGetOrderDocumentById(orderId);
    store.commit("setOrder", order);
    identifyOrderApp.value = order!.identifyOrderApp;
  }
});
onBeforeUnmount(() => {
  store.commit("managementCart", { shopping: null, cartActionType: CartActionTypes.RESET });
});
</script>

<template>
  <NavbarComponent />
  <div class="qt-container">
    <p class="mt-6">
      <img class="mt-3 mb-3" src="@/assets/images/tryfood.png"/>
    </p>
    <p class="m-4">
      <span class="qt-text-card-title-black">{{ $t('await_all_orders') }}</span>
    </p>
    <p v-if="identifyOrderApp" class="mt-2">
      <span v-if="table" class="qt-text-h4">{{ $t('table-lable') }} <span style="color: #F15A24;">{{ table }}</span>,&nbsp;</span>
      <span v-if="identifyOrderApp" class="qt-text-h4">{{ $t('order-lable') }} <span style="color: #F15A24;">{{ identifyOrderApp }}</span></span>     
    </p>
    <hr class="qt-hr">
    <QrImageComponent />
    <br>
  </div>
  <FooterComponent />
</template>

<style lang="css" scoped>
  .qt-hr {
    background-color: #C3C3C3;
    border: none;
    display: block;
    height: 2px;
    margin: 1.5rem 0;
  }
</style>
