<script setup lang="ts">
import { computed, onBeforeMount, ref, watch } from "vue";
import CartDetailComponent from "../components/CartDetailComponent.vue";
import { Order, Language, PaymentStatus, DbDocumentName, OrderStatus, ServiceZone, CatalogGroupType } from "../types";
import NavbarComponent from "../components/NavbarComponent.vue";
import { useGetDocumentByOrderNumberOrTableNumber, useUpdateDocument } from "@/firebase/firebaseUtilities";
import FooterComponent from '../components/FooterComponent.vue'
import { Timestamp } from "firebase/firestore";
import {useTranslateServiceZone} from "@/composables/useTranslate"
import PaginationComponent from '@/components/PaginationComponent.vue';
import { usePagination } from '@/composables/usePagination';
import { useTranslatePaymentStatus, useTranslateOrderStatus } from "@/composables/useTranslate"
import { useStore } from "vuex";

const isLoading = ref<boolean>(true);
const orderNumber = ref("");
const tableNumber = ref("");
const orders = ref([] as Order[]);
const checkInsertValue = ref(false);
const labelFocus = ref("");
const isOrderNumberActive = ref(true);
const isTableNumberActive = ref(true);
let itemsTotal = computed(() => orders.value.length);
let openIndex = ref(null as number | null);
const store = useStore();

const activeServiceZone = ref<ServiceZone>(ServiceZone.onsit);
//paginazione
const itemsPerPage = 6;
const { startIndex, endIndex, handlePageChange } = usePagination(itemsPerPage);
const currentPage = ref(1);
watch(activeServiceZone, () =>{
  currentPage.value = 1;
  handlePageChange(currentPage.value);
})

const ordersToDisplay = computed<Order[]>(() => {
  return orders.value.slice(startIndex.value, endIndex.value);
})

function toggleItem(index: number) {
  if (openIndex.value === index) {
    openIndex.value = null;
  } else {
    openIndex.value = index;
  }
}

onBeforeMount(async () => {
  isLoading.value = false;
  orders.value = store.getters.getOrders;
});

const tableNumberChange = () => {
  if (!tableNumber.value) {
    orderNumber.value = '';
    isOrderNumberActive.value = true;
  } else {
    isOrderNumberActive.value = false;
  }
};

const orderNumberChange = () => {
  if (!orderNumber.value) {
    tableNumber.value = '';
    isTableNumberActive.value = true;
  } else {
    isTableNumberActive.value = false;
  }
};

function formatDate(timestamp: Timestamp) {
  const date = new Date(timestamp.seconds * 1000);
  return date.toLocaleDateString();
}

function formatTime(timestamp: Timestamp) {
  const date = new Date(timestamp.seconds * 1000);
  return date.toLocaleTimeString();
}

async function search() {
  orders.value = await useGetDocumentByOrderNumberOrTableNumber(orderNumber.value.toUpperCase(), tableNumber.value)
}

async function updateOrderStatusToDeleted(order: Order) {
  await useUpdateDocument(DbDocumentName.ORDER, order.id, {'document.orderStatus': OrderStatus.DELETED});
  order.orderStatus = OrderStatus.DELETED;
}

function orderPaymentStatus(order: Order): PaymentStatus {
  return order.carts[0].pay.paymentStatus;
}

function formatAmount(amount: number) {
  return amount.toFixed(2);
}

</script>

<template>
  <div v-if="!isLoading">
    <NavbarComponent />
      <div class="qt-container">
          <div class="columns is-vcentered p-0 m-0">
            <div class="column is-6">
              <p class="qt-text-card-title-black has-text-left mb-4">{{ $t('search_order') }}</p>
              <p class="qt-text-h3 has-text-left">{{ $t('insert_table_num_order') }}</p>
            </div>
            <div class="column is-6 qt-display">
                  <input 
                      class="input mr-2" 
                      type="text" 
                      id="tableNumber" 
                      v-model="tableNumber" 
                      maxlength="5" 
                      placeholder="Numero tavolo"
                      :style="{ borderColor: (checkInsertValue && orderNumber==='') ? 'red' : '' }"
                      :disabled="!isTableNumberActive"
                      @input="tableNumberChange()"
                      required
                  >
                <input 
                  class="input mr-2" 
                  type="text" 
                  id="orderNumber" 
                  v-model="orderNumber" 
                  maxlength="4" 
                  placeholder="Numero ordine"
                  style="text-transform: uppercase;"
                  :style="{ borderColor: (checkInsertValue && orderNumber==='') ? 'red' : '' }"
                  :disabled="!isOrderNumberActive"
                  @input="orderNumberChange()"
                  required
                  >
                <button class="button is-warning" @click="search()"><strong>{{ $t('search') }}</strong></button>
            </div>
          </div>

          <section class="section m-0 p-0">            
            <div class="columns is-vcentered p-0 m-0">
              <div class="column has-text-left pb-0">
                <span v-if="checkInsertValue" class="qt-text-h3" style="color: red;">{{labelFocus}}</span>
                <span v-else class="qt-text-h3">{{labelFocus}}</span>
                <span v-if="itemsTotal > 0" class="qt-text-h3">{{ itemsTotal }} {{ $t('orders') }}</span>
              </div>
            </div>
          </section>

          <section class="section m-0 p-0">   
            <div v-if="orders.length > 0">
              <div class="accordion p-2">
                <div class="qt-card accordion-item mb-4" v-for="(order, index) in ordersToDisplay" :key="order.id">
                  <div class="accordion-header" @click="toggleItem(index)">
                    <div class="columns is-vcentered p-0 m-0">
                      <div class="column" v-if="order.serviceZone === ServiceZone.standup || order.serviceZone === ServiceZone.takeaway">
                        <span class="qt-text-p">
                          <strong>{{ order.identifyOrderClient.toUpperCase() }}</strong>
                      </span>
                      </div>
                      <div class="column p-2">
                        <span class="qt-text-p"><strong>{{ order.identifyOrderApp }}</strong></span>
                      </div>
                      <div class="column p-2">
                        <span class="qt-text-p"><strong>{{ formatDate(order.date) }}</strong></span>
                      </div>
                      <div class="column p-2">
                        <span class="qt-text-p"><strong>{{ formatTime(order.date) }}</strong></span>
                      </div>
                      <div class="column p-2">
                        <span class="qt-text-p" v-if="order.table !== null && order.table !== ''">
                        <strong>{{ $t('table') }} {{ order.table }}</strong>
                        </span>
                        <span class="qt-text-p" v-else>
                          <strong>{{ useTranslateServiceZone(order.serviceZone, Language.IT) }}</strong>
                        </span>
                      </div>
                      <div class="column p-2">
                        <span class="qt-text-p"><strong>{{ useTranslateOrderStatus(order.orderStatus, Language.IT) }}</strong></span>
                      </div>
                      <div class="column is-one-quarter p-2">
                        <span class="qt-text-h3"><strong>{{ $t('tot_order') }} {{ formatAmount(order.totalAmount) }} €</strong></span>
                      </div>
                    </div>
                  </div>
                  <div v-if="openIndex === index" class="accordion-content">
                    <hr class="mt-2 mb-2">
                    <div class="columns is-vcentered m-0 p-0">
                      <div class="column">
                        <div class="single-row-item">
                          <figure class="icon">
                            <img v-if="orderPaymentStatus(order) === PaymentStatus.PENDING"  class="is-small mr-2" :src="require(`@/assets/images/awaitTime.png`)" alt="awaitTime" />
                            <img v-else class="is-small mr-2" :src="require(`@/assets/images/successGreen.png`)" alt="awaitTime" />
                          </figure>
                          <span class="qt-text-h3" style="font-size:15px"><b>{{ useTranslatePaymentStatus(order, Language.IT) }}</b></span>
                          <button 
                            v-if="order.orderStatus === OrderStatus.OPEN || order.orderStatus === OrderStatus.MASTER_READY || order.orderStatus === OrderStatus.PENDING"
                            class="button is-danger ml-6"
                            @click="updateOrderStatusToDeleted(order)">
                            <span class="qt-text-p txt-white qt-txt-small">{{ $t('deleted_order') }}</span>
                          </button>
                        </div> 
                      </div>
                    </div>
                    <hr class="mt-2 mb-2">
                    <div class="card-content m-0 pt-0 pb-0">
                      <div class="columns mt-1 mb-3">
                        <div class="column is-5 has-text-left">
                          <div class="qt-text-title-orange">{{ $t('bar') }}</div>
                          <CartDetailComponent 
                          :order="order"
                          :cataloGroupType="CatalogGroupType.DRINK" />
                        </div>
                        <div class="column is-1"></div>
                        <div class="column is-5 has-text-left">
                          <div class="qt-text-title-orange" v-if="order.serviceZone === ServiceZone.onsit">{{ $t('kitchen') }}</div>
                          <CartDetailComponent 
                          :order="order"
                          :cataloGroupType="CatalogGroupType.FOOD" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="orders.length > 0" class="example-two mt-3 pb-6">
              <PaginationComponent class="mt-6"
                :totalItemsLength="orders.length" 
                :itemsPerPage="itemsPerPage"
                v-model:initialPage="currentPage"
                @page-change="handlePageChange"
              />
            </div>
          </section>



      </div>
    <FooterComponent />
  </div>
</template>

<style lang="css" scoped>
.qt-card {
  padding: 1rem;
}
.custom-flex-right {
    display: flex;
    justify-content: flex-end;
}
.order-info {
  text-align: start;
}
.card-title {
  text-align: start;
}

.qt-container {
    min-height: 40em;
}

.qt-display{
  justify-content: flex-end;
  display: inline-flex;
}

.single-row-item, .card-content-row-item {
  align-items: center;
  display: flex;
  justify-content: center;
}

.qt-text-title-orange {
  font-size: 21px;
}

@media only screen and (max-width: 979px) {
  .qt-text-p{
    font-size: 11px;
  }

  .qt-text-h3{
    font-size:13px
  }

  .qt-text-title-orange{
    font-size: 16px;
  }
}

</style>